import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

import Vue from 'vue';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store/app-store';

import Notifications from 'vue-notification';
Vue.use(Notifications);

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);
import 'sweetalert2/dist/sweetalert2.min.css';

import VueCookies from 'vue-cookies'
Vue.use(VueCookies);

import vueDebounce from 'vue-debounce';
Vue.use(vueDebounce);

Vue.config.productionTip = false;

import cookies from 'vue-cookies';
if (new URL(location.href).searchParams.get('token')) {
  cookies.set('user', new URL(location.href).searchParams.get('token'));
}


import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap-vue/esm/components';
// import { VBTooltip } from 'bootstrap-vue'
import { ModalPlugin, ButtonPlugin, DropdownPlugin, NavPlugin, VBTooltipPlugin, FormCheckboxPlugin } from 'bootstrap-vue';
Vue.use( ModalPlugin );
Vue.use( ButtonPlugin );
Vue.use( NavPlugin );
Vue.use( DropdownPlugin );
Vue.use( VBTooltipPlugin );
Vue.use( FormCheckboxPlugin );
 

import './sass/app.scss';
import API from "@/api";
import tracker from "@/mixins/tracker";

window.user = null;

API.getCurrentUser()
    .then(res => {

    // Set the user on successful response.
    window.user = res;

  })
    .catch(() => {})
    .finally(() => {

    new Vue({
      router,
      render: h => h(App),
      mixins: [tracker],

      data(){
        return { store };
      },

      mounted() {


        /**
         * Attach tracker for button / link clicks.
         */
        this.tracker.attach('mousedown', 'a,button', 'Click', (el) => {

          const $el = jQuery(el);
          let click_id = $el.attr('id');
          if( !click_id ) click_id = $el.data('cid');
          if( !click_id ) click_id = $el.data('ref');
          if( !click_id ) click_id = $el.parent().attr('id');
          if( !click_id ) click_id = $el.parent().data('cid');
          if( !click_id ) click_id = $el.parent().data('ref');
          if( !click_id ) click_id = null;

          return {
            title: document.title,
            page_name: this.$route.name,
            page_params: this.$route.params,
            label: jQuery.trim( $el.text() ),
            click_id,
          }
        });

      }


    }).$mount('#app');

  });
