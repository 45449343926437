<template>
  <div class="grant-step-intro">

    <div class="head">
      <div class="container" style="max-width: 800px">
        <div class="row">
          <div class="col-md-6">
            <h1 class="h3">Teacher &amp; School Info</h1>
          </div>
          <div class="col-md-6 text-right" v-if="!current_user">
            <button class="btn btn-info text-uppercase mb-4" @click.prevent="login()">
              <i class="fas fa-sign-in-alt mr-1"></i>
              Log in to use your existing account
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container" style="max-width: 800px">
      <div class="form-group">
        <label for="email">Email <sup>*</sup></label>
        <input type="email"
               id="email"
               class="form-control"
               placeholder="Email"
               v-model="values.email">
        <field-error-message :errors="errors" field="email"/>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="first_name">First Name <sup>*</sup></label>
            <input type="text"
                   id="first_name"
                   class="form-control"
                   placeholder="First Name"
                   v-model="values.first_name">
            <field-error-message :errors="errors" field="first_name"/>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="last_name">Last Name <sup>*</sup></label>
            <input type="text"
                   id="last_name"
                   class="form-control"
                   placeholder="Last Name"
                   v-model="values.last_name">
            <field-error-message :errors="errors" field="last_name"/>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="school_name">School Name <sup>*</sup></label>
        <input type="text"
               id="school_name"
               class="form-control"
               placeholder="School Name"
               v-model="values.school_name">
        <field-error-message :errors="errors" field="school_name"/>
      </div>

      <div class="form-group">
        <label for="city">City <sup>*</sup></label>
        <input type="text"
               id="city"
               class="form-control"
               placeholder="Name of your city"
               v-model="values.city">
        <field-error-message :errors="errors" field="city"/>
      </div>

      <div class="form-group">
        <label for="state">State <sup>*</sup></label>
        <select type="text"
                id="state"
                class="form-control"
                v-model="values.state">
          <option value="">-- SELECT STATE --</option>
          <option v-for="(state, key) in states" :key="key" :value="key">{{ key }} - {{ state }}</option>
        </select>
        <field-error-message :errors="errors" field="state"/>
      </div>
    </div>

  </div>
</template>

<script>
import mixins from "@/mixins";
import states from "@/helpers/states";
import FieldErrorMessage from "@/components/FieldErrorMessage";
import tracker from "@/mixins/tracker";

export default {
  name: "GrantStepTeacher",
  components: {FieldErrorMessage},
  mixins: [mixins, tracker],

  props: {
    values: {
      type: Object,
      default() {
        return {};
      }
    },
    errors: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  computed: {
    states() {
      return states;
    }
  },

  methods: {
    login() {
      this.$router.push({name: 'auth.login', query: {back: window.location + '#continue'}})
    }
  },

  mounted() {
    this.setPageTitle( 'Grant Submission - Teacher & School Info' );
    this.tracker.visit(this.$route);
  }

}
</script>

<style lang="scss" scoped>
hr {
  margin: 1rem 0 2rem;
}

.form-group {
  margin-bottom: 2rem;
}
</style>