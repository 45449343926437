export const all_stage_options = {
    warmup: {
        hint: `In the Empathy Warmup, students will listen to a
                 podcast and engage in selected materials about the
                 theme to describe it in their own words. The
                 deliverable is a 6 word sentence about the theme.
                 Below you can view your students' work.`,
        screen_id: '1.0',
        current_field: 'warmup_sixwords',
        default_field: 'warmup_sixwords',
        fields: [
            { name: 'warmup_sixwords', title: 'Six Word Statement' },
            { name: 'warmup_questions', title: 'Questions They Had' },
            { name: 'warmup_quotes', title: 'Quotes They Heard' },
            { name: 'warmup_connections', title: 'Connections They Made' },
        ],
        deliverable: {
            step: '1.7',
            hint: `In the Empathy Warmup stage, you will review each
                   of the Six Word Sentences from your students and
                   then collectively decide on one sentence for the
                   whole class.`,
            fields: [
                {name: 'warmup_sixwords', title: 'Six Word Statement'}
            ],
        },
    },

    feel: {
        hint: `In the Feel Stage, students will consider what they
                 learned about the theme to identify root causes.
                 The deliverable is a root cause analysis. Below you
                 can view your students' work.`,
        screen_id: '2.0',
        current_field: 'feel_rootcause_issues',
        default_field: 'feel_rootcause_issues',
        fields: [
            { name: 'feel_rootcause_issues', title: 'Issues' },
            { name: 'feel_rootcause_symptoms', title: 'Symptoms' },
            { name: 'feel_rootcause_roots', title: 'Roots' },
        ],
        deliverable: {
            step: '2.4',
            hint: `In the Feel stage, you will review the Issues, Symptoms
                   and Root statements from your students. You will select
                   a single entry for each one based upon a discussion
                   with the students.`,
            fields: [
                {name: 'feel_howmightwe_start', title: 'How might we...'},
                {name: 'feel_howmightwe_end', title: '...in a world where...'},
            ],
        },
    },

    imagine: {
        hint: `In the Imagine Stage, students will brainstorm
               ideas for combatting underlying causes of the issue.
               The deliverable is a set of ideas for their project.
               Below you can view your students' work.`,
        screen_id: '3.0',
        current_field: 'imagine_idea_1',
        default_field: 'imagine_idea_1',
        fields: [
            { name: 'imagine_idea_1', title: 'Idea #1' },
            { name: 'imagine_idea_2', title: 'Idea #2' },
            { name: 'imagine_idea_3', title: 'Idea #3' },
            { name: 'imagine_idea_4', title: 'Idea #4' },
        ],
        deliverable: {
            step: '3.3',
            hint: `In the Imagine stage, you'll go through a card sorting
                   exercise where you'll organize the ideas submitted by
                   your students. You'll then pick one idea that the
                   students will implement in the Do stage.`,
            fields: [
                {name: 'imagine_classidea', title: 'Class Idea'}
            ],
        },
    },

    do: {
        hint: `In the Do Stage, students will create a plan for the
                 action they will take and they will take action. The
                 deliverable is their reflection on the project
                 through an I Wish, I Like, I Wonder activity. Below
                 you can view your students' work.`,
        screen_id: '4.0',
        current_field: 'do_wish',
        default_field: 'do_wish',
        fields: [
            { name: 'do_wish', title: 'I Wish' },
            { name: 'do_like', title: 'I Like' },
            { name: 'do_wonder', title: 'I Wonder' },
        ],
        deliverable: {
            step: '4.4',
            hint: `In the Do stage, you'll lead a discussion about the
                   students' responses to the I Wish, I Like, I Wonder
                   questions. You'll then decide, as a group, a
                   collective answer to each of the questions.`,
            fields: [
                {name: 'do_wish', title: 'I Wish'},
                {name: 'do_like', title: 'I Like'},
                {name: 'do_wonder', title: 'I Wonder'},
            ],
        },
    },

    share: {
        hint: `In the Share Stage, students will reflect on lessons
                 learned through the Design for Change process. The
                 deliverable is their reflection on these lessons.
                 Below you can view your students' work.`,
        screen_id: '5.0',
        current_field: 'share_learnmyself',
        default_field: 'share_learnmyself',
        fields: [
            { name: 'share_learnmyself', title: 'Learn About Myself' },
            { name: 'share_learnissue', title: 'Learn About The Issue' },
            { name: 'share_learnissue', title: 'Learn About The Community' },
        ],
        deliverable: {
            step: '5.4',
            hint: `In the Share stage, you'll review the selfie videos
                   your students recorded reflecting on the lessons
                   they learned.`,
        },
    }
};

export default all_stage_options;