<template>
  <div class="title-bar" :style="background_image ? 'background-image: url(' + background_image + ')' : ''">
    <div class="inner">
      <div class="menu-opener" @click="$root.$emit('menu')">
        <i class="fas fa-bars"></i>
      </div>
      <div class="plain" v-if="layout === 'plain'">
        <div class="wrap">
          <h1 class="title">{{ title }}</h1>
          <p class="description">{{ description }}</p>
        </div>
      </div>
      <div class="project" v-else-if="layout === 'project'">
        <div class="wrap">
          <div class="left">
            <div class="icon"><img :src="projectIconUrl(project.course)" alt=""></div>
            <h1 class="name">{{ project.name }}</h1>
            <div class="grade">{{ project.grade_level ? inflect.titleize( project.grade_level ) : '' }}</div>
            <div class="type text-uppercase">{{ project.course }}</div>
          </div>
          <div class="right">
            <h2>{{ title }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "../mixins";

export default {
  name: "TitleBar",
  mixins: [mixins],

  props: {
    background: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    project: {
      type: Object,
      default(){
        return {
          name: '',
          grade_level: '',
          course: '',
        }
      }
    },
    layout: {
      type: String,
      default: 'plain'
    }
  },

  computed: {
    background_image(){
      return this.background ? this.background : ( this.layout === 'project' ? this.project.picture : '' );
    }
  }

}
</script>

<style lang="scss" scoped>
.title-bar{
  color: white;
  background: transparent no-repeat center / cover;
}

.inner{
  position: relative;
  padding: 6px 30px 6px 30px;
  background: rgba(red, 0.8);

  @media screen and (max-width: 767px) {
    padding-left: 50px;
  }
}

.plain{

  .wrap{
    display: flex;
    margin-left: 0 -1rem;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    > * {
      padding: 0 1rem;
      margin: 1rem 0;

      @media screen and (max-width: 767px) {
        margin: 0.3rem 0;
      }
    }

    .title{
      flex: 1 1 auto;
      font-size: 1.35rem;
      font-weight: 600;
    }

    .description{
      flex: 1 1 auto;
      text-align: right;
    }

  }
}

.project{

  .wrap{
    display: flex;
    margin: 0 -1rem;
    align-items: center;
    justify-content: space-between;

    //@media screen and (max-width: 767px) {
    //  flex-direction: column;
    //}

    > * {
      padding: 0 1rem;
      margin: 0.6rem 0;

      @media screen and (max-width: 767px) {
        margin: 0.3rem 0;
      }
    }
  }

  .left{
    padding-left: 100px;
    position: relative;

    .icon{
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);

      img{
        display: block;
        width: 60px;
        height: 60px;
        object-fit: contain;
        background: white;
        padding: 6px;
        border-radius: 50%;
        box-shadow: rgba(black, 0.3) 0 3px 6px;
      }
    }
  }

  .right{
    text-align: right;
  }

  .name{
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0;
  }
  .grade{
    font-size: 0.85rem;
  }
  .type{
    font-size: 0.85rem;
    font-weight: bold;
  }
  h2{
    font-size: 1.6rem;
    margin: 0;
    font-weight: 600;
  }
}

.menu-opener {
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: white;
  }

}


</style>