<template>
  <div class="library-archive">
    <title-bar title="My Library"
               class=""
               background="/dfcusa-web/img/img_hedr_teachers.png"/>
      <dash-section title=""
                description="This is your full library of articles, videos, and content you can assign to your students. Add, manage, and preview all of your existing content here. You can customize your lesson plans by selecting the content you want students to learn under each of your open projects."
                class="mb-4">
                  <div class="content-archive">
                    <div class="actions my-3">
                      <div class="filters">
                        <a v-for="f in filter_courses" :key="f.filter"
                          href="#"
                          @click.prevent="filter.course = f.filter"
                          :class="{active: f.filter === filter.course}"
                          class="filter">{{ f.title }}</a>
                      </div>
                      <div class="primary-action">
                        <router-link class="btn btn-danger text-uppercase"
                                    :to="{ name: 'my-library.create' }">
                          <i class="fas fa-plus"></i> Add New Item
                        </router-link>
                      </div> 
                    </div>

                    <div class="content-area">
                      <div class="library mb-4 row align-items-stretch" v-if="!library_busy">
                        <div v-for="item in library" :key="item.id"
                            class="podcast col-lg-4 col-md-6 mb-4">
                          <!-- Library -->
                          <!-- Make editable to !! -->
                          <card-selector type="checkbox"
                                        :selectable="false"
                                        :deselectable="false"
                                        :editable="!!item.organization_id"
                                        :card="{
                                          id: item.id,
                                          title: item.title,
                                          description: item.description,
                                          image: libraryImageUrl(item),
                                          type: item.type,
                                          difficulty: item.level,
                                          preview: item.url,
                                          edit: {name: 'my-library.edit', params: {id: item.id}},
                                        }"></card-selector>
                        </div>
                      </div>

                      <div v-if="!library.length">
                        <div class="card text-center">
                          <div class="card-body py-5">
                            <p>Sorry, no library items found.</p>
                            <router-link :to="{name: 'my-library.create'}" class="btn btn-primary">
                              <i class="fas fa-plus mr-1"></i> Add New Item
                            </router-link>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div><!-- end of content archive-->
    </dash-section>
  </div>
</template>


<script>
import API from "@/api";
import TitleBar from "../../components/TitleBar";
import DashSection from "@/components/DashSection";
import CardSelector from "../../components/CardSelector";
import mixins from "@/mixins";
import tracker from "@/mixins/tracker";


export default {
  name: "MyLibrary",
  components: {TitleBar, CardSelector, DashSection},
  mixins: [mixins, tracker],

  data() {
    return {
      filter: {
        course: '',
      },
      library: [],
      library_busy: false,

      filter_courses: [
        {title: 'All', filter: ''},
        {title: 'Racial Justice', filter: 'justice'},
        {title: 'Climate Action', filter: 'climate'},
        {title: 'Education Equity', filter: 'education'},
      ],
    };
  },


  watch: {
    filter: {
      deep: true,
      handler(){
        this.refresh();
      }
    }
  },

  methods: {
    async refresh() {

      // TODO: Image transformation: https://cdn.filestackcontent.com/resize=width:480/crop=dim:[0,0,480,360]/n7u2FSNjQU6e63F7qDaZ
      // TODO: Image transformation: https://cdn.filestackcontent.com/resize=h:480,w:360,fit:crop/n7u2FSNjQU6e63F7qDaZ
      // TODO: Document transformation: https://cdn.filestackcontent.com/ApAGlzhR0TS2QZCuWqVg8z/doc_to_images@1/n7u2FSNjQU6e63F7qDaZ

      try {
        let library = await API.getLibrary(this.filter.course, true);
        library = typeof library === "string" ? JSON.parse(library) : library;
        this.library.length = 0;
        library.map((item) => this.library.push(item));

        const course_type_name = this.filter_courses.reduce((acc, val) => val.filter === this.filter.course ? val.title : acc, null);

        this.$forceUpdate();

        this.setPageTitle( 'My Library' + (this.filter.course ? ' (' + course_type_name + ')' : '') );
        this.tracker.visit(this.$route);

      } catch (e) {
        /**/
      }
    },
  },

  beforeMount() {
    this.setPageTitle( 'My Library' );
  },

  mounted() {
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  margin: 0 -10px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  > * {
    padding: 0 10px;
    margin-bottom: 1rem;
  }
}

.filters {
  padding-left: 40px;
  display: flex;

  .filter {
    background: #f0f0f0;
    color: black;
    border: #c0c0c0 solid 1px;
    padding: 0.2rem 0.8rem;
    font-size: 0.9rem;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1.4rem;

    &:first-child {
      border-radius: 20px 0 0 20px;
    }

    &:last-child {
      border-radius: 0 20px 20px 0;
    }

    &.active {
      background: black;
      color: white;
      border: black solid 1px;
    }
  }
}
</style>