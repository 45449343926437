<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="112.5" height="118.688" viewBox="0 0 112.5 118.688"><defs><filter id="a" x="0" y="0" width="112.5" height="118.688" filterUnits="userSpaceOnUse"><feOffset input="SourceAlpha"/><feGaussianBlur stdDeviation="15" result="b"/><feFlood flood-color="#007bff"/><feComposite operator="in" in2="b"/><feComposite in="SourceGraphic"/></filter></defs><g class="b" transform="matrix(1, 0, 0, 1, 0, 0)"><path class="a" d="M6.75,3.656V32.344L29.25,18Z" transform="translate(38.25 41.34)"/></g></svg>
</template>

<script>
export default {
  name: "IconPlay",
}
</script>
<style>
  .a{fill:currentColor;}
  .b{filter:url(#a);}
</style>