<template>
  <div class="class-settings">
    <title-bar title="Settings"
               layout="project"
               :project="active_project"/>


    <!-- Class Settings -->
    <dash-section title="Class Settings"
                  description="Enter information identifying your class."
                  class="mb-5"
                  :action="{title: '<i class=\'fas fa-trash mr-1\'></i> Delete Class', click: actionDeleteClass}">

      <template #action>
        <router-link :to="{name: 'grant.home'}" class="btn btn-primary mb-2">
          Submit your project for a chance to win a $500 award <i class="fas fa-angle-right ml-1"></i>
        </router-link>
      </template>

      <div class="row mt-3">
        <div class="col-sm-7 mb-3">
          <div class="form-group">
            <div class="row">
              <label class="col-sm-4 col-form-label" for="class-name">Class Name</label>
              <div class="col-sm-8">
                <input type="text"
                       class="form-control"
                       id="class-name"
                       v-model="active_project.name"
                       placeholder="Class Name">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-sm-4 col-form-label" for="class-grade-level">Grade Level</label>
              <div class="col-sm-8">
                <select type="text"
                       class="form-control"
                       id="class-grade-level"
                       v-model="active_project.grade_level">
                  <option value="elementary_school">Elementary Grade</option>
                  <option value="middle_school">Middle School</option>
                  <option value="high_school">High School</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-sm-4 col-form-label" for="class-theme">Theme</label>
              <div class="col-sm-8">
                <select type="text"
                       class="form-control"
                       id="class-theme"
                       v-model="active_project.course">
                  <option value="climate">Climate Action</option>
                  <option value="education">Educational Equity</option>
                  <option value="justice">Racial Justice</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-5 mb-3">
          <stage-selector title="Current Stage"
                            description="Students can move through the platform at their own
                                         pace. At the end of each stage, there is an
                                         opportunity to bring all students together for a
                                         collaborative activity. You can pause students at
                                         each stage. Select the stage below at which you’d
                                         like students to pause."
                            :options="stage_bookmarks"
                            v-model="active_project.bookmark_step"/>
        </div>
      </div>

    </dash-section>
    <!-- Class Settings Ends -->


    <!-- Class Rooster -->
    <dash-section title="Class List"
                  description="Enter a list of your students' names - first name and last initial
                               - to the class list. This will generate a unique access code for
                               each student."
                  class="mb-5"
                  :action="{title: '<i class=\'fas fa-plus mr-1\'></i> Add Student', click: actionAddStudent}">
      <div class="row">
        <div class="col-sm-7">
          <student-list ref="students_list" :project="active_project" :total-steps="25"/>
        </div>
        <div class="col-sm-5">
          <h6>Student Link</h6>
          <p class="description">
            Send this link to your students. Students can visit this link to access the student version of the platform. When they first visit the link, they will be asked to select their name and enter their access code from above.
          </p>
          <copy-button class="btn btn-success text-light btn-block overflow-hidden mt-3 mb-2 py-2"
                       :content="projectUrlForStudents(active_project)">
            <div class="smaller">
              {{ projectUrlForStudents(active_project) }}
            </div>
            <template v-slot:success>
              <div class="smaller">
                <i class="fas fa-clipboard-check"></i> Copied
              </div>
            </template>
          </copy-button>
          <div class="small text-center">
            <i class="fas fa-info-circle text-warning"></i> Click the link to copy to clipboard.
          </div>
        </div>
      </div>
    </dash-section>
    <!-- Class Rooster Ends -->


  </div>
</template>

<script>

import API from "../api";

import TitleBar from "../components/TitleBar";
import mixins from "../mixins";
import DashSection from "../components/DashSection";
// import PrimaryDropdown from "../components/PrimaryDropdown";
//import CardSelector from "../components/CardSelector";
import StudentList from "../components/StudentList";
import CopyButton from "../components/CopyButton";
import StageSelector from "@/components/StageSelector";
import tracker from "@/mixins/tracker";
export default {
  name: "ClassSettings",
  components: {StageSelector, CopyButton, StudentList/*, CardSelector, PrimaryDropdown*/, DashSection, TitleBar},
  mixins: [mixins, tracker],

  data(){
    return {
      stage_bookmarks: {
        '0.0': 'Getting Started',
        '1.0': 'Empathy Warmup',
        '2.0': 'Feel',
        '3.0': 'Imagine',
        '4.0': 'Do',
        '5.0': 'Share'
      }

    };
  },

  computed: {
    selected_library(){
      if(!this.active_project.library_items) return [];
      var libs = [];
      var i;
      for (i = 0; i < this.active_project.library_items.length; i++) {
        var j;
        for (j = 0; j < this.library.length; j++) {
          if (this.library[j].id == this.active_project.library_items[i]) {
            libs.push(this.library[j]);
          }
        }
      }
      return libs;
    },

  },

  methods: {

    actionAddStudent(){
      this.$refs.students_list.actionAddStudent()
    },

    async actionDeleteClass() {
      this.$swal({
        title: 'Remove Student?',
        html: `
            Are you sure to delete this class
            <strong class="text-danger">${this.active_project.name}</strong>?
            This action can't be undone`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, Remove.'
      }).then(({ isConfirmed }) => {
        isConfirmed && this.deleteClass();
      });

    },

    async deleteClass(){

      const name = this.active_project.name;
      await API.removeClass(this.active_project.id);

      let idx = this.sidebar.list.indexOf( this.active_project.id );
      if( idx >= 0 ) this.sidebar.list.splice(idx, 1);

      idx = this.projects.indexOf( this.active_project );
      if( idx >= 0 ) this.projects.splice(idx, 1);

      this.$notify({
        group: 'projects',
        title: name + ' Deleted',
        type: 'success',
        text: 'The class has been deleted successfully.'
      });

      await this.$router.push({name: 'class.dashboard'});

    },

  },

  async mounted() {
    // this.refresh();
    // this.library_busy = true;
    // this.library = await API.getLibrary(this.active_project.course);
    // this.library_busy = false;

    this.setPageTitle( 'Settings - ' + this.active_project.name );
    this.tracker.visit(this.$route);

  }

}
</script>

<style lang="scss" scoped>
.smaller{
  font-size: 0.85em;
}
</style>