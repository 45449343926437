<template>
  <div class="stage-selector">
    <h4>
      {{ title }}
      <button class="help" v-b-tooltip.hover.focus :title="description">
        <i class="fas fa-question-circle"></i>
      </button>
    </h4>
<!--    <p class="small text-muted">{{ description }}</p>-->
    <div class="stages">
      <div v-for="(value, key) in options" :key="key"
           class="stage"
           tabindex="1"
           :class="{active: content === key, open: position(key) < 0}">
        <span class="check"><i class="far fa-hand-paper"></i></span>
        <span class="label">
          {{ value }}
        </span>
        <a v-if="content !== key"
           href="#"
           class="btn"
           :class="position(key) > 0 ? 'btn-danger' : 'btn-light'"
           @click.prevent="select(key)">
          <i class="far fa-hand-paper"></i> STOP
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StageSelector",
  props: ['title', 'description', 'options', 'value'],

  data(){
    return {
      content: this.value,
    }
  },

  computed: {
    selected(){
      return this.options[ this.content ];
    },
  },

  methods: {
    select(option) {
      if (this.content !== option) {
        this.content = option;
        this.$emit('input', this.content);
      }
    },

    position(key){
      return Object.keys( this.options ).indexOf(key) - Object.keys( this.options ).indexOf(this.content)
    },

  }

}
</script>

<style lang="scss" scoped>

//$color: #e3003e;
$color: #007Bff;


.stage-selector{
  padding-left: 1rem;
}

h4{
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 0.6rem;
}

.stages{
  border: rgba(black, 0.1) solid 1px;
  border-radius: 0.3rem;
}

.stage{
  position: relative;
  padding: 0.3rem 0.4rem 0.3rem 1.6rem;
  justify-content: space-between;
  align-items: center;
  line-height: 2.3rem;
  border-bottom: rgba(black, 0.1) solid 1px;

  &:first-child{
    border-radius: 0.3rem 0.3rem 0 0;
  }

  &:last-child{
    border-bottom: none;
    border-radius: 0 0 0.3rem 0.3rem;
  }

  &:hover, &:focus{
    outline: none;

    .btn{
      display: block;
    }
  }

  .btn{
    display: none;
    position: absolute;
    right: 0.4rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.active{
  background: $color;
  color: white;

  .check{
    opacity: 1;
  }
}
.open{
  background: $color;
  color: white;
}

.check{
  opacity: 0;
  transition: opacity .3s;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(black, 0.15) 0 0 6px;
  border: rgba(black, 0.15) solid 1px;
}

.help{
  border: none;
  background: none;
  padding: 0;
}

</style>