<template>
  <div class="podcast-list-item"
       :class="{featured: featured, audio: is_audio, video: is_video}"
       :style="`background-image: url(${picture})`">
    <div class="inner">
      <div class="special" v-if="featured">
        <div class="line"><i class="fas fa-star"></i> Featured</div>
      </div>
      <div class="specs">
        <div class="line"><i class="fas fa-clock"></i> {{ podcast.duration }}</div>
        <div class="line"><i class="fas fa-cog"></i> {{ podcast.difficulty }}</div>
        <div class="line" v-if="is_video"><i class="fas fa-video"></i> Video Podcast</div>
      </div>
      <div class="actions">
        <a class="btn btn-lg btn-light d-flex align-items-center" target="_parent"
           :href="`https://www.designforchange.us/podcast-embed?name=${podcast.short_name}`">
          <icon-video v-if="is_video" />
          <icon-audio v-if="is_audio" />
          <span class="ml-2">Preview</span>
        </a>
        <a v-if="selectable"
           class="btn btn-lg btn-light d-flex align-items-center mt-2"
           @click.prevent.stop="$emit('selected', podcast)"
           href="#">
          <i class="fas fa-check"></i>
          <span class="ml-2">Select</span>
        </a>
      </div>
      <div class="caption">
        <div class="icon">
          <icon-video class="ico" v-if="podcast.type === 'video'" />
          <icon-audio class="ico" v-if="podcast.type === 'audio'" />
        </div>
        <h6>{{ podcast.name }}</h6>
        <p class="mb-0">{{ podcast.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import IconAudio from "@/icons/IconAudio";
import IconVideo from "@/icons/IconVideo";

export default {
  name: "PodcastListItem",
  components: {IconAudio, IconVideo},
  props: ['podcast', 'featured', 'selectable', 'newtab'],

  computed: {

    excerpt(){
      const max_length = 120;
      const desc = this.podcast.description;
      return desc.length > max_length ? desc.substr(0, max_length - 3) + '...' : desc;
    },

    is_video() {
      return this.podcast.type === 'video';
    },

    is_audio() {
      return this.podcast.type === 'audio';
    },

    picture(){
      return this.featured ? this.podcast.picture_large : this.podcast.picture;
    }

  }

}
</script>

<style lang="scss" scoped>
.podcast-list-item{
  background: transparent no-repeat center / cover;
  border-radius: 19px;
  border: white solid 6px;
  box-shadow: 0 3px 8px #00000029;
  position: relative;
  height: 480px;
  margin-left: 1rem;

  .inner{
    position: absolute;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border-radius: 18px;
    border: transparent solid 6px;
    transition: background .3s, border .3s;
  }

  .special{
    position: absolute;
    top: 16px;
    right: auto;
    left: -16px;
    padding: 10px 15px;
    background: #fac90e;
    color: black;
    text-transform: uppercase;
    border-radius: 0.5rem;
    font-weight: bold;
    box-shadow: 0 3px 6px #00000029;

    i{
      margin-right: 0.4rem;
    }
  }

  .actions{
    opacity: 0;
    transition: opacity .3s;
  }

  &:hover{
    .inner{
      background: #007BFFE5;
      border-color: #007BFFE5;
    }
    .actions{
      opacity: 1;
    }
  }

  &.featured{

    .special{
      right: -16px;
      left: auto;
    }

    .specs{
      left: -16px;
      right: auto;
      background: #ed1651;
    }

    .caption{
      font-size: 1rem;
    }
  }


  &.audio{
    .icon{
      background: #007BFF;
    }
  }

  .specs{
    position: absolute;
    top: 16px;
    left: auto;
    right: -16px;
    background: #23252c;
    padding: 10px;
    border-radius: 9px;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: 0 3px 6px #00000029;

    i{
      margin-right: 0.4rem;
    }
  }

  .actions{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -30px;
  }

  .caption{
    position: absolute;
    right: -5px;
    bottom: -5px;
    left: -5px;
    padding: 16px 16px 16px 26px;
    background: rgba(white, 0.9);
    border-radius: 0 0 17px 17px;
    font-size: 0.9rem;
  }

  h6{
    font-size: 23px;
  }

  .icon{
    background: #ed1651;
    color: white;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -2px;
    top: 14px;
    transform: translate(-50%);

    .ico{
      width: 18px;
      height: auto;
    }
  }


  &.is-selected{
    .inner{
      border-color: #007BFFE5;
    }
  }

}
</style>