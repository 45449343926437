<template>
  <section class="section" :class="{'is-busy': busy}">
    <div class="head">
      <div class="area">
        <div class="info">
          <h3 class="title" v-html="title"></h3>
          <p v-if="description" class="description" v-html="description"></p>
        </div>
        <div class="action">
          <slot name="action"></slot>
          <button class="btn btn-danger text-uppercase"
                  v-if="action"
                  :disabled="busy"
                  type="button"
                  @click.prevent="action.click"
                  v-html="action.title"/>
        </div>
      </div>
    </div>
    <div class="section-content">
      <div v-if="busy" class="loading"><i class="fas fa-circle-notch fa-spin"></i></div>
      <div v-else class="content-area">
        <slot>This section is empty.</slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "DashSection",
  props: {
    title: {type: String, default: 'Section Title'},
    description: {type: String, default: ''},
    action: {
      type: [Object, undefined],
      default: undefined,
    },
    busy: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>
.section{
  &.is-busy{
    user-select: none;
    position: relative;

    &:before{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(white, 0.6);
      backdrop-filter: blur(2px);
      z-index: 5;
      cursor: not-allowed;
    }
  }
}

.area{
  padding: 10px 30px;

  .action{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media all and (min-width: 768px){
      justify-content: flex-end;
    }

    .btn{
      white-space: nowrap;
    }
  }
}
h3{
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
}
p{
  font-style: italic;
  color: lighten(black, 60%);
  margin-top: 0.2rem;
  margin-bottom: 0;
  font-size: 0.9rem;
}
.head{
  background: #f0f0f0;
  margin-bottom: 1.6rem;

  .area{
    display: flex;
    margin: 0 -1rem;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }

    > * {
      padding: 0 1rem;
      margin: 0.6rem 0;
    }
  }
}
.loading{
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: red;
}
</style>