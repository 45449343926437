<template>
  <div class="grant-wizard">

    <div v-if="is_busy" class="py-5" style="background: rgba(0,0,0, 0.05)">
      <div class="processing py-5 text-center fa-2x">
        <i class="fas fa-spinner fa-spin fa-2x"></i>
        <div class="small mt-5">
          Working on...
        </div>
      </div>
    </div>
    <template v-else>
      <div v-if="step_index > 0" class="progress-wrap">
        <div class="container">
          <progress-bar :progress="progress"></progress-bar>
        </div>
      </div>

      <div>
        <template v-if="current_step_id === 'intro'">
          <grant-step-intro :values="current_step.values" @start="next()"/>
        </template>

        <template v-if="current_step_id === 'teacher'">
          <grant-step-teacher :values="current_step.values" :errors="errors"/>
        </template>

        <template v-if="current_step_id === 'project'">
          <grant-step-project :values="current_step.values" :errors="errors"/>
        </template>

        <template v-if="current_step_id === 'story'">
          <grant-step-story :values="current_step.values" :errors="errors"/>
        </template>

        <template v-if="current_step_id === 'account'">
          <grant-step-account :values="current_step.values" :errors="errors"/>
        </template>
      </div>

      <div class="container">

        <div class="wiz-footer mt-4 mb-5">
          <button class="btn btn-warning btn-lg mx-2"
                  v-if="has_prev"
                  @click.prevent="prev()">
            <i class="fas fa-angle-left mr-1"></i> BACK
          </button>
          <button class="btn btn-secondary btn-lg mx-2"
                  v-if="!is_first"
                  :id="click_id"
                  @click.prevent="next()">
            {{ current_step_id === 'story' ? 'SUBMIT' : 'NEXT' }} <i class="fas fa-angle-right ml-1"></i>
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import mixins from "@/mixins";
import jQuery from "jquery";
import { debounce } from 'vue-debounce';
import GrantStepIntro from "@/views/grant/GrantStepIntro";
import GrantStepTeacher from "@/views/grant/GrantStepTeacher";
import GrantStepProject from "@/views/grant/GrantStepProject";
import GrantStepStory from "@/views/grant/GrantStepStory";
import ProgressBar from "@/components/ProgressBar";
import API from "@/api";
import validators from "@/helpers/validators";
import GrantStepAccount from "@/views/grant/GrantStepAccount";
import CRYPT from "@/helpers/CRYPT";

export default {
  name: "GrantWizard",
  components: {GrantStepAccount, ProgressBar, GrantStepStory, GrantStepProject, GrantStepTeacher, GrantStepIntro},
  mixins: [mixins],

  data() {
    return {
      is_busy: false,
      step_index: 0,
      step_order: ['intro', 'teacher', 'project', 'story'],

      errors: [],

      uid: null,
      submission_id: null,

      steps: {
        intro: {},

        teacher: {
          values: {
            email: '',
            first_name: '',
            last_name: '',
            school_name: '',
            city: '',
            state: '',
          },
          async validate(values) {
            const errors = [];
            for (const i in values){
              if( validators.is_empty( values[i] ) ){
                errors.push({field: i, message: `This field is required.`});
              }
            }

            if( !validators.is_email(values.email) ){
              errors.push({field: 'email', message: `Not a valid email.`});
            }

            return errors;
          }
        },

        project: {
          values: {
            project: null,
            theme: '',
            processes: [],
            grade_level: '',
            number_of_students: '',
            feel: '',
            imagine: '',
            do: {actions: '', files: []},
            share: {experience: '', knowledge: '', files: []},
          },
          async validate(values) {
            const errors = [];
            if( validators.is_empty(values.theme) ) errors.push({field: 'theme', message: 'This field is required.'});
            if( validators.is_empty(values.grade_level) ) errors.push({field: 'grade_level', message: 'This field is required.'});
            if( validators.is_empty(values.processes) ) errors.push({field: 'processes', message: 'Choose at least one option.'});
            if( validators.is_empty(values.number_of_students) ) errors.push({field: 'number_of_students', message: 'This field is required.'});
            if( parseInt(values.number_of_students) <= 0 ) errors.push({field: 'number_of_students', message: 'You must have at least 1 student.'});
            if( validators.is_empty(values.feel) ) errors.push({field: 'feel', message: 'This field is required.'});
            if( validators.is_empty(values.imagine) ) errors.push({field: 'imagine', message: 'This field is required.'});
            if( validators.is_empty(values.do.actions) ) errors.push({field: 'do.actions', message: 'This field is required.'});
            if( validators.is_empty(values.share.experience) ) errors.push({field: 'share.experience', message: 'This field is required.'});
            if( validators.is_empty(values.share.knowledge) ) errors.push({field: 'share.knowledge', message: 'This field is required.'});
            return errors;
          }
        },

        story: {
          values: {
            impact: '',
            impact_count: '',
            additional_stories: '',
            rating: -1,
            // surprise: '',
          },
          async validate(values) {
            const errors = [];
            if( validators.is_empty(values.impact) ) errors.push({field: 'impact', message: 'This field is required.'});
            if( validators.is_empty(values.impact_count) ) errors.push({field: 'impact_count', message: 'This field is required.'});
            if( parseInt(values.impact_count) < 0 ) errors.push({field: 'impact_count', message: 'Number of people must be at least 0.'});
            if( validators.is_not_between(values.rating, 0, 10) ) errors.push({field: 'rating', message: 'Please choose a star between 1 to 10.'});
            // if( validators.is_empty(values.additional_stories) ) errors.push({field: 'additional_stories', message: 'This field is required.'});
            // if( validators.is_empty(values.surprise) ) errors.push({field: 'surprise', message: 'This field is required.'});
            return errors;
          }
        },

        account: {
          values: {
            create_account: true,
            create_account_password: '',
            create_account_retype_password: '',
          },
          async validate(values) {
            const errors = [];
            if( values.create_account ){
              if(validators.is_empty(values.create_account_password)){
                errors.push({field: 'create_account_password', message: 'This field is required.'});
              }else if(values.create_account_password !== values.create_account_retype_password){
                errors.push({field: 'create_account_retype_password', message: 'Passwords do not match.'});
              }
            }
            return errors;
          }
        }
      }
    }
  },

  computed: {

    click_id(){
      switch (this.current_step_id){
        case 'teacher': return 'passed-school-info';
        case 'project': return 'passed-project-info';
      }
      return '';
    },

    progress(){
      return {
        max: Object.keys(this.steps).length - 1,
        value: this.step_index,
      };
    },

    current_step() {
      return this.steps[this.current_step_id];
    },

    current_step_id() {
      return this.step_order[this.step_index];
    },

    is_first() {
      return this.step_index === 0;
    },

    is_last() {
      return this.step_index === this.step_order.length - 1;
    },

    has_prev() {
      return this.step_index > 0 && this.step_index < 4;
    },

    has_next() {
      return this.step_index < this.step_order.length - 1;
    },
  },

  watch: {
    steps: {
      deep: true,
      handler(steps){
        if( this.is_busy ) return;
        if( this.step_index > 3 ) return;

        /**
         * Save only specific steps.
         */

        const values = ['teacher', 'project', 'story']
          .filter(key => typeof steps[key].values !== 'undefined')
          .map(key => ({key, values: steps[key].values}));

        this.save(values);

      }
    }
  },

  methods: {

    save: debounce(function(values){
      const json_values = JSON.stringify(values);


      /**
       * Save to localstorage.
       */
      localStorage.setItem('grantsave', json_values);


      /**
       * On submission of each change, we'll proactively submit the data to database.
       * That's why we do not need to send save anything when moving from once step
       * to another, as that is already saved by this call.
       */

      this.submit();

      console.log('Saved: ', json_values);

    }, 1000),

    saveExists(){
      const saved = localStorage.getItem('grantsave');
      console.log('Save Exists: ', saved);
      return saved && JSON.parse( saved );
    },

    restoreSaved(){

      /**
       * Get saved progress from localstorage and restore to our wizard data.
       */

      let values = localStorage.getItem('grantsave');
      if( values ) values = JSON.parse(values);
      values.forEach(v => this.steps[v.key].values = jQuery.extend(this.steps[v.key].values, v.values));


      // /**
      //  * Submit to save current progress, this is needed in restore, because some
      //  * old users may not have anything saved to our database.
      //  */
      //
      // this.submit();

      console.log('Saved progress restored', {restore: values, steps: this.steps});
    },

    deleteSaved(){

      /**
       * Delete saved data in our localstorage. We'll keep the old UID, to make sure
       * the data stored in the database also get replaced buy this later on.
       */

      localStorage.removeItem('grantsave');

      console.log('Saved progress deleted.');
    },

    prev() {
      if (this.has_prev) {
        this.step_index--;
        this.scrollToTop();
      }
    },

    async next() {
      console.log(this.current_step);

      if (typeof this.current_step.validate !== 'undefined') {
        this.errors = await this.current_step.validate(this.current_step.values);
      }

      if (!this.errors || this.errors.length === 0) {

        /**
         * Submit the data after story step.
         * But continue the wizard next.
         */

        if( this.current_step_id === 'story' ){
          console.log('Final Submit');
          await this.submit(true);
        }

        /**
         * If this is account step then check if user wants to create
         * an account. If yes, then create an account then redirect to
         * grant success page, otherwise skip creating account and
         * directly send user to grant success page.
         */
        if( this.current_step_id === 'account' ){

          if( this.steps.account.values.create_account ){
            await this.createAccount();
          }

          return await this.$router.push({name: 'grant.success', params: {id: this.submission_id}});

        }


        /**
         * If this is the last step in the wizard, then it will
         * mandatorily redirect user to grant success page.
         */

        if( this.step_index === this.step_order.length - 1 ){
          return await this.$router.push({name: 'grant.success', params: {id: this.submission_id}});
        }

        this.step_index++;
        this.scrollToTop();


      }
    },

    async createAccount(){
      this.is_busy = true;

      await API.register({
        email: this.steps.teacher.values.email,
        first_name: this.steps.teacher.values.first_name,
        last_name: this.steps.teacher.values.last_name,
        location: this.steps.teacher.values.city,
        organization: this.steps.teacher.values.school_name,
        password: this.steps.account.values.create_account_password,
        project_id: '',
        site_id: '-1',
        state: this.steps.teacher.values.state,
        type: 'mentor',
      })

      this.is_busy = false;
    },

    async submit( is_final = false ) {

      // this.is_busy = true;

      /**
       * Merge and Transform the data to be saved in our databse.
       */

      const data = jQuery.extend({}, {uid: this.uid, is_final}, this.steps.teacher.values, {
        data: jQuery.extend({}, this.steps.project.values, this.steps.story.values),
      }, this.steps.account.values);

      const res = await API.submitGrant(data);
      this.submission_id = res.id;

      this.is_busy = false;


      /**
       * If this is a final step, then we should make sure to delete all
       * tracking & data saved in localstorage.
       */

      if( is_final ){

        this.deleteSaved();

        /**
         * Force auto-generate the UID, so we are sure that next we'll be filling a
         * new project instead replacing over previous one. We could also clear it,
         * but it doesn't matter.
         */

        this.autoGenerateUid(true);

      }
    },

    autoGenerateUid(force_create = false){
      let uid = this.$cookies.get('grant_uid');
      if( force_create || !uid ){
        uid = CRYPT.uid();
        this.$cookies.set('grant_uid', uid);
      }
      this.uid = uid;
      return uid;
    }
  },


  beforeMount() {
    this.autoGenerateUid();
  },

  mounted() {
    this.is_busy = true;

    if( this.$route.hash === '#continue'){
      this.step_index = Object.keys(this.steps).indexOf('teacher');
    }

    if( !this.current_user ){
      this.step_order.push('account');
    }

    if (this.current_user) {
      this.steps.teacher.values.email = this.current_user.email;
      this.steps.teacher.values.first_name = this.current_user.first_name;
      this.steps.teacher.values.last_name = this.current_user.last_name;
      this.steps.teacher.values.school_name = this.current_user.organization.name;
      this.steps.teacher.values.city = this.current_user.organization.city;
      this.steps.teacher.values.state = this.current_user.organization.state;
      this.steps.project.values.processes = ['DFC Online Platform'];
    }



    /**
     * If no saved point exists then ignore.
     */

    if( !this.saveExists() ){
      this.is_busy = false;
      return;
    }


    /**
     * Notify user about a previously saved data and ask to restore or not.
     */

    this.$swal({
      title: 'Restore Progress?',
      html: `
          It seems like you were already in the process of submitting for grant.
          It can be restored if you want.`,
      icon: 'info',
      showCancelButton: true,
      //cancelButtonColor: '#d33',
      cancelButtonText: 'Delete &amp; Start Fresh',
      confirmButtonColor: '#d33',
      confirmButtonText: '<i class="fas fa-undo"></i> Yes, Restore.'
    }).then(({ isConfirmed }) => {
      if( isConfirmed ){
        this.restoreSaved();
        // TODO: Remove test code.
        console.log({project: this.steps.project.values.project});
        this.step_index = Object.keys(this.steps).indexOf('teacher');

      }else{
        this.deleteSaved();
      }

      this.is_busy = false;
    });
  }

}
</script>
<style lang="scss" scoped>
.wiz-footer {
  text-align: center;
}
</style>