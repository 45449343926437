<template>
  <div class="grant-step-intro">

    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 my-3">
          <h1 class="h3 mb-5">Complete This Year's DFC Experience!</h1>
          <p>
            Submit images, videos, and stories of your Design for Change
            experience by May 31st for a chance to take your students'
            project to next level.
          </p>
          <h5 class="mt-4">All classes who submit their project will:</h5>
          <ul>
            <li>
              Have a chance to win one of our <strong>$500 awards</strong>
              to continue your project
            </li>
            <li>
              Be considered for presenting or participating in our annual
              <strong>"Be the Change" Conference</strong> (held virtually
              or in-person when conditions are safe!)
            </li>
            <li>
              Receive the annual Design for Change <strong>Completion
              Certificate</strong> that you've earned and deserve to take
              pride in!
            </li>
          </ul>

          <div class="mt-4 mt-md-4 text-center">
<!--            <router-link to="//www.designforchange.us/how-it-works" target="_blank" class="btn btn-primary btn-lg mr-3 mb-2">-->
<!--              HOW IT WORKS ?-->
<!--            </router-link>-->
            <button class="btn btn-secondary btn-lg mb-2"
                    @click.prevent="$emit('start')">
              START <i class="fas fa-angle-right ml-1"></i>
            </button>
            <template v-if="!current_user">
              <hr>
              <p>
                Already have an account?
              </p>
              <button class="btn btn-primary btn-lg mb-2" style="font-size: 1rem"
                      @click.prevent="login()">
                <i class="fas fa-sign-in-alt mr-1"></i>Log in to use your existing information
              </button>
            </template>
          </div>
        </div>
        <div class="col-md-6 my-3">
          <div class="d-none d-md-block">
            <img class="img-fluid rounded-lg shadow"
                 src="/img/grant-image.png"
                 alt="">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import mixins from "@/mixins";
import tracker from "@/mixins/tracker";
export default {
  name: "GrantStepIntro",
  mixins: [mixins, tracker],
  props: {
    values: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  methods: {
    login() {
      this.$router.push({name: 'auth.login', query: {back: window.location + '#continue'}});
    }
  },

  mounted() {
    this.setPageTitle( 'Grant Submission' );
    this.tracker.visit(this.$route);
  }

}
</script>

<style scoped>

</style>