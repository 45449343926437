import jQuery from 'jquery';
import cookies from 'vue-cookies';
import * as fs from 'filestack-js';

const API = {
    base: process.env.VUE_APP_API_BASE,

    __save_timeout: null,
    __save_xhr: null,
    __organizations: null,

    __filestack: {
        client: null,
        apikey: process.env.VUE_APP_FILESTACK_API_KEY,
    },

    timestamp(){
        return new Date().getTime();
    },

    getCurrentUser(){
        return new Promise((resolve, reject) => {
            jQuery.getJSON(`${API.base}/user/me?token=` + cookies.get('user'), resolve, 'json').fail(reject);
        });
    },

    login(email, password){
        return new Promise((resolve, reject) => {
            jQuery.post(`${API.base}/user/login`, JSON.stringify({ cookie: "0;", email, password }), resolve, 'json').fail(reject);
        });
    },

    sendPasswordRecoveryEmail(email){
        return new Promise((resolve, reject) => {
            email = encodeURIComponent( email );
            jQuery.getJSON(`${API.base}/user/requestreset/${email}?_=` + this.timestamp(), resolve, 'json').fail(reject);
        });
    },

    checkEmailExists(email){
        return new Promise((resolve, reject) => {
            email = encodeURIComponent( email );
            jQuery.getJSON(`${API.base}/user/check?email=${email}&_=` + this.timestamp(), (res) => resolve(`${res}` === '1') ).fail(reject);
        });
    },

    allOrganizations(){
        return new Promise((resolve, reject) => {
            if( this.__organizations ) return resolve(this.__organizations);
            jQuery.getJSON(`${API.base}/organizations/simple?_=` + this.timestamp(), res => {
                this.__organizations = res;
                resolve(this.__organizations);
            }).fail(reject);
        });
    },

    async searchOrganizations(keywords) {
        const geo = await this.geoLookup( keywords );
        const registered = await this.allOrganizations();
        return { geo, registered: registered ? registered.filter( n => n && n.match(keywords) ) : [] };
    },

    getOrganizationByName(name){
        return new Promise((resolve, reject) => {
            name = encodeURIComponent(name);
            jQuery.getJSON(`${API.base}/organization/byname/${name}?_=` + this.timestamp(), resolve).fail(reject);
        });
    },

    getOrganizationById(id){
        return new Promise((resolve, reject) => {
            id = encodeURIComponent(id);
            jQuery.getJSON(`${API.base}/organization/byid/${id}?_=` + this.timestamp(), resolve).fail(reject);
        });
    },

    geoLookup(keywords){
        return new Promise((resolve, reject) => {
            keywords = encodeURIComponent(keywords);
            jQuery.getJSON(`${API.base}/organizations/glookup/${keywords}?_=` + this.timestamp(), res => resolve( res.candidates )).fail(reject);
        });
    },

    geoPlace(place_id, components = {
        state: ['administrative_area_level_1', 'county'],
        location: ['locality', 'administrative_area_level_2'],
    }){
        return new Promise((resolve, reject) => {
            place_id = encodeURIComponent(place_id);

            jQuery.getJSON(`${API.base}/organizations/gplace/${place_id}?_=` + this.timestamp(), res => {
                const keys = Object.keys( components );
                const result = res.result;
                for( const idx in keys){
                    if( !Object.hasOwnProperty.call( keys, idx ) ) continue;
                    const key = keys[ idx ];
                    const values = components[key];

                    // Set empty string as default value.
                    result[ key ] = '';

                    result.address_components.forEach(c => {
                        for( const i in values ){
                            if( !Object.hasOwnProperty.call( values, i ) ) continue;
                            if( c.types.indexOf( values[i] ) >= 0 ){
                                result[ key ] = result[ key ].length === 0 ? c.short_name : result[ key ];
                                break;
                            }
                        }
                    });
                }

                resolve( result );

            }).fail(reject);
        });
    },

    register(data){
        return new Promise(((resolve, reject) => {
            jQuery.post(`${API.base}/user/new`, JSON.stringify(data), resolve, 'json').fail(reject);
        }));
    },

    resetPassword(data){
        return new Promise(((resolve, reject) => {
            jQuery.post(`${API.base}/user/reset?reset=` + new URL(location.href).searchParams.get('reset'), JSON.stringify(data), resolve, 'json').fail(reject);
        }));
    },

    getPodcasts(){
        return new Promise((resolve, reject) => {
            jQuery.getJSON( `${API.base}/podcasts/simple?_=` + API.timestamp(), resolve).fail(reject);
        });

        // return new Promise((resolve, reject) => {
        //     jQuery.getJSON( `simple.json?` + API.timestamp(), resolve).fail(reject);
        // });
    },

    getPodcastBySlug(slug){
        return new Promise((resolve, reject) => {
            jQuery.getJSON( `${API.base}/podcast/${slug}?_=` + API.timestamp(), resolve).fail(reject);
        });

        // return new Promise((resolve, reject) => {
        //     jQuery.getJSON( `${slug}.json`, resolve).fail(reject);
        // });
    },

    getLibrary(course = null, org_only = false){
        return new Promise((resolve, reject) => {
            // jQuery.getJSON( `${API.base}/course/${course}/library?_=` + API.timestamp(), resolve).fail(reject);
            org_only = org_only ? 'yes' : '';
            jQuery.getJSON( `${API.base}/library/`, {
                token: cookies.get('user'),
                course,
                org_only,
                _: API.timestamp()
            }, resolve).fail(reject);
        });
    },

    getLibraryItemById(id){
        return new Promise((resolve, reject) => {
            jQuery.getJSON( `${API.base}/library/${id}`, {
                token: cookies.get('user'),
                _: API.timestamp()
            }, resolve).fail(reject);
        });
    },

    removeLibrary(id){
        return new Promise((resolve, reject) => {
            jQuery.ajax({
                url: `${API.base}/library/${id}/?token=` + cookies.get('user') + '&_=' + API.timestamp(),
                type: 'DELETE',
            })
                .then(resolve)
                .fail(reject);
        });
    },

    updateLibraryItemRaw(id, data){
        return jQuery.post( `${API.base}/library/${id}/?token=`+ cookies.get('user') + `&_=` + API.timestamp(), data, 'json');
    },

    createLibraryItem(data){
        return new Promise((resolve, reject) => {
            jQuery.post( `${API.base}/library/?token=`+ cookies.get('user') + `&_=` + API.timestamp(), data, resolve, 'json').fail(reject);
        });
    },

    // getMyLibrary(){
    //     return new Promise((resolve, reject) => {
    //         jQuery.getJSON( `${API.base}/course/justice/library?_=` + API.timestamp(), resolve).fail(reject);
    //         // jQuery.getJSON( `${API.base}/my-library/?_=` + API.timestamp(), resolve).fail(reject);
    //     });
    // },

    getClasses(){
        return new Promise((resolve, reject) => {
            if( cookies.get('user') == null ) reject();
            else jQuery.getJSON( `${API.base}/classes?_=` + API.timestamp() + `&token=` + cookies.get('user'), resolve).fail(reject);
        });
    },

    createClass(class_info){
        return new Promise(((resolve, reject) => {
            jQuery.post(`${API.base}/class/create?token=` + cookies.get('user'), JSON.stringify({
                course: class_info.course,
                grade_level: class_info.grade_level,
                id: '',
                name: class_info.name,
                num_students: '',
            }), async (data) => {
                try{
                    // //data = jQuery.parseJSON(data);
                    let class_info_updated = await API.getClass(data.id);

                    // Call this API only when there are students.
                    if( class_info.students.length ){
                        class_info_updated.students = class_info.students;
                        await API.createStudents(class_info_updated, class_info.generate_access_code);
                    }

                    class_info_updated.library_items = class_info.library_items;
                    class_info_updated.podcast = class_info.podcast;
                    class_info_updated = await API.updateClass(class_info_updated);

                    return resolve( class_info_updated );
                }catch (e) {
                    return reject(e);
                }
            }).fail(reject);
        }));
    },


    createStudents(class_info, generate_access_code = true){
        generate_access_code = generate_access_code ? 'yes' : '';
        return new Promise(((resolve, reject) => {
            jQuery
                .post(`${API.base}/class/${class_info.id}/students/add?generate_access_code=${generate_access_code}&token=` + cookies.get('user'), JSON.stringify(class_info), resolve)
                .fail(reject);
        }));
    },

    getStudentById(id){
        return new Promise(((resolve, reject) => {
            jQuery
                .get(`${API.base}/student/${id}`, resolve)
                .fail(reject);
        }));
    },

    removeStudent(class_id, student_id){
        return new Promise((resolve, reject) => {
            jQuery.ajax({
                url: `${API.base}/class/${class_id}/student/${student_id}/delete?token=` + cookies.get('user'),
                type: 'DELETE',
            })
                .then(resolve)
                .fail(reject);
        });
    },

    removeAccessCode(student_id){
        return new Promise((resolve, reject) => {
            jQuery.ajax({
                url: `${API.base}/student/${student_id}/access-code?token=` + cookies.get('user'),
                type: 'DELETE',
            })
                .then(resolve)
                .fail(reject);
        });
    },

    generateAccessCode(student_id){
        return new Promise((resolve, reject) => {
            jQuery.ajax({
                url: `${API.base}/student/${student_id}/access-code?token=` + cookies.get('user'),
                type: 'POST',
                contentType: 'json',
            })
                .then(resolve)
                .fail(reject);
        });
    },

    getClass(class_id){
        return new Promise((resolve, reject) => {
            jQuery.getJSON(`${API.base}/class/${class_id}?_=` + API.timestamp() + `&token=` + cookies.get('user'), resolve).fail(reject);
        });
    },

    removeClass(class_id){
        return new Promise((resolve, reject) => {
            jQuery.ajax({
                url: `${API.base}/class/${class_id}/delete?token=` + cookies.get('user'),
                type: 'DELETE',
            })
                .then(resolve)
                .fail(reject);
        });
    },

    updateClass(class_info){
        return new Promise((resolve, reject) => {
            this.updateClassRaw(class_info).then(resolve).fail(reject);
        });
    },

    updateClassRaw(class_info){
        if( !class_info.id ) throw 'class id not found.';
        return jQuery.post(`${API.base}/class/${class_info.id}/update?token=` + cookies.get('user'), JSON.stringify(class_info));
    },

    getStudents(class_id) {
        return new Promise((resolve, reject) => {
            jQuery.getJSON(`${API.base}/class/${class_id}/students/simple?_=` + API.timestamp() + `&token=` + cookies.get('user'), resolve).fail(reject);
        });
    },

    getStudentsData(class_id) {
        return new Promise((resolve, reject) => {
            jQuery.getJSON(`${API.base}/class/${class_id}/students?_=` + API.timestamp() + `&token=` + cookies.get('user'),
                    students => resolve(students.map(student => {
                        student.student_data = JSON.parse(student.student_data);
                        return student;
                    })))
                .fail(reject);
        });
    },

    getResponses(class_id, stage) {
        return new Promise((resolve, reject) => {
            jQuery.getJSON(`${API.base}/class/${class_id}/students/data/bystage/${stage}?_=` + API.timestamp() + `&token=` + cookies.get('user'), resolve).fail(reject);
        });
    },

    saveClassDebounced(class_info, debounce_timeout = 500){
        return new Promise((resolve, reject) => {
            if( !class_info ) reject();

            if (API.__save_timeout) clearTimeout(API.__save_timeout);
            if (API.__save_xhr) API.__save_xhr.abort();

            API.__save_timeout = setTimeout(() => {
                API.__save_xhr = API.updateClassRaw(class_info);
                API.__save_xhr.then(resolve).fail(reject);
            }, debounce_timeout);

        });
    },

    saveLibraryItemDebounced(id, data, debounce_timeout = 500){
        return new Promise((resolve, reject) => {
            if( !id || !data ) reject();

            if (API.__save_timeout) clearTimeout(API.__save_timeout);
            if (API.__save_xhr) API.__save_xhr.abort();

            API.__save_timeout = setTimeout(() => {
                API.__save_xhr = API.updateLibraryItemRaw(id, data);
                API.__save_xhr.then(resolve).fail(reject);
            }, debounce_timeout);

        });
    },

    __initFileStackClient(){
        if( !API.__filestack.client ){
            API.__filestack.client = fs.init( API.__filestack.apikey );
        }
        return API.__filestack.client;
    },

    uploadedFileInfo(url_or_handle){
        url_or_handle = url_or_handle.split('/');
        return API.__initFileStackClient().metadata(url_or_handle[ url_or_handle.length - 1 ]);
    },

    uploadFilePicker(options = {}){
        return new Promise((resolve) => {
            options.onUploadDone = resolve;
            API.__initFileStackClient().picker(options).open();
        })
    },

    uploadFile(fileData, onProgress = (() => {})){
        return API.__initFileStackClient().upload(fileData, {onProgress}, {filename: fileData.name});
    },

 
    allUsers(){
        return new Promise((resolve, reject) => {
            jQuery.getJSON( `${API.base}/user/`, {
                token: cookies.get('user'),
                _: API.timestamp()
            }, resolve).fail(reject);
        });
    },

    getUserById(id){
        return new Promise((resolve, reject) => {
            jQuery.getJSON( `${API.base}/user/${id}`, {
                token: cookies.get('user'),
                _: API.timestamp()
            }, resolve).fail(reject);
        });
    },

    saveUserDataDebounced(id, data, debounce_timeout = 500){
        return new Promise((resolve, reject) => {
            if( !id || !data ) reject();

            if (API.__save_timeout) clearTimeout(API.__save_timeout);
            if (API.__save_xhr) API.__save_xhr.abort();

            API.__save_timeout = setTimeout(() => {
                API.__save_xhr = API.updateUserDataRaw(id, data);

                API.__save_xhr.then(resolve).fail(reject);
            }, debounce_timeout);
        });
    },

    updateUserDataRaw(id, _data){

        return jQuery.ajax({
            url: `${API.base}/user/${id}/?token=` + cookies.get('user') + `&_=` + API.timestamp(),
            type: 'PUT',
            contentType: 'json',
            data : JSON.stringify(_data )
        });

    },

    inviteUser( mail, invitation_type, name ) {
        const type = ( invitation_type ? 1 : 0 );
        return new Promise(((resolve, reject) => {
            jQuery.post(`${API.base}/user/invite?token=`+ cookies.get('user'), JSON.stringify({email: mail, admin: type, name: name }), resolve, 'json').fail(reject);
        }));
    },

    deleteUser( user_id ) {
        return new Promise(((resolve, reject) => {
            jQuery.ajax({
                url: `${API.base}/user/${user_id}?token=` + cookies.get('user'),
                type: 'DELETE',
            })
            .then(resolve)
            .fail(reject);
        }));
    },

    submitGrant(data){
        return new Promise(((resolve, reject) => {
            jQuery.post(`${API.base}/grant?token=`+ cookies.get('user'), JSON.stringify(data), resolve, 'json').fail(reject);
        }));
    },

    getGrantSubmission(id){
        return new Promise(((resolve, reject) => {
            jQuery.getJSON(`${API.base}/grant/${id}?token=`+ cookies.get('user'), resolve).fail(reject);
        }));
    },

    getGrantCertificateUrl(id, name = ''){
      if( name ) return `${API.base}/grant/certificate/${id}?name=` + encodeURIComponent(name);
      return `${API.base}/grant/certificate/${id}`;
    },

    getRiseCertificateUrl(name = ''){
      if( name ) return `${API.base}/rise/certificate?name=` + encodeURIComponent(name);
      return `${API.base}/rise/certificate`;
    }

}

export default API;