<template>
  <div class="podcast-single" ref="podcast"
       :class="{
          'is-embed': embed.is_embed,
          'is-audio': is_audio,
          'is-video': is_video,
          'is-fullscreen': is_fullscreen,
          'is-playing': stats.is_playing,
          'is-paused': !stats.is_playing,
          'is-buffering': stats.is_buffering,
          'player-is-visible': player_is_visible,
        }">

    <div v-if="!is_busy" class="position-relative pb-5">

<!--      <div class="backdrop" :style="'background-image: url(' + p ? p.picture : '' + ');'"></div>-->

      <div class="inner">

        <div class="container">
          <div class="desk">

            <a v-if="is_fullscreen || !embed.is_embed" class="back" href="https://www.designforchange.us/warmups" target="_parent"><icon-back/></a>

            <div class="preview-pane" :class="{'has-progressed': stats.now > 0}">
              <div class="click-play" @click.prevent.stop="togglePlay()"></div>
              <div class="preview" ref="preview" :style="'background-image: url(' + current_preview + ');'"></div>
              <div class="play">
                <i v-if="stats.is_buffering" class="fas fa-spinner fa-spin fa-2x"></i>
                <i v-else class="fas" :class="stats.is_playing ? 'fa-pause' : 'fa-play'"></i>
              </div>
              <div class="actions" @click.prevent.stop>
                <a href="#" @click.prevent="fullscreen()"><i class="fas fa-expand"></i></a>
              </div>

              <div class="buttons">
                <button class="btn d-flex align-items-center text-uppercase text-left transcript"
                        @click="transcript.visible = !transcript.visible"
                        :class="transcript.visible ? 'btn-outline-dark' : 'btn-dark'">
                  <icon-comment width="48" class="mr-3 flex-grow-0 flex-shrink-0" />
                  {{ transcript.visible ? 'Hide' : 'Show' }} Transcript
                </button>
                <a v-if="!is_video"
                   class="btn btn-dark-green text-white d-flex align-items-center text-uppercase text-left visuals"
                   target="_blank"
                   :href="p.visuals">
                  <icon-cloud-download width="48" class="mr-3 flex-grow-0 flex-shrink-0" />
                  Download Visuals
                </a>
              </div>
            </div>

            <div class="details-pane">

              <div v-if="transcript.visible" class="podcast-transcript text-left">
                <div class="ts-inner">
                  <h4>{{ current_transcript.title }}</h4>
                  <div v-for="(segment, idx) in current_transcript.segments" :key="idx" class="segment">
                    <h5>{{ segment.speaker }}</h5>
                    <p>{{ segment.text }}</p>
                    <hr v-if="idx < current_transcript.segments.length - 1">
                  </div>
                </div>
              </div>

              <div v-else class="podcast-info">
                <div class="goal">
                  <img :src="p.global_goal_image" alt="">
                </div>

                <h1>{{ p.name }}</h1>
                <p>{{ p.description }}</p>

                <div v-if="p.sponsor_name" class="sponsor">
                  <div class="section"><i>This Empathy Warmup was produced with the generous support of <b><a :href="p.sponsor_url" target="_new">{{p.sponsor_name}}.</a></b></i></div>
                  <div class="section logo"><a :href="p.sponsor_url" target="_new"><img :src="p.sponsor_logo"/></a></div>
                </div>

                <hr>

                <div class="next-steps">
                  <h6>The Next Steps</h6>
                  <p>After previewing the podcast, download our Empathy Warmup toolkit to use this podcast with your students today!</p>
                  <button class="btn btn-dark-green mb-2" @click="showModal()">
                    <icon-cloud-download width="24" class="mr-2" /> Download the Toolkit
                  </button>
                  <button class="btn btn-primary" @click="startProject()">
                      Start project with this episode
                  </button>
                </div>

                <hr>

              </div>

            </div>
          </div>
        </div>

        <div class="player">
          <div class="container">
            <div class="controls">
              <a href="#" class="prev" @click.prevent="prev()"><icon-backward/></a>
              <a href="#" class="play" @click.prevent="togglePlay()">
                <span v-if="stats.is_buffering"><i class="fas fa-spinner fa-spin fa-2x"></i></span>
                <icon-play v-else-if="!stats.is_playing"/>
                <icon-pause v-else/>
              </a>
              <a href="#" class="next" @click.prevent="next()"><icon-forward/></a>
            </div>
            <div class="seeker">
              <div class="seek-bar" ref="seekbar" @click.prevent="seeker($event)">
                <div class="seek-bar-progress" :style="'width: ' + (stats.now / stats.duration * 100) + '%'">
                  <div class="seek-bar-control"></div>
                </div>
                <div class="seek-bar-flags">
                  <a v-for="item in ts_data" :key="item.timestamp"
                     href="#"
                     :style="'left:' + (item.timestamp / stats.duration * 100) + '%'"
                     @click.prevent.stop="seek( item.timestamp )">
                  </a>
                </div>
              </div>
              <div class="timers">
                <div class="now">{{ formatTime( stats.now ) }}</div>
                <div class="duration">{{ formatTime( stats.duration ) }}</div>
              </div>
            </div>
            <div class="info">
              <div class="title">{{ current_transcript.title }}</div>
              <!-- div class="description">{{ current_transcript.segments.map(s => s.speaker).join(', ') }}</div -->
            </div>
          </div>
        </div>

      </div>

    </div>

    <div v-else>
      <div class="text-center my-5 py-5">
        <i class="fas fa-spinner fa-spin fa-2x"></i>
      </div>
    </div>


    <div v-if="modal_toolkit_visible" class="modal-toolkit">
      <div class="modal-toolkit-content">
        <div class="modal-toolkit-header">
          Download the toolkit
          <a href="https://f.hubspotusercontent20.net/hubfs/6395720/Design%20for%20Change%20USA%20Toolkit/Design%20for%20Change%20USA%20-%20Empathy%20Warmup%20Toolkit%20-%202021-2022.pdf" class="close"><i class="fas fa-times"></i></a>
          <div id="hubspotForm" v-once style="width: 100%; height: 1000px; background-color: #fff; padding: 30px; margin-top: 15px"></div>
        </div>
        <div class="modal-toolkit-body">
          <div class="details">
            
          </div>
        </div>
      </div>
    </div>

<!--    <div class="container-fluid my-5">-->
<!--      <code><pre class="small text-white bg-dark p-3">{{ podcast }}</pre></code>-->
<!--    </div>-->

  </div>
</template>

<script>
import API from "@/api";
import jQuery from "jquery";
import mixins from "@/mixins";
import tracker from "@/mixins/tracker";
import Vimeo from "@vimeo/player/dist/player";
import IconBack from "@/icons/IconBack";
import IconPlay from "@/icons/IconPlay";
import IconPause from "@/icons/IconPause";
import IconBackward from "@/icons/IconBackward";
import IconForward from "@/icons/IconForward";
import IconCloudDownload from "@/icons/IconCloudDownload";
import IconComment from "@/icons/IconComment";

export default {
  name: "PodcastSingle",
  components: {IconBack, IconBackward, IconForward, IconPlay, IconPause, IconCloudDownload, IconComment},
  mixins: [mixins, tracker],
  props: ['slug'],

  data(){
    return {
      is_embed: false,
      is_busy: true,
      podcast: null,
      is_fullscreen: false,
      player_is_visible: 50,
      animation_state_class: 'off',
      transcript: {
        visible: false,
        index: 0,
      },
      modal_toolkit_visible: false,

      // Resources
      audio: null,
      video: null,

      stats: {
        duration: 0,
        now: 0,
        is_playing: false,
        is_buffering: false,
      }
    };
  },

  computed: {
    p(){
      return this.podcast;
    },

    player(){
      return this.$refs.player;
    },

    current_transcript(){
      return this.ts_data[ this.transcript.index ];
    },

    current_preview(){
      if( this.stats.now === 0) return this.p.picture_large;
      return typeof this.current_transcript.visuals !== 'undefined' ? this.current_transcript.visuals[0] : this.p.picture_large;
    },

    ts_data(){
      return this.p.transcript_data;
    },

    is_audio(){
      return this.p ? this.p.type === 'audio' : false;
    },

    is_video(){
      return this.p ? this.p.type === 'video' : false;
    },

    stats_now(){
      return this.stats.now;
    },

    stats_duration(){
      return this.stats.duration;
    }

  },

  watch: {
    stats_now(now){
      for( let i = this.ts_data.length - 1; i >= 0; i-- ){
        const item = this.ts_data[ i ];
        if( now > item.timestamp ){
          this.transcript.index = i;
          break;
        }
      }
    },

    transcript: {
      deep: true,
      handler(){
        this.$nextTick(() => this.autoResizeTranscript());
      }
    }
  },

  methods: {

    async ready(){

      if( this.is_audio ){
        this.audio = new Audio( this.p.url );

        this.audio.onplay = () => this.stats.is_playing = true;
        this.audio.onpause = () => this.stats.is_playing = false;
        this.audio.ontimeupdate = () => this.stats.now = this.audio.currentTime;
        this.audio.onloadedmetadata = () => this.stats.duration = this.audio.duration;
        this.audio.onended = () => this.stats.now = 0;
        this.audio.onwaiting = () => this.stats.is_buffering = true;
        this.audio.oncanplay = () => this.stats.is_buffering = false;

      }else{

        // const rect = this.$refs.preview.getBoundingClientRect();

        this.video = new Vimeo( this.$refs.preview, {
          id: this.p.url.split('/').pop(),
          // width: rect.width,
          // height: rect.height,
          loop: false,
          autoplay: false,
          controls: false,
          color: 'ffADEF',
          responsive: true,
        });


        this.stats.duration = await this.video.getDuration();

        this.video.on('play', () => this.stats.is_playing = true);
        this.video.on('pause', () => this.stats.is_playing = false);
        this.video.on('timeupdate', update => this.stats.now = update.seconds);
        this.video.on('ended', () => this.stats.now = 0);
        this.video.on('bufferstart', () => this.stats.is_buffering = true);
        this.video.on('bufferend', () => this.stats.is_buffering = false);

      }

    },

    async isPlaying(){
      if( this.is_audio ){
        return !this.audio.paused;
      }else{
        return ! await this.video.getPaused();
      }
    },

    formattedTimeToSecs( time ){
      time = time.split(':').reverse();
      let secs = 0;
      if( time.length > 2 ){
        secs += time[ 2 ] * 3600;
      }
      if( time.length > 1 ){
        secs += time[ 1 ] * 60;
      }
      if( time.length > 0 ){
        secs += time[ 0 ] * 1;
      }
      return secs;
    },

    formatTime(secs){

      secs = Math.round( secs );

      let hrs = Math.floor( secs / 3600 );
      let mins = Math.floor( secs / 60 ) - ( hrs * 60 );

      secs = secs - ( hrs * 3600 ) - (mins * 60);

      let formatted = '';

      if( hrs > 0 ){
        formatted += hrs + ':';
      }

      if( mins >= 10 ){
        formatted += mins + ':';
      }else{
        formatted += '0' + mins + ':';
      }

      if( secs >= 10 ){
        formatted += secs;
      }else{
        formatted += '0' + secs;
      }

      return formatted;

    },

    seeker(ev){
      const rect = this.$refs.seekbar.getBoundingClientRect();
      const t = this.stats.duration / rect.width * (ev.clientX - rect.left);
      this.seek( t );
    },

    async seek(secs){
      if( this.is_audio ){
        this.audio.currentTime = secs;
      } else {
        await this.video.setCurrentTime(secs);
      }

      if( ! await this.isPlaying() ){
        this.play();
      }

    },

    async togglePlay(){
      if( !this.stats.is_buffering ){
        if( await this.isPlaying() ){
          this.pause();
        }else{
          this.play();
        }
      }
    },

    play(){
      if( this.is_audio ){
        this.audio.play();
      }else{
        this.video.play();
      }
    },

    pause(){
      if( this.is_audio ){
        this.audio.pause();
      }else{
        this.video.pause();
      }
    },

    prev(){
      if( this.transcript.index < 0 ) return;
      this.seek( this.ts_data[ Math.max( 0, this.transcript.index - 1) ].timestamp );
    },

    next(){
      if( this.transcript.index >= this.ts_data.length - 1 ) return;
      this.seek( this.ts_data[ this.transcript.index + 1 ].timestamp );
    },

    fullscreen(){

      const el = this.$refs.podcast;

      if( !this.is_fullscreen ){


          if( this.embed.is_embed ) {
              this.onFullScreen();

          }else{
              if (el.requestFullscreen) {
                  el.requestFullscreen();
              } else if (el.webkitRequestFullscreen) { /* Safari */
                  el.webkitRequestFullscreen();
              } else if (el.msRequestFullscreen) { /* IE11 */
                  el.msRequestFullscreen();
              }
          }

      }else{

          if( this.embed.is_embed ){
              this.onFullScreenExit();
          }else{
              document.exitFullscreen();
          }
      }

    },

    onFullScreen(){
      this.is_fullscreen = true;
    },

    onFullScreenExit(){
      this.is_fullscreen = false;
    },


    playerVisibleTimer(){

      if( this.player_is_visible > 0 ){
        this.player_is_visible -= 1;
      }

      setTimeout( this.playerVisibleTimer, 100);
    },


    downloadToolkit(url = null){

      if( this.current_user ){
        window.open(url, '_blank').focus();
        return;
      }

      this.$swal({
        title: 'Signup... It\'s Free!\n',
        html: `
            <img src="https://designforchange.us/dfcusa-pm/app/webroot/img/dfcprocess%202.svg" class="img-fluid my-3" alt="">
            <p>Design for Change equips young people to transform empathy into social action using 4 steps: Feel, Imagine, Do, Share.</p>
            <p>Educators use DFC to facilitate a design process that fosters empathy and student agency.</p>`,
        icon: '',
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Sign Up or Login',
        denyButtonColor: '#88c51f',
        denyButtonText: 'How does DFC work?',
      }).then(({isConfirmed, isDenied}) => {
        console.log({isConfirmed, isDenied, file: url});

        if( isConfirmed ){

          if( url ) this.enableAutoDownload(url);
          this.$router.push({name: 'class.dashboard'});

        }else if( isDenied ){
          window.location = '//designforchange.us/how-it-works';
        }

      }).catch(() => {

      });
    },

    autoResizeTranscript(){
      const $preview = jQuery('.preview-pane');
      const $transcript = jQuery('.podcast-transcript');
      const $actions = jQuery('.details-pane .actions');

      console.log({p: $preview.height(), t: $transcript.height(), a: $actions.height()});

      $transcript.height( $preview.height() - $actions.height() - 30 );

    },

    async showModal() {
      window.location.href = "https://f.hubspotusercontent20.net/hubfs/6395720/Design%20for%20Change%20USA%20Toolkit/Design%20for%20Change%20USA%20-%20Empathy%20Warmup%20Toolkit%20-%202021-2022.pdf"
      // this.modal_toolkit_visible = true;
      // if (window.hbspt) {
      //   window.hbspt.forms.create({
      //     region: "na1",
      //     portalId: "6395720",
      //     formId: "c3931dce-736f-4882-bc53-05f0318c28ec",
      //     target: "#hubspotForm"
      //   })
      // }
    },

    startProject(){
      window.top.location = 'https://pro.designforchange.us/app/project/create?path=61&podcast=' + this.podcast.short_name;
    }

  },

  async beforeMount() {

    this.is_busy = true;

    try{

      let podcast = await API.getPodcastBySlug( this.slug );

      podcast.transcript_data.map(item => item.timestamp = this.formattedTimeToSecs( item.timestamp ));

      this.setPageTitle('Podcast - ' + podcast.name);
      await this.tracker.visit(this.$route);

      this.podcast = podcast;

    }catch (e) { console.log( e ); }

    this.is_busy = false;


    jQuery(window).on('resize', this.autoResizeTranscript);


    // Call Ready when setup is complete.
    this.$nextTick( this.ready );


  },


  mounted() {

    document.onfullscreenchange = () => {
      if (!document.fullscreenElement) this.onFullScreenExit();
      else this.onFullScreen();
    };

    document.onwebkitfullscreenchange = () => {
      if (!document.webkitFullscreenElement) this.onFullScreenExit();
      else this.onFullScreen();
    };

    document.onmozfullscreenchange = () => {
      if (!document.mozFullScreenElement) this.onFullScreenExit();
      else this.onFullScreen();
    };

    document.onmsfullscreenchange = () => {
      if (!document.msFullscreenElement) this.onFullScreenExit();
      else this.onFullScreen();
    };

    document.onmousemove = () => {
      this.player_is_visible = 50;
    }


    if( this.embed.is_fullscreen ){
        this.onFullScreen();
    }

    this.playerVisibleTimer();

    const script = document.createElement("script");
    script.src="https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      
    })
  }

}
</script>
<style lang="scss">
.is-video .preview {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
<style lang="scss" scoped>
.container{
  max-width: 1420px !important;
}

.podcast-single{

}

.is-fullscreen{

  .player{
    transition: transform 300ms;
    transform: translateY(100%);
  }

  .back{
    transition: margin 300ms;
    margin-left: -100px;
  }

  &.player-is-visible{
    .player{
      transform: translateY(0);
    }

    .back{
      margin-left: 0;
    }
  }

  &.is-playing {
    .preview-pane {
      .play {
        opacity: 0 !important;
      }
    }

  }


  .preview{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 80px;
    left: 0;
    padding-bottom: 0 !important;
    height: auto;

    > * {
      transform: translateY(-50%);
      position: fixed !important;
      top: 50%;
      left: 0;
      width: 100%;
    }

    //iframe{
    //  position: fixed !important;
    //  top: 0 !important;
    //  right: 0 !important;
    //  left: 0 !important;
    //}
  }

  .player{
    opacity: 0.75;
  }

  .back{
    position: fixed !important;
    top: 40px !important;
    left: 10px !important;
    transform: translate(0) !important;
  }

}

.inner{
  position: relative;
  z-index: 1;
}

.desk{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media all and (min-width: 767px) {
    flex-direction: row;
    //align-items: stretch;
  }

  .back{
    position: absolute;
    border-radius: 50%;
    padding: 0.4rem;
    left: 0;
    top: 40px;
    z-index: 10;
    box-shadow: 0 2px 6px #00000029;
    color: white;
    background: #323232;
    border: white solid 2px;
    transform: translate(10%, 10%);

    @media all and (min-width: 1530px) {
      transform: translate(-50%, 35%);
    }
  }

}

.preview-pane{
  border-radius: 19px;
  overflow: hidden;
  box-shadow: 0 0 6px #00000029;
  position: relative;
  width: 100%;
  cursor: pointer;
  margin-top:  80px;

  @media all and (min-width: 767px) {
    width: 60%;
  }

  @media all and (min-width: 1200px) {
    width: 65%;
  }

  &.has-progressed{
    .preview{
      background-color: #ffffff;
      background-size: contain;
    }
  }

  .play{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 50%;
    font-size: 1.8rem;
    border: #eaeaea solid 2px;
    box-shadow: rgba(#333, 0.3) 0 0 15px;

    .fa-spin{
      font-size: 1.6rem;
    }

    .fa-play{
      margin-left: 0.5rem;
    }
  }

  .buttons {
    margin:  25px;

    .transcript {
      width:  200px;
      float:  left;
      margin-bottom:  20px;
    }

    .visuals {
      width: 200px;
      float: left;
      margin-left: 20px;
      margin-bottom:  20px;
    }
  }

  .actions{
    // display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.5rem 0;
    background: rgba(white, 0.8);
    border-radius: 19px 0 19px 0;
    font-size: 0.7rem;
    z-index: 5;

    > * {
      margin-right: 0.6rem;
      padding: 0.6rem;
      color: black;

      &:first-child{
        margin-left: 0.6rem;
      }
    }
  }

}


.is-playing{
  .preview-pane{
    .play{
      opacity: 0;
    }

    &:hover{
      opacity: 1;
    }
  }
}

.preview{
  background: transparent no-repeat center / cover;


}

.is-audio{

  .preview{
    background: transparent no-repeat center / cover;
    height: 340px;

    @media all and (min-width: 767px) {
      height: 70vh;
    }
  }


  &.is-fullscreen{
    .preview{
      height: 100% !important;
    }
  }

}

.click-play{
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 95px;
  left: 0;
  background: rgba(white, 0);
  z-index: 2;
}


.is-video{

  //.preview-pane{
  //  .actions{
  //    display: block;
  //  }
  //}

  .preview {
    background: #17242E !important;
  }
}


.details-pane{
  text-align: center;

  @media all and (min-width: 767px) {
    width: 40%;
    // height: 70vh;
    display: flex;
    flex-direction: column;
  }

  @media all and (min-width: 1200px) {
    width: 35%;
  }


  .actions{
    display: flex;
    justify-content: center;
    //margin-left: -0.5rem;
    //margin-right: -0.5rem;
    //margin-top: 1rem;

    @media all and (min-width: 767px) {
      //height: 70px;
      //flex: 0 0 70px;
      //margin-top: 0;
      //margin-bottom: 10px;
      //padding: 0 0 0 30px;
      padding: 0 0 0 30px;
    }

    .btn{
      justify-content: center;
      padding: 0.8rem 1rem;
      border-radius: 0.5rem;
      margin: 0 0.5rem;
      // flex-grow: 1;
    }
  }

  h1{
    font-size: 32px;
  }

  hr{
    max-width: 80px;
    margin: 2rem auto;
  }

  .goal{
    img{
      display: block;
      width: 100%;
      max-width: 120px;
      height: auto;
      box-shadow: 0 3px 6px #00000029;
      border: 2px solid #fff;
      border-radius: 13px;
      margin: 1rem auto;
    }
  }


  .podcast-info{
    //display: none;
    @media all and (min-width: 767px) {
      padding: 15px 0 0px 30px;
    }
  }

  .podcast-transcript{
    margin-top: 2rem;

    @media all and (min-width: 767px) {
      margin-top: 0;
      padding: 15px 0 15px 15px;
      margin-left: 15px;
      display: flex;
      ///height: 85%;
    }

    .ts-inner{
      padding: 0.8rem 1rem;
      background: rgba(white, 0.75);
      overflow: auto;
      height: 100%;
      border-radius: 19px;
      box-shadow: 0 3px 6px #00000015;
      border: rgba(black, 0.1) solid 1px;
    }

    h4{
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 24px;
    }

    h5{
      font-size: 20px;
      margin-bottom: 20px;
    }

    p{
      font-size: 16px;
    }

    hr{
      margin-left: 0;
    }

  }

}


.backdrop{
  position: absolute;
  top: 80px;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: 1px solid #DFDFDF;
  z-index: 0;
  background: transparent no-repeat center / cover;

  &:before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(15px);
    background: rgba(white, 0.85);
  }
}


.player{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: #303E44;
  z-index: 9;
  display: flex;
  align-items: stretch;
  text-shadow: 0 0 5px #007BFF, 0 0 30px #007BFF;
  color: white;

  .container{
    display: flex;
    align-items: stretch;
  }

  .controls{
    height: 100%;
    display: flex;
    align-items: stretch;
    border-left: rgba(#999, 0.1) solid 1px;
    border-right: rgba(#999, 0.1) solid 1px;
    padding: 0 0.2rem;

    a{
      flex-grow: 1;
      background: transparent;
      border: none;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }

    .play{
      > * {
        width: 80px;
        height: auto;
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }

    .prev, .next{
      > * {
        width: 60px;
        height: auto;
      }
    }

  }

  .seeker{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0 0 50%;
    width: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 1rem;
  }

  .seek-bar{
    cursor: pointer;
    margin-top: 20px;
    height: 4px;
    width: 100%;
    background: #ababab;
    border-radius: 4px;
    position: relative;


    &-progress{
      position: relative;
      width: 0;
      height: 4px;
      padding: 0 3px;
      background: #2680eb;
      border-radius: 4px;
    }

    &-control{
      cursor: pointer;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 12px;
      height: 12px;
      background: white;
      z-index: 10;
      border-radius: 50%;
      box-shadow: 0px 0px 15px #007BFF;
    }

    &-flags{
      a{
        transition: left .3s;
        position: absolute;
        top: -16px;
        left: 0;
        margin-left: -8px;
        display: block;
        border: transparent solid 8px;
        border-bottom-color: #2680eb;

        &:first-child{
          margin-left: 0;
        }
      }
    }
  }

  .timers{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
  }

  .info{
    padding: 0.6rem 1rem;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    border-left: rgba(#999, 0.1) solid 1px;
    border-right: rgba(#999, 0.1) solid 1px;
    flex-direction: column;
    text-align: left;
    overflow: hidden;

    .title{
      font-weight: bold;
    }

    .description{
      font-size: 0.8rem;
    }

    .title, .description{
      overflow: hidden;
      text-wrap: none;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

}

.sponsor {
  border:  1px solid #f1f1f1;
  background-color:  #fff;
  border-radius:  10px;
  width:  100%;
  padding:  10px;
  height:  125px;
  margin-top:  30px;
  margin-bottom:  20px;

  .section {
    float:  left;
    width:  60%;
    text-align:  left;
    padding:  10px 0px 10px 10px;

    &.logo {
      padding:  0px;
      width:  30%;
      img {
        float:  right;
      }
    }
  }
}

.modal-toolkit{
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(white, 0.9);

  &-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1200px;
    box-shadow: 0 6px 24px #00000029;
    border-radius: 24px;
  }

  &-header{
    padding: 1rem;
    background: #ed1651;
    color: white;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    border-radius: 24px 24px 0 0;
    position: relative;

    .close{
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      width: 46px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      opacity: 1;
      border-radius: 50%;
      box-shadow: 0 3px 6px #00000029;
      border: #00000029 solid 1px;
      color: #FB2C2C;

      &:hover, &:focus{
        opacity: 1;
      }
    }
  }

  &-body{
    padding: 1.6rem 1rem;
    background: white;
    border-radius: 0 0 24px 24px;
    max-height: 80vh;
    overflow: auto;
  }

  .details{
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > * {
      width: 100%;
      flex: 0 0 100%;

      @media all and (min-width: 768px) {
        width: 50%;
        flex: 0 0 50%;
      }

      @media all and (min-width: 1024px) {
        width: 25%;
        flex: 0 0 25%;
      }
    }

    img{
      display: block;
      max-width: 240px;
      height: auto;
      margin: 2rem auto 0.6rem;
      border-radius: 24px;

      @media all and (min-width: 1024px) {
        max-width: 100%;
      }
    }

    .text{
      padding: 0 1.6rem;
      text-align: center;

      &.in-person{
        order: 1;

        @media all and (min-width: 1024px) {
          order: 0;
        }
      }

      &.virtual{
        order: 3;
      }
    }

    .image{
      &.in-person{
        order: 0;

        @media all and (min-width: 1024px) {
          img {
            border-radius: 24px 0 0 24px;
          }
        }
      }
      &.virtual{
        order: 2;

        @media all and (min-width: 1024px) {
          img{
            border-radius: 0 24px 24px 0;
          }
        }
      }
    }
  }
}

</style>