import Vue from 'vue';

import VueRouter from 'vue-router'
Vue.use(VueRouter);


import Guest from "../middlewares/Guest";
import Auth from "../middlewares/Auth";
import AutoRedirect from "../middlewares/AutoRedirect";


//
import Login from "../views/auth/Login";
import Register from "../views/auth/Register";
import RecoverPassword from "../views/auth/RecoverPassword";
import ResetPassword from "../views/auth/ResetPassword";
import RegisterWizard from "../views/auth/RegisterWizard";

import Dashboard from "../views/Dashboard";
import ClassSettings from "../views/ClassSettings";
import ClassStage from "../views/ClassStage";

import SelectTheme from "../views/new-class/SelectTheme";
import ClassDetails from "../views/new-class/ClassDetails";
import Students from "../views/new-class/Students";
import FeaturedPodcast from "../views/new-class/FeaturedPodcast";
import Library from "../views/new-class/Library";
import LibraryArchive from "@/views/my-library/LibraryArchive";
import LibraryEdit from "@/views/my-library/LibraryEdit";

import Error404 from "../views/errors/Error404";

import PodcastSingle from "@/views/podcast/PodcastSingle";
//import PodcastSingleOld from "@/views/podcast/PodcastSingleOld";
import PodcastArchive from "@/views/podcast/PodcastArchive";

import StudentProfile from "@/views/profiles/StudentProfile";

import UsersList from "../views/users/UsersList";
import UserInvite from "../views/users/UserInvite";
import UserEdit from  "../views/users/UserEdit";

import GrantWizard from "@/views/grant/GrantWizard";
import GrantSuccess from "@/views/grant/GrantSuccess";
import RiseCertificate from "@/views/rise/RiseCertificate";
import GrantShow from "@/views/grant/GrantShow";


const routes = [

  // Auth ===================================
  { name: 'auth.login', path: '/auth', component: Login, meta: { middleware: Guest } },
  { name: 'auth.register', path: '/auth/register', component: Register, meta: { middleware: Guest } },
  { name: 'auth.register.wizard', path: '/auth/register/:type', component: RegisterWizard, props: route => ({ type: route.params.type }), meta: { middleware: Guest } },
  { name: 'auth.recover-password', path: '/auth/recover-password', component: RecoverPassword, meta: { middleware: Guest } },
  { name: 'auth.reset-password', path: '/auth/reset-password', component: ResetPassword, meta: { middleware: Guest } },


  // Grant =====================================
  {name: 'grant.home', path: '/grant', component: GrantWizard},
  {name: 'grant.success', path: '/grant/submit/:id', component: GrantSuccess, props: route => ({ id: parseInt(route.params.id) })},
  {name: 'grant.show', path: '/grant/:id', component: GrantShow, props: route => ({ id: parseInt(route.params.id) })},

  // Rise Certificate
  {name: 'rise.certificate', path: '/rise/certificate', component: RiseCertificate, meta: { middleware: Auth }},


  // New Class ==============================
  { name: 'new-class.theme', path: '/new', component: SelectTheme, meta: { middleware: Auth } },
  { name: 'new-class.class', path: '/new/class', component: ClassDetails, meta: { middleware: Auth } },
  { name: 'new-class.students', path: '/new/students', component: Students, meta: { middleware: Auth } },
  { name: 'new-class.podcast', path: '/new/featured-podcast', component: FeaturedPodcast, meta: { middleware: Auth } },
  { name: 'new-class.library', path: '/new/library', component: Library, meta: { middleware: Auth } },


  // Class Management =======================
  { name: 'class.dashboard', path: '/', component: Dashboard, meta: { middleware: Auth } },
  { name: 'class.settings', path: '/class/:id/', component: ClassSettings, meta: { middleware: Auth } },
  { name: 'class.stage', path: '/class/:id/:stage', component: ClassStage, props: route => ({ stage: route.params.stage }), meta: { middleware: Auth } },

  // Podcast ================================
  { name: 'podcast.show', path: '/podcast/:slug/', component: PodcastSingle, props: route => ({ slug: route.params.slug }), meta: {middleware: AutoRedirect} },
  { name: 'podcast.index', path: '/podcasts', component: PodcastArchive, meta: {middleware: AutoRedirect} },

  // Profiles
  { name: 'profile.student', path: '/r/:id', component: StudentProfile, props: route => ({ id: parseInt(route.params.id) }) },



  // My Library
  { name: 'my-library.index', path: '/my-library', component: LibraryArchive },
  { name: 'my-library.create', path: '/my-library/create', component: LibraryEdit },
  { name: 'my-library.edit', path: '/my-library/:id/', component: LibraryEdit, props: route => ({ id: parseInt(route.params.id) }), meta: { middleware: Auth } },


  // Users
  { name: 'users.index', path: '/teachers', component: UsersList },
  { name: 'users.invite', path: '/teachers/invite', component: UserInvite },
  { name: 'users.edit', path: '/teachers/:id/', component: UserEdit, props: route => ({ id: parseInt(route.params.id) }) },


      // Error Page not Found.
  { name: 'error.404', path: "/:pathMatch(.*)*", component: Error404 },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


// A simple middleware processor using vue beforeEach guard.
router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) return next();
  const middleware = to.meta.middleware;
  const context = { to, from, next };
  return middleware({ ...context });
});

export default router;
