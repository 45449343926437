import inflect from 'i';
import jQuery from 'jquery';
import states from "./helpers/states";

export default {

    data(){
        return {
            store: {},
            save_timeout: null,
            save_debounce: 3000,
            save_xhr: null,
        };
    },

    computed: {
        window(){
            return window;
        },

        embed(){
          const embed = {
            is_embed: false,
            is_fullscreen: false,
          };
          const url = new URL(location.href);
          if( url.searchParams.has('embed') ){
            embed.is_embed = true;
            let fullscreen = url.searchParams.get('fullscreen')?.toLowerCase();
            if( fullscreen === 'true' || fullscreen === 'yes' || parseInt( fullscreen ) ){
              embed.is_fullscreen = true;
            }
          }
          return embed;
        },

        meta(){
            return this.store.meta;
        },

        base_url(){
            const parts = window.location.href.split('/');
            return parts[0] + "//" + parts[2];
        },

        current_user(){
            return window.user || null;
        },

        all_state_names(){
          return states;
        },

        projects: {
            get(){
                return this.store.projects;
            },
            set(projects){
                this.store.projects = projects.map(project => {
                    project.id = parseInt(project.id);
                    return project;
                });
            }
        },

        active_project_id(){
            let project_id = undefined;
            if( this.projects && this.projects.length && ['class.settings', 'class.stage'].indexOf(this.$route.name) >= 0 ){
                return parseInt(this.$route.params.id);
            }
            return project_id;
        },

        active_project(){
            return this.store.active_project;
        },

        sidebar: {
            get(){
                try{ return this.store.sidebar; }
                catch (e) { return { list: [] }; }
            },
            set(val){
                this.store.sidebar = val;
            }
        },

        inflect(){
            return inflect();
        },

    },

    methods: {

        routeNameIs(patterns){

            if( !Array.isArray( patterns ) ) patterns = [ patterns ];

            patterns = patterns.map( p => p instanceof RegExp ? p
                : new RegExp( p.replace('.', '\\.').replace('-', '\\-').replace('*', '.*') , 'i' ));

            for( const idx in patterns ){
                if(!Object.hasOwnProperty.call( patterns, idx )) continue;
                if( this.$route.name.match(patterns[idx]) ) return true;
            }

            return false;
        },

        scrollTo(el){
            jQuery('html, body').animate({
                scrollTop: jQuery( el ).offset().top - 30
            }, 1000);
        },

        scrollToTop(){
            jQuery('html, body').animate({
                scrollTop: 0
            }, 'fast');
        },

        back(){
            if( this.is_fullscreen ){
              this.fullscreen();
            }else{
              this.$router.back();
            }
        },

        openProject(id){
            const list = this.store.sidebar.list;
            if( list.indexOf(id) < 0 ){
                list.push(id);
            }
            this.$router.push({name: 'class.settings', params:{id}});
        },

        closeProject(id){
            const list = this.store.sidebar.list;
            const idx = list.indexOf(id);
            if( list.indexOf(id) >= 0 ){
                list.splice(idx, 1);
                this.$forceUpdate();
            }

            if( ['class.settings', 'class.stage'].indexOf(this.$route.name) >= 0 && this.$route.params.id === id ){
                this.$router.push({name: 'class.dashboard'});
            }
        },

        projectIconUrl(/*type*/){
            return '/img/ico_connect.png';
        },


        libraryImageUrl(lib){
            if( lib.picture ) return lib.picture;
            return `/img/placeholder-${lib.type}.svg`;
        },

        projectUrlForStudents(project){
            const code = typeof project === 'string' ? project : project.code;
            return `${process.env.VUE_APP_APP_BASE}/start/${code}/`;
        },

        enableAutoDownload(url){
            this.$cookies.set('auto-download', url);
        },

        checkAutoDownload(){
            try {
                const url = this.$cookies.get('auto-download');
                if( url ){
                    this.$cookies.remove('auto-download');
                    window.open(url, '_blank').focus();
                }
            }catch (e){/**/}
        },

        fileIcon(file){
            switch (file.mimetype){
                case 'image/jpg': return {icon: '/img/filetype/004-file-3.svg', preview: file.url};
                case 'image/jpeg': return {icon: '/img/filetype/004-file-3.svg', preview: file.url};
                case 'image/png': return {icon: '/img/filetype/003-file-2.svg', preview: file.url};
                case 'image/gif': return {icon: '/img/filetype/025-file-24.svg', preview: file.url};
                case 'image/svg': return {icon: '/img/filetype/001-file.svg', preview: file.url};

                case 'application/pdf': return {icon: '/img/filetype/008-file-7.svg', preview: null};

                case 'application/msword':
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    return {icon: '/img/filetype/006-file-5.svg', preview: null};

                case 'application/vnd.ms-excel':
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    return {icon: '/img/filetype/047-file-46.svg', preview: null};

                case 'application/vnd.ms-powerpoint':
                case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                    return {icon: '/img/filetype/023-file-22.svg', preview: null};

                case 'video/mp4': return {icon: '/img/filetype/011-file-10.svg', preview: null};
                case 'video/quicktime': return {icon: '/img/filetype/022-file-21.svg', preview: null};

                case 'application/zip': return {icon: '/img/filetype/043-file-42.svg', preview: null};
                case 'application/gzip': return {icon: '/img/filetype/043-file-42.svg', preview: null};
            }

            return {icon: '/img/filetype/046-file-45.svg', preview: null};
        },

        setPageTitle(title){
            return document.title = [
                process.env.VUE_APP_NAME_PREFIX,
                title,
                ' | ',
                process.env.VUE_APP_NAME
            ].join('');
        }


    },

    beforeMount(){
        this.store = this.$root._data.store;
    }
}