export default {
    meta: {title: ''},
    active_project: undefined,
    projects: [],
    sidebar: {
        list: []
    },
    progress: { max: 4, value: 0 },
    wiz: {
        course: '',
        grade_level: '',
        id: '',
        name: '',
        num_students: '',
        podcast: '',
        library_items: [], // Library item ids as an array.
        students: [],
        generate_access_code: true,
    },
};