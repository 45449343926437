var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('p',{staticClass:"text-center mt-4 text-block"}),_c('div',{ref:"selection",staticClass:"selected-content",class:{ 'is-empty': _vm.selected_library.length === 0 }},[_c('div',{staticClass:"no-items"},[_c('div',{staticClass:"inner"},[_c('h4',[_vm._v("No items selected.")]),_c('p',[_vm._v("Select one or more from the list below.")]),_c('a',{staticClass:"text-secondary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.scrollTo( _vm.$refs.library )}}},[_vm._v("Go to content list "),_c('i',{staticClass:"fas fa-arrow-down"})])])]),_c('div',{staticClass:"row"},_vm._l((_vm.selected_library),function(item){return _c('div',{key:item.id,staticClass:"col-sm-6 col-lg-4 mb-4"},[_c('card-selector',{attrs:{"card":{
            id: item.id,
            title: item.title,
            image: _vm.libraryImageUrl(item),
            type: item.type,
            difficulty: item.level,
            selected: _vm.store.wiz.library_items.indexOf(item.id) >= 0,
            preview: item.url,
          },"label-select":"ADD","label-deselect":"REMOVE","type":"checkbox","selectable":false,"deselectable":true},on:{"change":_vm.change}})],1)}),0)]),_c('div',{staticClass:"bottom-actions mb-5"},[_c('button',{staticClass:"btn btn-lg btn-dark",attrs:{"disabled":_vm.busy},on:{"click":_vm.back}},[_c('i',{staticClass:"fas fa-caret-left mr-2"}),_vm._v(" BACK ")]),_c('button',{staticClass:"btn btn-lg btn-secondary",attrs:{"disabled":!_vm.selected_library.length || _vm.busy},on:{"click":_vm.next}},[(_vm.busy)?_c('span',[_vm._v("WORKING "),_c('i',{staticClass:"fas fa-circle-notch fa-spin ml-2"})]):_c('span',[_vm._v("FINISH "),_c('i',{staticClass:"fas fa-caret-right ml-2"})])])]),_c('hr'),_c('div',{ref:"library",staticClass:"library"},[_c('div',{staticClass:"row"},_vm._l((_vm.library),function(item){return _c('div',{key:item.id,staticClass:"col-sm-6 col-lg-4 mb-4"},[_c('card-selector',{attrs:{"card":{
            id: item.id,
            title: item.title,
            description: item.description,
            image: _vm.libraryImageUrl(item),
            type: item.type,
            difficulty: item.level,
            selected: _vm.store.wiz.library_items.indexOf(item.id) >= 0,
            preview: item.url,
          },"label-select":"ADD","label-deselect":"REMOVE","type":"checkbox","selectable":true,"deselectable":false},on:{"change":_vm.change}})],1)}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected_library.length > 0 && _vm.scrolled_long_down),expression:"selected_library.length > 0 && scrolled_long_down"}],staticClass:"done"},[_c('button',{staticClass:"btn btn-primary btn-lg",on:{"click":function($event){return _vm.scrollTo( _vm.$refs.selection )}}},[_c('i',{staticClass:"fas fa-check-circle mr-1"}),_vm._v(" Review Selection ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }