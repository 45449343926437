<template>
  <div class="container">
    <p class="text-center mt-4 text-block">
    </p>
    <div class="row">

      <div class="col-md-5 order-md-last">
        <div class="selected-podcast-wrap">
          <div v-if="!selected_podcast" class="no-podcast">
            <div class="inner">
              <img src="https://www.flaticon.com/svg/static/icons/svg/2701/2701171.svg" alt="">
              <p>No podcast selected</p>
            </div>
          </div>
          <div class="selected-podcast" v-if="selected_podcast">
<!--            <card-selector :card="{-->
<!--              id: selected_podcast.id,-->
<!--              title: selected_podcast.name,-->
<!--              description: selected_podcast.description,-->
<!--              image: selected_podcast.picture,-->
<!--              duration: selected_podcast.duration,-->
<!--              difficulty: selected_podcast.difficulty,-->
<!--              short_name: selected_podcast.short_name,-->
<!--              preview: '/podcast/' + selected_podcast.short_name,-->
<!--            }" :selectable="false"></card-selector>-->

            <podcast-list-item :podcast="selected_podcast" :newtab="true" />

          </div>
          <div class="bottom-actions my-4">
            <button class="btn btn-lg btn-dark"
                    @click="back">
              <i class="fas fa-caret-left mr-2"></i> BACK
            </button>
            <button class="btn btn-lg btn-secondary"
                    @click="next"
                    :disabled="!selected_podcast">
              NEXT <i class="fas fa-caret-right ml-2"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-7 order-md-first">
        <div class="podcasts row align-items-stretch">
          <div v-for="podcast in podcasts" :key="podcast.id"
               :class="podcast.priority > 0 ? 'col-md-12' : 'col-md-6'"
               class="podcast mb-4">

            <podcast-list-item :podcast="podcast"
                               :featured="podcast.priority > 0"
                               :selectable="true"
                               :newtab="true"
                               @selected="change(podcast)" />

<!--            <card-selector :card="{-->
<!--              id: podcast.id,-->
<!--              title: podcast.name,-->
<!--              description: podcast.description,-->
<!--              image: podcast.picture,-->
<!--              duration: podcast.duration,-->
<!--              difficulty: podcast.difficulty,-->
<!--              selected: store.wiz.podcast === podcast.short_name,-->
<!--              short_name: podcast.short_name,-->
<!--              preview: '/podcast/' + podcast.short_name,-->
<!--            }" @change="change" type="checkbox" :selectable="true" :deselectable="false" />-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import BoxCheck from "../../components/BoxCheck";
import mixins from "../../mixins";
import API from "../../api";
// import CardSelector from "../../components/CardSelector";
import PodcastListItem from "@/components/PodcastListItem";
import tracker from "@/mixins/tracker";

export default {
  name: "FeaturedPodcast",
  components: {/*CardSelector, BoxCheck,*/ PodcastListItem},
  mixins: [ mixins, tracker ],

  data(){
    return {
      podcasts: []
    };
  },

  computed: {
    selected_podcast(){
      return this.podcasts.reduce((acc, val) => val.short_name === this.store.wiz.podcast ? val : acc, null);
    }
  },

  methods: {

    change(podcast){
      this.podcasts.map(p => p.selected = false);
      podcast.selected = true;
      this.store.wiz.podcast = podcast.selected ? podcast.short_name : '';
      this.podcasts.map(p => p.selected = podcast.id === p.id ? podcast.selected : false);
    },

    next(){
      this.$router.push({ name: 'new-class.library' });
    },

  },

  beforeMount() {
    this.setPageTitle( 'Create New Class - Featured Podcast' );
    this.tracker.visit(this.$route);
  },

  mounted() {

    // Students check not required anymore as one can skip them in last step.

    this.meta.title = 'Featured Podcast';
    this.store.progress.value = 3;

    // Get Podcasts and filter them as per course selected.
    API.getPodcasts()
        .then(podcasts => {
          this.podcasts = podcasts
            .filter(podcast => podcast.course === this.store.wiz.course);

          this.podcasts.map(podcast => podcast.selected = false)

        });
  },
}
</script>

<style lang="scss" scoped>

//.container{
//  max-width: 1200px !important;
//}

.selected-podcast-wrap{
  position: sticky;
  top: 30px;
}

.no-podcast{
  height: 70vh;
  border: rgba(black, 0.1) solid 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;

  .inner{

    img{
      display: block;
      width: 100%;
      max-width: 80px;
      margin: 1rem auto;
      opacity: 0.2;
    }

    p{
      font-size: 1.5rem;
      color: #d4d4d4;
    }

  }
}

</style>