<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><path class="a" d="M42.98,7A4,4,0,0,0,39,3H7A4.012,4.012,0,0,0,3,7V31a4.012,4.012,0,0,0,4,4H35l8,8ZM35,27H11V23H35Zm0-6H11V17H35Zm0-6H11V11H35Z" transform="translate(-3 -3)"/></svg>
</template>

<script>
export default {
  name: "IconComment",
}
</script>
<style>
  .a{fill:currentColor;}
</style>