<template>
  <div class="dashboard">
    <title-bar title="Dashboard"
               :description="`Welcome, ${current_user.first_name}`"
               class="mb-4"
               background="/dfcusa-web/img/img_hedr_teachers.png"/>
    <div class="content-area">
      <div class="actions my-3">
        <div class="filters">
          <a v-for="type in options.filter_types" :key="type.filter"
             :class="{active: type.filter === filter.type}"
             href="#"
             @click.prevent.stop="filter.type = type.filter"
             class="filter">{{ type.title }}</a>
        </div>
        <div class="primary-action">
          <router-link class="btn btn-danger text-uppercase"
                       @click="tracker.track('Create New Class', {source: 'Dashboard Header'})"
                       :to="{name: 'new-class.theme'}">
            <i class="fas fa-plus"></i> Create New Class
          </router-link>
        </div>
      </div>
      <div v-if="filteredProjects.length" class="projects">
        <div v-for="project in filteredProjects" :key="project.id"
             class="project-wrap">
          <a href="#" @click.prevent="openProject(project.id)" class="project">
            <div class="inner"
                 :style="project.picture ? 'background-image: url(' + project.picture + ')' : ''">
              <div class="overlay">
                <h4>{{ project.name }}</h4>
                <div class="grade">{{ project.grade_level ? inflect.titleize( project.grade_level ) : '' }}</div>
                <div class="type text-uppercase">{{ project.course_name }}</div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div v-else>
        <div class="card text-center">
          <div class="card-body py-5">
            <p>Sorry, no classes found.</p>
            <router-link :to="{name: 'new-class.theme'}"
                         @click="tracker.track('Create New Class', {source: 'Dashboard Empty CTA'})"
                         class="btn btn-primary">
              <i class="fas fa-plus mr-1"></i> Create New Class
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "../mixins";
import tracker from '@/mixins/tracker';
import TitleBar from "../components/TitleBar";

export default {
  name: "Dashboard",
  components: {TitleBar},
  mixins: [mixins, tracker],

  data(){
    return {
      options: {
        filter_types: [
          {title: 'All', filter: ''},
          {title: 'Racial Justice', filter: 'justice'},
          {title: 'Climate Action', filter: 'climate'},
          {title: 'Education Equity', filter: 'education'},
        ],
      },
      filter: {
        type: ''
      }
    }
  },

  computed: {
    filteredProjects(){
      return this.filter.type ? this.projects.filter(item => item.course === this.filter.type) : this.projects;
    }
  },

  beforeMount() {
    this.setPageTitle('Dashboard');
    this.tracker.visit(this.$route);
  }

}
</script>

<style lang="scss" scoped>
.projects{
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap: wrap;
}


.project{
  display: block;
  padding-left: 40px;

  &-wrap{
    padding-left: 10px;
    padding-right: 10px;
    flex: 0 0 50%;
    width: 50%;
    margin-bottom: 30px;


    @media screen and (max-width: 767px) {
      flex: 0 0 100%;
      width: 100%;
    }
  }

  .inner{
    background: #8b8d8f no-repeat center / cover;
    position: relative;
    height: 260px;
    box-shadow: rgba(black, 0.15) 0 3px 6px;
    border-radius: 1rem;
    transition: box-shadow .3s, transform .3s;

    &:hover{
      box-shadow: rgba(black, 0.15) 0 8px 16px;
      transform: scale(1.01) rotate(0.5deg);
    }
  }

  .overlay{
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    color: #666666;
    padding: 10px 10px 10px 40px;
    background: white;

    h4{
      font-size: 1.4rem;
      margin: 0;
      font-weight: 600;
    }

    .grade{
      font-size: 0.8rem;
      margin-bottom: 2px;
    }

    .type{
      font-size: 0.8rem;
      font-weight: bold;
    }
  }
}

.actions{
  display: flex;
  margin: 0 -10px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  > * {
    padding: 0 10px;
    margin-bottom: 1rem;
  }
}

.filters{
  padding-left: 40px;
  display: flex;

  .filter{
    background: #f0f0f0;
    color: black;
    border: #c0c0c0 solid 1px;
    padding: 0.2rem 0.8rem;
    font-size: 0.9rem;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1.4rem;

    &:first-child{
      border-radius: 20px 0 0 20px;
    }

    &:last-child{
      border-radius: 0 20px 20px 0;
    }

    &.active{
      background: black;
      color: white;
      border: black solid 1px;
    }
  }
}

</style>