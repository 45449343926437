<template>
<div class="register py-3">
  <div class="container py-5">
    <div class="row align-items-center justify-content-center">
      <div class="col-md-4 pr-md-5 text-smaller border-right text-center text-md-left">
        <img src="/img/img_logo_dfc.svg"
             class="logo my-3 mx-auto mx-md-0"
             alt="Design for change">

        <h1 class="h4">Welcome to our virtual platform!</h1>
        <p>DFC equips young people to transform empathy into social action using a simple framework.</p>
        <p>Educators use DFC to facilitate a design process that fosters empathy and student agency.</p>
        <p>Students brainstorm, plan, and execute community change projects.</p>
        <p><a href="http://www.designforchange.us/">Learn more</a></p>
        <router-link class="btn btn-success" :to="{name: 'auth.login'}">Already Registered? Login</router-link>

      </div>
      <div class="col-md-4 pl-md-5 text-center">
        <p class="h6 mb-4">
          Select if you are part of a school/organization or if you are a parent:
        </p>
        <router-link class="btn btn-primary btn-block my-4" :to="{name: 'auth.register.wizard', params: {type: 'organization'}}">
          <img src="/img/school.png"
               alt=""
               class="img-fluid d-block mx-auto my-2">
          <div class="py-1">
            School / Organization
          </div>
        </router-link>
        <router-link class="btn btn-success btn-block my-4" :to="{name: 'auth.register.wizard', params: {type: 'personal'}}">
          <img src="/img/family.png"
               alt=""
               class="img-fluid d-block mx-auto my-2">
          <div class="py-1">
            Parent
          </div>
        </router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import tracker from "@/mixins/tracker";

export default {
  name: "Register",
  mixins: [tracker],

  mounted() {
    if( typeof this.$route.query.org !== 'undefined' && this.$route.query.org.length > 0 ){
      this.$router.push({name: 'auth.register.wizard', params: {type: 'organization'}, query: {org: this.$route.query.org}});
    }

    this.setPageTitle( 'Register' );
    this.tracker.visit(this.$route);

  }
}
</script>

<style lang="scss" scoped>
.text-smaller{
  font-size: 0.9em;
}

p{
  margin: 1.5rem 0;
}

.logo{
  width: 100%;
  max-width: 200px;
  display: block;
}
.btn{
  color: white;

  img{
    max-height: 80px;
    width: auto;
  }
}
</style>