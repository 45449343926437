<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26.667" viewBox="0 0 24 26.667"><defs></defs><path class="a" d="M11.76,26.475h2.667v-16H11.76Zm5.333,5.333H19.76V5.141H17.093V31.808ZM6.427,21.141H9.093V15.808H6.427Zm16,5.333h2.667v-16H22.427ZM27.76,15.808v5.333h2.667V15.808Z" transform="translate(-6.427 -5.141)"/></svg>
</template>

<script>
export default {
  name: "IconAudio",
}
</script>
<style>
  .a{fill:currentColor}
</style>