<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="39.788" height="31.493" viewBox="0 0 39.788 31.493"><path class="a" d="M37.191,14.8a9.243,9.243,0,0,0-16.5-5.491,5.192,5.192,0,0,0-9.247,3.913A7.531,7.531,0,1,0,10.1,28.165h3.565l8.8,9.112,8.8-9.112H35.6A6.779,6.779,0,0,0,37.191,14.8ZM22.465,33.139,15,25.678h4.974v-7.46h4.974v7.46h4.974l-7.46,7.46Z" transform="translate(-2.571 -5.784)"/></svg>
</template>

<script>
export default {
  name: "IconCloudDownload",

}
</script>
<style>
  .a{fill:currentColor}
</style>