<template>
  <div class="register-parent my-5">
    <div class="container text-center">

      <img src="/img/img_logo_dfc.svg"
           class="logo d-block my-3 mx-auto"
           alt="Design for change">

      <div v-if="invalid_organization" class="my-5">
        <hr class="mb-4">
        <div class="text-danger">
          <div class="h3">
            <i class="fas fa-exclamation-triangle fa-2x"></i>
          </div>
          <p class="mt-3">Sorry we couldn't find any organization you are referring to.</p>
        </div>
        <router-link :to="{name: 'auth.register'}" class="btn btn-primary mt-3">
          Create account without referred Organization
        </router-link>
      </div>
      <template v-else>
        <div v-if="stage === 'location'"
             class="step-location">
          <div v-if="type === 'organization'">
            <h2 class="h4">Search Organization</h2>
            <p>Let's see if we can find your organization or school:</p>
            <form class="form-group" @submit.prevent="searchOrgs()">
              <div class="input-group">
                <input type="text"
                       v-model="search_org_keyword"
                       :disabled="is_busy"
                       class="form-control"
                       placeholder="Type in your school name">
                <div class="input-group-append">
                  <button type="submit"
                          :disabled="is_busy"
                          class="btn btn-primary">Search</button>
                </div>
              </div>
            </form>

            <div v-if="organizations.registered.length || organizations.geo.length"
                 class="org-list">

              <template v-if="organizations.registered.length">
                <p class="font-weight-bold">These organizations are already registered with Design for Change:</p>
                <ul>
                  <li v-for="name in organizations.registered" :key="name">
                    <span class="flex-grow-1">{{ name }}</span>
                    <button class="btn btn-primary"
                            :disabled="is_busy"
                            @click="selectRegisteredOrg(name)">Select</button>
                  </li>
                </ul>
              </template>

              <template v-if="organizations.geo.length">
                <p class="font-weight-bold">We found these organizations on Google:</p>
                <ul>
                  <li v-for="place in organizations.geo" :key="place.place_id">
                <span class="flex-grow-1">
                  <strong class="font-weight-bold d-block">{{ place.name }}</strong>
                  <small>{{ place.formatted_address }}</small>
                </span>
                    <button class="btn btn-primary"
                            :disabled="is_busy"
                            @click="selectUnregisteredOrg(place)">Select</button>
                  </li>
                </ul>
              </template>
            </div>

          </div>

          <div v-if="type === 'personal'">
            <h2 class="h4">Your Location</h2>
            <p>Please enter your city and state:</p>

            <form @submit.prevent="setPersonalLocation()">
              <div class="form-group">
                <input type="text"
                       v-model="wiz.location"
                       :disabled="is_busy"
                       class="form-control"
                       placeholder="Your City">
              </div>
              <div class="form-group">
                <select v-model="wiz.state"
                        :disabled="is_busy"
                        class="form-control">
                  <option value="">-- Select State --</option>
                  <option v-for="(name, code) in all_state_names" :key="code"
                          :value="code">{{ name }}</option>
                </select>
              </div>
              <div class="form-group">
                <button type="submit"
                        :disabled="is_busy || !stage_location_personal_is_valid"
                        class="btn btn-primary btn-lg">Next</button>
              </div>
            </form>
          </div>

        </div>


        <div v-if="stage === 'userinfo'"
             class="step-userinfo">
          <hr>
          <h1 class="h3">{{ wiz.organization }}</h1>
          <p v-if="wiz.state || wiz.location">{{ [wiz.location, wiz.state].join(', ') }}</p>
          <hr>
          <h2 class="h4">Account Details</h2>
          <p>Please give your full name &amp; a valid email address:</p>

          <form action="" @submit.prevent="submitUserInfo()">
            <div class="form-group">
              <div class="input-group">
                <input type="text"
                       v-model="wiz.first_name"
                       :disabled="is_busy"
                       class="form-control"
                       placeholder="First Name">
                <input type="text"
                       v-model="wiz.last_name"
                       :disabled="is_busy"
                       class="form-control"
                       placeholder="Last Name">
              </div>
            </div>
            <div class="form-group">
              <div class="input-validation">
                <input type="email"
                       :disabled="is_busy"
                       class="form-control"
                       @keyup="checkEmail($event.target.value)"
                       @change="checkEmail($event.target.value)"
                       placeholder="Email Address">
                <div class="input-validation-icon" v-if="wiz.email.length">
                  <i class="fas fa-exclamation-circle text-danger" v-if="!stage_userinfo_email_is_valid || email_exists"></i>
                  <i class="fas fa-check-circle text-success" v-else></i>
                </div>
              </div>
            </div>

            <div v-if="email_exists" class="form-group">
              <div class="text-danger">
                <i class="fas fa-exclamation-circle mr-1"></i>
                Email is already registered.
                <router-link :to="{name: 'auth.recover-password'}">Forgot Password?</router-link>
              </div>
            </div>

            <div class="form-group">
              <button type="submit"
                      :disabled="is_busy || !stage_userinfo_is_valid"
                      class="btn btn-primary btn-lg">Next</button>
            </div>
          </form>
        </div>

        <div v-if="stage === 'security'"
             class="step-userinfo">
          <hr>
          <h1 class="h3">{{ wiz.organization }}</h1>
          <p class="small" v-if="wiz.state || wiz.location">{{ [wiz.location, wiz.state].join(', ') }}</p>
          <hr>
          <h2 class="h4">Security</h2>
          <p>Secure your account with a password:</p>

          <form action="" @submit.prevent="register()">
            <div class="form-group">
              <input type="password"
                     v-model="wiz.password"
                     :disabled="is_busy"
                     class="form-control"
                     placeholder="Password">
            </div>
            <div class="form-group">
              <div class="input-validation">
                <input type="password"
                       v-model="re_password"
                       :disabled="is_busy"
                       class="form-control"
                       placeholder="Retype Password">
                <div v-if="re_password.length" class="input-validation-icon">
                  <i v-if="stage_security_password_is_valid" class="fas fa-check-circle text-success"></i>
                  <i v-else class="fas fa-exclamation-circle text-danger"></i>
                </div>
              </div>

            </div>

            <div v-if="re_password.length && !stage_security_password_is_valid" class="form-group">
              <div class="text-danger">
                <i class="fas fa-exclamation-circle mr-1"></i>
                Passwords do not match.
              </div>
            </div>

            <div v-if="error" class="form-group">
              <div class="text-danger">
                <i class="fas fa-exclamation-circle mr-1"></i>
                {{ error }}
              </div>
            </div>

            <div class="form-group">
              <button type="submit"
                      :disabled="is_busy || !stage_security_password_is_valid"
                      class="btn btn-primary btn-lg">Register</button>
            </div>
          </form>
        </div>
      </template>

      <hr>

      <div class="my-3">
        Already have account?
        <router-link :to="{name: 'auth.login'}">Login here.</router-link>

        <div class="my-5 small">
          © 2021 Design for Change USA
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import API from "../../api";
import mixins from "../../mixins";
import tracker from "../../mixins/tracker";
import jQuery from "jquery";

export default {
  name: "RegisterWizard",
  mixins: [ mixins, tracker ],
  props: [ 'type' ],

  data(){
    return {
      is_busy: false,
      stage_index: 0,
      stages: ['location', 'userinfo', 'security'],

      invalid_organization: false,
      search_org_keyword: '',
      organizations: {
        registered: [],
        geo: [],
      },

      email_exists: false,
      check_email_timeout: null,
      re_password: '',
      error: '',

      wiz: {
        email: '',
        first_name: '',
        last_name: '',
        location: '',
        organization: '',
        password: '',
        project_id: '',
        site_id: '-1',
        state: '',
        type: 'mentor',
      }
    };
  },


  computed: {
    stage(){
      return this.stages[ this.stage_index ];
    },

    stage_location_personal_is_valid(){
      return jQuery.trim( this.wiz.state ).length
          && jQuery.trim( this.wiz.location ).length;
    },

    stage_userinfo_is_valid(){
      return jQuery.trim( this.wiz.first_name ).length
          && jQuery.trim( this.wiz.last_name ).length
          && this.stage_userinfo_email_is_valid
          && !this.email_exists;
    },

    stage_userinfo_email_is_valid(){
      const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      return regex.test( this.wiz.email );
    },

    stage_security_password_is_valid(){
      return this.wiz.password.length && this.wiz.password === this.re_password;
    },
  },

  methods: {

    searchOrgs(){
      const keywords = jQuery.trim( this.search_org_keyword );
      if( !keywords.length ) return;
      this.is_busy = true;
      API
          .searchOrganizations(this.search_org_keyword)
          .then(res => this.organizations = res)
          .catch(e => console.log( e ))
          .then(() => this.is_busy = false);
    },

    setPersonalLocation(){
      if( !this.stage_location_personal_is_valid ) return;
      this.wiz.organization = 'Personal Organization';
      this.stage_index++;
    },

    selectRegisteredOrg(name){
      this.is_busy = true;
      API.getOrganizationByName( name )
          .then( org => {
            this.setSelectedOrganization(org);
            this.stage_index++;
          })
          .catch(e => console.log(e))
          .then(() => this.is_busy = false);
    },

    selectUnregisteredOrg(place){
      this.is_busy = true;
      API.geoPlace( place.place_id )
          .then( p => {
            this.wiz.organization = p.name;
            this.wiz.location = p.location;
            this.wiz.state = p.state;
            this.stage_index++;
          })
          .catch( e => console.log( e ) )
          .then(() => this.is_busy = false);
    },

    checkEmail(email){
      try {

        if( this.check_email_timeout ){
          clearTimeout(this.check_email_timeout);
        }

        this.check_email_timeout = setTimeout(() => {
          API.checkEmailExists(email)
              .then(exists => {
                this.email_exists = exists;
                this.wiz.email = email;
              })
              .catch(() => this.wiz.email = '');
        }, 300);

      }catch (e){/**/}
    },

    submitUserInfo(){
      if( !this.is_busy && this.stage_userinfo_is_valid ){
        this.stage_index++;
      }
    },

    register(){

      this.is_busy = true;
      this.error = '';

      API
          .register(this.wiz)
          .then((data) => {

            // Track -----------
            this.tracker.track('Register', {
              email: this.wiz.email,
            });

            // Force reload location.
            this.$cookies.set('user', data.security_hash, 999999999);
            window.onhashchange = () => window.location.reload();

            this.$swal({
              title: 'Registration Successful',
              text: 'You are now automatically be signed in to your account.',
              icon: 'success',
            }).then( () => window.location.hash = `/` );
          })
          .catch(() => this.error = 'Failed to register, please check if your email address is already registered with us.')
          .then(() => this.is_busy = true );
    },

    setSelectedOrganization(org) {
      this.wiz.organization = org.name;
      this.wiz.location = org.city;
      this.wiz.state = org.state;
    },
  },

  async mounted() {

    if( typeof this.$route.query.org !== 'undefined' && this.$route.query.org.length > 0 ){
      this.stage_index = 1;
      this.is_busy = true;
      try{
        const org = await API.getOrganizationById( this.$route.query.org );
        this.setSelectedOrganization( org );
        this.is_busy = false;
      }catch (e) {
        this.invalid_organization = true;
      }
    }


    this.setPageTitle( 'Register' );
    this.tracker.visit(this.$route);

  }

}
</script>

<style lang="scss" scoped>
.container{
  max-width: 600px !important;
}
.logo{
  max-width: 200px;
}
.input-group{
  .form-control{
    &:not(:last-child){
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &:not(:first-child){
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
.org-list{
  ul{
    list-style: none;
    padding: 0;

    li{
      margin: 0;
      padding: 0.6rem 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: #f0f0f0 solid 1px;
      text-align: left;

      &:first-child{
        border-top: #f0f0f0 solid 1px;
      }

      &:hover{
        background: #eaeaea;
      }
    }
  }
}
</style>