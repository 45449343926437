<template>
  <label class="box-check">
    <input type="checkbox" v-model="box.checked" @change="$emit('change', box)">
    <span class="ui">
      <span class="icon"><i class="fas fa-check"></i></span>
      <span v-if="box.image"
           class="image"
           :style="'background-image:url(' + box.image + ')'"></span>
      <span class="box-title">
        {{ box.title }}
      </span>
    </span>
  </label>
</template>

<script>
export default {
  name: "BoxCheck",
  props: {
    box: {
      type: Object,
      default(){
        return {
          title: 'Box',
          checked: false,
          image: null,
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
$active-color: #76dd68;

.box-check{
  display: block;
  width: 100%;
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;

  input{
    display: none;

    &:checked{
      +.ui{
        background: $active-color;
        color: white;
        box-shadow: rgba(black, 0.2) 0 5px 16px;
        transform: scale(1.025);

        .icon{
          opacity: 1;
        }
      }
    }
  }

  .ui{
    display: block;
    position: relative;
    background: white;
    border-radius: 1rem;
    box-shadow: rgba(black, 0.1) 0 3px 8px;
    transition: background-color .2s, color .2s, box-shadow .2s, transform .3s;

    &:hover{
      box-shadow: rgba(black, 0.1) 0 5px 16px;
      transform: scale(1.015);
    }

    .icon{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 2.2rem;
      height: 2.2rem;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $active-color;
      color: white;
      border: white solid 2px;
      box-shadow: rgba(black, 0.25) 0 0 10px;
      border-radius: 50%;
      opacity: 0;
      transition: opacity .2s;
    }

    .image{
      display: block;
      height: 180px;
      background: transparent no-repeat center / cover;
      border-radius: 1rem;
    }

    .box-title{
      display: block;
      padding: 0.6rem 1rem;
      font-weight: bold;
      font-size: 1.2rem;
      text-align: center;
    }
  }
}
</style>