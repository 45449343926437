<template>
    <div class="users-list">
        <title-bar title="Users"
               class="mb-4"
               background="/dfcusa-web/img/img_hedr_teachers.png"/>
                <!-- ask about image-->

            <div class="content-archive">
                <div class="actions my-3">
                    <div class="filters">
                        <a v-for="f in filter_users" :key="f.filter"
                            href="#"
                            @click.prevent="filter.user = f.type"
                            :class="{active: f.type === filter.user}"
                        class="filter">{{ f.title }}</a>
                    </div>
                    <div class="primary-action">
                        <router-link class="btn btn-danger text-uppercase"
                                    :to="{ name: 'users.invite' }">
                            <i class="fas fa-plus"></i> Invite User
                        </router-link>
                    </div>
                </div>


                <div class="content-area">
                    <div class="users mb-4" v-if="!users_busy">
                      <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                            <tr>
                                <th width="1"></th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>
                              <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <!--needs styling -->
                            <tr v-for="item in filtered_users" :key="item.id">
                                <td><span class="user-status" :class="item.status" :title="item.status"></span></td>
                              <td>{{item.first_name}} {{item.last_name}}</td>
                              <td>{{item.email}}</td>
                              <td>{{item.type}}</td>

                              <td>
                                  <div class="btn-group">
                                      <router-link class="btn btn-primary text-uppercase"
                                                   :to="{ name:'users.edit', params: { id: item.id } }">
                                          <i class="fas fa-edit"></i>
                                          <span class="d-none d-md-inline ml-1">
                                              Edit
                                          </span>
                                      </router-link>
                                      <button @click="deleteUser(item.id)" class="btn btn-danger px-3">
                                          <i class="fas fa-times"></i>
                                      </button>
                                  </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                      </div>
                    </div>
                </div>

            </div>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import API from "@/api";

import mixins from "@/mixins";
import tracker from "@/mixins/tracker";

export default {
  name: "UsersList",
  components: {TitleBar},
  mixins: [mixins, tracker],

  props: {
    id: {
      type: Number,
      default: 0
    },
  },

  data() {
    return {
      filter: {
        user: '',
      },
      users: [],
      users_busy: false,

      filter_users: [
        {title: 'All', type: ''},
        {title: 'Admins', type: 'admin'},
        {title: 'Teachers', type: 'teacher'},
      ],
    };
  },

  computed: {
    filtered_users(){
        return this.users.filter(user => (!this.filter.user.length || user.type === this.filter.user));
    }
  },

  watch: {
    filter: {
      deep: true,
      handler(){
        this.refresh();

      }
    }
  },

  methods: {
    async refresh() {

      // TODO: Image transformation: https://cdn.filestackcontent.com/resize=width:480/crop=dim:[0,0,480,360]/n7u2FSNjQU6e63F7qDaZ
      // TODO: Image transformation: https://cdn.filestackcontent.com/resize=h:480,w:360,fit:crop/n7u2FSNjQU6e63F7qDaZ
      // TODO: Document transformation: https://cdn.filestackcontent.com/ApAGlzhR0TS2QZCuWqVg8z/doc_to_images@1/n7u2FSNjQU6e63F7qDaZ
      try {
        let users = await API.allUsers();
        users = typeof users === "string" ? JSON.parse(users) : users;
        this.users.length = 0;
        users.map((item) => this.users.push(item));
        this.$forceUpdate();
      } catch (e) {
        console.log( e);
      }
    },

    async deleteUser(user_id) {

        this.$swal({
        title: 'Remove User?',
        html: `
            Are you sure you want to delete this user?
            This action can't be undone`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, Remove.'
      }).then(({ isConfirmed }) => {
        isConfirmed && this.removeUser( user_id );

      });
    },

    async removeUser(user_id ) {
      await API.deleteUser( user_id ).then(() => {
        this.refresh();
      });
    }

  },

  mounted() {
    console.log("mounted");
    this.refresh();

    this.setPageTitle('Manage Teachers');
    this.tracker.visit(this.$route);

  },

};
</script>

<style lang="scss" scoped>
//.delete-user i {
//    color: rgba(255, 0, 0, 0.8);
//}
.actions {
  display: flex;
  margin: 0 -10px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  > * {
    padding: 0 10px;
    margin-bottom: 1rem;
  }
}

.filters {
  padding-left: 40px;
  display: flex;

  .filter {
    background: #f0f0f0;
    color: black;
    border: #c0c0c0 solid 1px;
    padding: 0.2rem 0.8rem;
    font-size: 0.9rem;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1.4rem;

    &:first-child {
      border-radius: 20px 0 0 20px;
    }

    &:last-child {
      border-radius: 0 20px 20px 0;
    }

    &.active {
      background: black;
      color: white;
      border: black solid 1px;
    }
  }
}

//table {
//    font-size: 14px;
//    border-collapse: collapse;
//    width: 100%;
//    td,th {
//        border: 1px solid #ddd;
//        padding: 8px;
//    }
//    tr:nth-child(even){background-color: #f2f2f2;}
//    th {
//        padding-top: 12px;
//        padding-bottom: 12px;
//        text-align: left;
//        background-color: #666666;
//        color: #fff;
//    }
//}
.user-status {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background: rgba(255, 0, 0, 0.8);
  display: block;
  margin: 0 auto;

  &.active {
    background: #88c51f;
  }
}

</style>