<template>
  <div class="login">
    <form action="" @submit.prevent="login()">
      <div class="container text-center">
        <img src="/img/img_logo_dfc.svg"
             class="logo"
             alt="Design for change">
        <h1 class="h3 mb-3">Welcome</h1>
        <div class="form-group">
          <input type="text"
                 v-model="email"
                 class="form-control"
                 placeholder="Email">
        </div>
        <div class="form-group">
          <input type="password"
                 v-model="password"
                 class="form-control"
                 placeholder="Password">
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-lg btn-block">Sign In</button>
        </div>

        <div v-if="error" class="form-group">
          <div class="text-danger">
            <i class="fas fa-exclamation-circle mr-1"></i>
            {{ error }}
          </div>
        </div>

        <div class="my-5">
          Need help signing in?<br>
          <router-link :to="{name: 'auth.recover-password'}">Reset your password.</router-link>
        </div>

        <div class="my-5">
          Don't have an account?
          <router-link :to="{name: 'auth.register'}">Sign-up here.</router-link>
        </div>

        <div class="my-5 small">
          © 2021 Design for Change USA
        </div>

      </div>
    </form>
  </div>
</template>

<script>
import API from "../../api";
import tracker from "@/mixins/tracker";

export default {
  name: "Login",
  mixins: [tracker],

  data(){
    return {
      email: '',
      password: '',
      error: '',
    }
  },

  methods: {
    login(){

      this.error = '';

      if ( !this.email.length ){
        return this.error = 'Email cannot be blank!';
      }

      const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      if ( !regex.test( this.email ) ){
        return this.error = 'Email is not valid!';
      }

      if ( !this.password.length ){
        return this.error = 'Password cannot be blank!';
      }

      API
          .login(this.email, this.password)
          .then((data) => {
            // Force reload location.
            // window.onchange = () => window.location.reload();

            this.$cookies.set('user', data.security_hash, 999999999);
            let redirect_url = '/';
            if( this.$route.query.back){
              if(typeof this.$route.query.back === 'object' && this.$route.query.back.href){
                redirect_url = this.$route.query.back.href;
              }else if(typeof this.$route.query.back === 'string'){
                redirect_url = this.$route.query.back;
              }
            }

            console.log(redirect_url);

            window.location = redirect_url;

          })
          .catch(() => {
            this.error = 'Invalid email or password!';
          });
    }
  },

  mounted() {
    this.setPageTitle( 'Login' );
    this.tracker.visit(this.$route);
  }

}
</script>

<style lang="scss" scoped>
.login{
  max-width: 400px;
  margin: auto;
}
.logo{
  display: block;
  max-width: 200px;
  margin: 2rem auto 2rem;
}
</style>