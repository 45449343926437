<template>
  <div class="container">
    <p class="text-center mt-4 text-block">
    </p>
    <div class="selected-content"
         ref="selection"
         :class="{ 'is-empty': selected_library.length === 0 }">
      <div class="no-items">
        <div class="inner">
          <h4>No items selected.</h4>
          <p>Select one or more from the list below.</p>
          <a href="#"
             class="text-secondary"
             @click.prevent="scrollTo( $refs.library )">Go to content list <i class="fas fa-arrow-down"></i></a>
        </div>
      </div>
      <div class="row">
        <div v-for="item in selected_library"
             :key="item.id"
             class="col-sm-6 col-lg-4 mb-4">
          <card-selector :card="{
              id: item.id,
              title: item.title,
              image: libraryImageUrl(item),
              type: item.type,
              difficulty: item.level,
              selected: store.wiz.library_items.indexOf(item.id) >= 0,
              preview: item.url,
            }"
            label-select="ADD"
            label-deselect="REMOVE"
            @change="change"
            type="checkbox"
            :selectable="false"
            :deselectable="true" />
        </div>
      </div>
    </div>
    <div class="bottom-actions mb-5">
      <button class="btn btn-lg btn-dark"
              :disabled="busy"
              @click="back">
        <i class="fas fa-caret-left mr-2"></i> BACK
      </button>
      <button class="btn btn-lg btn-secondary"
              @click="next"
              :disabled="!selected_library.length || busy">
        <span v-if="busy">WORKING <i class="fas fa-circle-notch fa-spin ml-2"></i></span>
        <span v-else>FINISH <i class="fas fa-caret-right ml-2"></i></span>
      </button>
    </div>

    <hr>

    <div class="library" ref="library">
      <div class="row">
        <div v-for="item in library" :key="item.id"
             class="col-sm-6 col-lg-4 mb-4">
          <card-selector :card="{
              id: item.id,
              title: item.title,
              description: item.description,
              image: libraryImageUrl(item),
              type: item.type,
              difficulty: item.level,
              selected: store.wiz.library_items.indexOf(item.id) >= 0,
              preview: item.url,
            }"
            label-select="ADD"
            label-deselect="REMOVE"
            @change="change"
            type="checkbox"
            :selectable="true"
            :deselectable="false" />
        </div>
      </div>
    </div>

    <div class="done" v-show="selected_library.length > 0 && scrolled_long_down">
      <button class="btn btn-primary btn-lg" @click="scrollTo( $refs.selection )">
        <i class="fas fa-check-circle mr-1"></i> Review Selection
      </button>
    </div>

  </div>
</template>

<script>
import jQuery from 'jquery';
import mixins from "../../mixins";
import API from "../../api";
import CardSelector from "../../components/CardSelector";
import tracker from "@/mixins/tracker";

export default {
  name: "Library",
  components: {CardSelector},
  mixins: [ mixins, tracker ],

  data(){
    return {
      busy: false,
      library: [],
      scrolled_long_down: false,
    };
  },

  computed: {
    selected_library(){
      return this.library.filter(item => this.store.wiz.library_items.indexOf( item.id ) >= 0 );
    },
  },

  methods: {

    change(item){
      if( item.selected ){
        this.store.wiz.library_items.push(item.id);
      }else{
        const idx = this.store.wiz.library_items.indexOf(item.id);
        if( idx >= 0 ){
          this.store.wiz.library_items.splice(idx, 1);
        }
      }
    },

    next(){

      this.busy = true;

      API.createClass(this.store.wiz)
          .then(class_info => {
            // Force reload location.
            window.onhashchange = () => window.location.reload();
            //class_info = jQuery.parseJSON(class_info);
            window.location.href = `/class/${class_info.id}`;
          })
          .catch(err => {
            this.busy = false;
            console.log(err);
          });
    },

    scrollDetect(){
      const top = jQuery(this.$refs.library).offset().top
      const scroll = jQuery(window).scrollTop();
      if( scroll + 150 > top + 50 ){
        this.scrolled_long_down = true;
      }else if( scroll + 150 < top - 50 ){
        this.scrolled_long_down = false;
      }
    },

  },

  beforeMount() {
    window.scrollTo(0,0);
    this.setPageTitle( 'Create New Class - Content Library' );
    this.tracker.visit(this.$route);
  },

  mounted() {

    if( !this.store.wiz.podcast ) return this.$router.push({name: 'podcast'});

    this.meta.title = 'Content Library';
    this.store.progress.value = 4;

    API.getLibrary( this.store.wiz.course )
        .then(library => this.library = library);

    this.scrollDetect();
    window.addEventListener('scroll', this.scrollDetect);

  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollDetect);
  }

}
</script>

<style lang="scss" scoped>

.selected-content{
  background: rgba(black, 0.1);
  border: rgba(black, 0.1) solid 1px;
  padding: 1rem;
  border-radius: 1rem;
  margin: 3rem 0;
  min-height: 300px;

  &.is-empty{
    display: flex;
    align-items: center;
    justify-content: center;

    .no-items{
      display: flex;
    }
  }
}

.no-items{
  color: #acacac;
  align-items: center;
  justify-content: center;
  display: none;

  .inner{
    text-align: center;
    max-width: 600px;
  }
}

.done{
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 3;
}

</style>