<template>
  <div class="grant-success">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 mb-3">
          <h1 class="h2">
            Download RISE Certificate
          </h1>

          <p class="mb-5">
            Give the name to imprint in the certificate or just download directly.
          </p>

          <div class="form-group mt-3 mb-4 pb-3">
            <label for="name">Certified For (optional)</label>
            <input type="text"
                   id="name"
                   class="form-control"
                   placeholder="Name"
                   v-model="name">
          </div>

          <div class="text-center">
            <a :href="download_url"
               target="_blank"
               class="btn btn-secondary btn-lg mt-3 mb-4">
              <i class="fas fa-download mr-1"></i> Download Certificate
            </a>
          </div>

        </div>
        <div class="col-md-6 mb-3">
          <div class="d-none d-md-block">
            <img class="img-fluid rounded border shadow-lg"
                 src="/files/Certificate_RISE_Signed.jpg"
                 alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import tracker from "@/mixins/tracker";
import mixins from "@/mixins";

export default {
  name: "RiseCertificate",
  mixins: [mixins,tracker],

  data(){
    return {
      name: '',
    }
  },

  computed: {
    download_url(){
      return API.getRiseCertificateUrl(this.name);
    },
  },

  async mounted() {
    this.setPageTitle( 'RISE Certificate' );
    this.tracker.visit(this.$route);
  }

}
</script>

<style lang="scss" scoped>
.container {
  // max-width: 00px !important;
  margin: 1rem auto;
}
</style>