<template>
  <div class="grant-step-intro">

    <div class="head">
      <div class="container" style="max-width: 800px">
        <h1 class="h3">Share your Impact, Story and Experience</h1>
      </div>
    </div>

    <div class="container" style="max-width: 800px">
      <div class="form-group">
        <label for="impact">What was the impact your class created with the Design for Change project?</label>
        <textarea class="form-control" id="impact" rows="1" v-model="values.impact"></textarea>
        <field-error-message :errors="errors" field="impact"/>
      </div>

      <div class="form-group">
        <label for="impact_count">How many people were impacted by your project?</label>
        <input type="number"
               class="form-control"
               id="impact_count" min="0"
               v-model="values.impact_count"
               placeholder="# of people"/>
        <field-error-message :errors="errors" field="impact_count"/>
      </div>

      <div class="form-group">
        <label for="additional_stories">
          What are any additional stories about your students' or your experience  with DFC that you
          would like to share? (please include any quotes and testimonials you may have)
        </label>
        <textarea class="form-control" id="additional_stories" rows="1" v-model="values.additional_stories"></textarea>
        <field-error-message :errors="errors" field="additional_stories"/>
      </div>

      <div class="form-group">
        <label>
          How likely are you to recommend us to others?
        </label>
        <star-rating :min="0" :max="10" v-model="values.rating"></star-rating>
        <field-error-message :errors="errors" field="rating"/>
      </div>

<!--      <div class="form-group">-->
<!--        <label for="surprise">What most surprised you about the Design for Change experience?</label>-->
<!--        <textarea class="form-control" id="surprise" rows="1" v-model="values.surprise"></textarea>-->
<!--        <field-error-message :errors="errors" field="surprise"/>-->
<!--      </div>-->

      <div class="form-group">
        <label for="feedback">
          Please share any extra feedback you'd like us to know so we can improve
          the program in the future (optional)
        </label>
        <textarea class="form-control" id="feedback" rows="1" v-model="values.feedback"></textarea>
        <field-error-message :errors="errors" field="feedback"/>
      </div>
    </div>

  </div>
</template>

<script>
import StarRating from "@/components/StarRating";
import FieldErrorMessage from "@/components/FieldErrorMessage";
import tracker from "@/mixins/tracker";
export default {
  name: "GrantStepStory",
  components: {FieldErrorMessage, StarRating},
  mixins: [tracker],

  props: {
    values: {
      type: Object,
      default() {
        return {};
      }
    },
    errors: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  mounted() {
    this.setPageTitle( 'Grant Submission - Share your Impact, Story & Experience' );
    this.tracker.visit(this.$route);
  }

}
</script>

<style scoped>
.form-group {
  margin-bottom: 2rem;
}
</style>