<template>

  <div class="recover-password my-5">
    <div class="container text-center">

      <img src="/img/img_logo_dfc.svg"
           class="logo d-block my-3 mx-auto"
           alt="Design for change">

      <h2 class="h4">Recover Password</h2>
      <p>Enter your email address to send password recovery instructions:</p>

      <form action="" @submit.prevent="recoverPassword()" class="mb-4">
        <div class="form-group">
          <input type="email"
                 :disabled="is_busy"
                 v-model="email"
                 class="form-control"
                 placeholder="Email Address">
        </div>
        <div class="form-group">
          <button type="submit"
                  :disabled="is_busy || !email_is_valid"
                  class="btn btn-primary btn-lg">Send Reset Instruction</button>
        </div>
      </form>

      <div class="my-5">
        Don't have an account?
        <router-link :to="{name: 'auth.register'}">Sign-up here.</router-link>
      </div>

      <div class="my-5 small">
        © 2021 Design for Change USA
      </div>

    </div>
  </div>

</template>

<script>
import API from '../../api';
import tracker from "@/mixins/tracker";

export default {
  name: "RecoverPassword",
  mixins: [tracker],

  data(){
    return {
      is_busy: false,
      email: '',
    }
  },

  computed: {
    email_is_valid(){
      return /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
          .test(this.email);
    },
  },

  methods: {
    recoverPassword(){
      this.is_busy = true;
      API
          .sendPasswordRecoveryEmail(this.email)
          .then(() => {
            this.$swal({
              title: 'Email Sent',
              text: 'Check your email for instructions on how to reset your password.',
              icon: 'success',
            }).then( () => window.location.hash = `/auth` );
        });
    },
  },

  mounted() {
    this.setPageTitle( 'Recover Password' );
    this.tracker.visit(this.$route);
  }

}
</script>

<style lang="scss" scoped>
.container{
  max-width: 600px !important;
}
.logo{
  max-width: 200px;
}
</style>