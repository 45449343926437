import router from '../router/index';

export class SidebarMenuItem {
    constructor(id, projects, menu_states) {

        this.id = id;
        this.project = projects.reduce((acc, val) => id === val.id ? val : acc, {});
        this.menu = menu_states.reduce((acc, val) => id === val.id ? val : acc, { id, is_active: false, });

        this.sub_items = [
            {
                id: 'details',
                title: 'Settings',
                icon: '/img/ico_share.png',
                action: {name: 'class.settings', params: {id}},
                is_active: () => router.app.$route.name === 'class.settings',
            },
            {
                id: 'warmup',
                title: 'Warmup',
                icon: '/img/ico_getstarted.png',
                action: {name: 'class.stage', params: {id, stage: 'warmup'}},
                is_active: () => router.app.$route.params.stage === 'warmup',
            },
            {
                id: 'feel',
                title: 'Feel',
                icon: '/img/feel_arrow.png',
                action: {name: 'class.stage', params: {id, stage: 'feel'}},
                is_active: () => router.app.$route.params.stage === 'feel',
            },
            {
                id: 'imagine',
                title: 'Imagine',
                icon: '/img/imagine_arrow.png',
                action: {name: 'class.stage', params: {id, stage: 'imagine'}},
                is_active: () => router.app.$route.params.stage === 'imagine',
            },
            {
                id: 'do',
                title: 'Do',
                icon: '/img/do_arrow.png',
                action: {name: 'class.stage', params: {id, stage: 'do'}},
                is_active: () => router.app.$route.params.stage === 'do',
            },
            {
                id: 'share',
                title: 'Share',
                icon: '/img/share_arrow.png',
                action: {name: 'class.stage', params: {id, stage: 'share'}},
                is_active: () => router.app.$route.params.stage === 'share',
            },
        ]

    }
}

export default SidebarMenuItem;