<template>
  <span class="text-danger">
    {{ message }}
  </span>
</template>

<script>
export default {
  name: "FieldErrorMessage",
  props: {
    errors: {
      type: Array,
      default(){ return []; }
    },
    field: {
      type: String,
      default: ''
    }
  },

  computed: {
    message(){
      return this.errors.reduce((acc, val) => val.field === this.field ? val.message : acc, '');
    }
  },

}
</script>

<style scoped>

</style>