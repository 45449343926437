<template>
  <div class="error">
    <div class="container py-4">
      <div class="row align-items-center justify-content-center">
        <div class="col-sm-4 col-lg-3 text-center my-1 my-md-4 order-sm-last">
          <h6 class="mb-1">ERROR <span class="text-muted">/</span> 404</h6>
          <h1 class="h4">PAGE NOT FOUND</h1>
          <hr>
          <p>
            Ooops.. Looks like you are lost.
            Please <a href="#" @click="$router.go(-1)">go back</a> or try some links on this page.
          </p>
        </div>
        <div class="col-sm-5 col-lg-4 text-center my-1 my-md-4 order-sm-first">
          <img src="https://img.freepik.com/free-vector/lost-concept-illustration_114360-1096.jpg?size=420&ext=jpg"
               class="img-fluid"
               alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tracker from "@/mixins/tracker";

export default {
  name: "Error404",
  mixins: [tracker],

  mounted() {
    this.setPageTitle( 'ERROR 404 - Page not found' );
    this.tracker.visit(this.$route);
  }
}
</script>

<style scoped>

</style>