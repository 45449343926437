<template>
    <div class="users-list">
        <title-bar title="Edit User"
               class=""
               background="/dfcusa-web/img/img_hedr_teachers.png"/>

        <dash-section title="User Editing"
                description="Edit information of the user."
                class="mb-5"
                :busy="is_busy">

            <div class="row mt-3">
                <div class="col-md-7">
                    <div class="form-group">
                        <div class="row">
                            <label class="col-sm-4 col-form-label" for="first-name">First Name</label>
                            <div class="col-sm-8">
                                <input type="text"
                                    class="form-control"
                                    id="first-name"
                                    v-model="item.first_name"
                                    placeholder="First Name "/>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="row">
                            <label class="col-sm-4 col-form-label" for="last-name">Last Name</label>
                            <div class="col-sm-8">
                                <input type="text"
                                    class="form-control"
                                    id="last-name"
                                    v-model="item.last_name"
                                    placeholder="Last Name "/>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="row">
                            <label class="col-sm-4 col-form-label" for="e-mail">E-mail</label>
                            <div class="col-sm-8">
                                <input type="text"
                                    class="form-control"
                                    id="e-mail"
                                    v-model="item.email"
                                    placeholder="E-mail"/>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="row">
                            <label class="col-sm-4 col-form-label" for="location">Location</label>
                            <div class="col-sm-8">
                                <input type="text"
                                    class="form-control"
                                    id="location"
                                    v-model="item.location"
                                    placeholder="Location"/>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="row">
                            <label class="col-sm-4 col-form-label" for="state">State</label>
                            <div class="col-sm-8">
                                <input type="text"
                                    class="form-control"
                                    id="state"
                                    v-model="item.state"
                                    placeholder="State"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <label class="col-sm-4 col-form-label" for="status">Status</label>
                            <div class="col-sm-8">
                                <select v-model="item.status" class="form-control">
                                    
                                    <option value="active" :selected="item.status == 'active' ? 'selected' : ''">Active</option>
                                    <option value="inactive" :selected="item.status == 'active' ? 'selected' : ''">Inactive</option>
                                </select>
                      
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="row">
                            <label class="col-sm-4 col-form-label" for="type">Type</label>
                            <div class="col-sm-8">
                                <select v-model="item.type" class="form-control">
                                    
                                    <option value="admin" :selected="item.type == 'admin' ? 'selected' : ''">Admin</option>
                                    <option value="mentor" :selected="item.status == 'mentor' ? 'selected' : ''">Mentor</option>
                                </select>
                      
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </dash-section>
    </div>

</template>


<script>
import TitleBar from "@/components/TitleBar";
import DashSection from "@/components/DashSection";
import API from "@/api";
import mixins from "@/mixins";
import tracker from "@/mixins/tracker";

export default {
  name: "UsersList",
  components: {TitleBar, DashSection},
  mixins: [mixins, tracker],

  props: {
    id: {
      type: Number,
      default: 0
    },
  },

  data() {
    return {
      is_busy: false,
      item: {
        first_name: '',
        last_name: '',
        user_type: '',
        mail: '',
        location: '',
        state: '',
        status: '',
        type: ''
      },
    };
  },


  watch: {
    item:{
      deep: true,
      handler(val){

        if( this.is_busy ) return;
        if( !this.id ) return;
        
        API.saveUserDataDebounced(this.id, val)
            .then(() => {

              this.$notify({
                group: 'projects',
                title: 'Saved',
                type: 'success',
                text: 'The user data automatically saved.'
              });

            }).catch(err => {
                
              if( err.statusText !== 'abort' ){

                this.$notify({
                  group: 'projects',
                  title: 'Error',
                  type: 'error',
                  text: 'Failed to save user data'
                });
              }
            });
      }
    }
  },

  async mounted() {
    this.is_busy = true;
    if( this.id ){
      try{
        this.item = await API.getUserById(this.id);
      }catch (e) {
          console.log( e );
        // DO some stuffs.
      }
    }

    this.is_busy = false;
    this.$forceUpdate();

    this.setPageTitle(this.id ? 'Edit Teacher' : 'Add Teacher');
    this.tracker.visit(this.$route);

  },

};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  margin: 0 -10px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  > * {
    padding: 0 10px;
    margin-bottom: 1rem;
  }
}


</style>