const validators = {
    is_email(val){
        const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return regex.test( val );
    },
    is_empty(val){
        return val === null || val.length === 0;
    },
    is_not_empty(val){
        return !validators.is_empty(val);
    },
    is_between(val, min, max){
        return val >= min && val <= max;
    },
    is_not_between(val, min, max){
        return !validators.is_between(val, min, max);
    }
}

export default validators;