<template>
  <div class="file-uploader">
    <input type="file"
           class="d-none"
           ref="file"
           @change="upload($event)"
           :id="id"/>

    <div class="progress my-1" v-if="is_busy">
      <div class="progress-bar" :style="`width: ${progress.totalPercent}%;`"></div>
    </div>

    <button v-else
            type="button"
            class="btn btn-danger"
            @click.prevent="$refs.file.click()"
            v-html="label"></button>
  </div>
</template>

<script>
import API from "@/api";

export default {
  name: "FileUploader",
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'UPLOAD',
    },
    id: {
      type: String,
      default: 'inp-' + Math.random(),
    },
  },


  data(){
    return {
      url: '',
      is_busy: false,
      progress: {
        totalBytes: 0,
        totalPercent: 0,
      },
    }
  },

  watch: {
    value(val){
      this.url = val;
    }
  },

  methods: {
    async upload(ev){
      if( !ev.target.files.length ) return;

      try{

        this.is_busy = true;

        const res = await API.uploadFile(ev.target.files[0], (progress) => {
          console.log( progress );
          this.progress = progress;
          this.$emit('progress', progress);
        });

        this.url = res.url;
        this.$emit('input', this.url);

        // {
        //   container: undefined
        //   filename: "01-Register.png"
        //   handle: "cDGknOpATZGIypFFFRLf"
        //   key: undefined
        //   mimetype: "image/png"
        //   name: "01-Register.png"
        //   size: 38290
        //   status: "Stored"
        //   type: "image/png"
        //   uploadTags: undefined
        //   url: "https://cdn.filestackcontent.com/cDGknOpATZGIypFFFRLf"
        //   workflows: undefined
        //   _file: {
        //       "name": "01-Register.png",
        //       "size": 38290,
        //       "type": "image/png"
        //     }
        // }

      }catch (e) {
        console.log(e);
      }

      this.is_busy = false;

    }
  },

}
</script>

<style lang="scss" scoped>
.btn{
  padding: 0.8rem 1.6rem;
}
.progress{
  height: 0.3rem;
}
</style>