<template>
  <div class="video-recorder">
    <button class="btn-close btn btn-secondary"
            @click="cancel()">
      <i class="fas fa-times"></i>
    </button>
    <div class="recording-container">
      <div class="player-wrap">
        <div class="timer" :class="{active: timer !== false}">{{ timer > 0 ? timer : 'GO' }}</div>
        <video class="player" ref="player" autoplay playsinline></video>
      </div>
      <div class="controls-wrap">
        <div class="upload-progress" v-show="filestack.uploadPercent !== false">
          <div class="value" :style="'width: ' + filestack.uploadPercent + '%'"></div>
        </div>
        <div class="controls">
          <template v-if="recorderState === 'idle' && this.timer === false">
            <button @click="startRecording()"
                    class="btn btn-primary">
              <i class="fas fa-circle"></i>
              <span class="d-none d-md-inline-block ml-1">Start Recording</span>
            </button>
          </template>
          <template v-else-if="recorderState === 'recording'">
            <button @click="stopRecording()"
                    class="btn btn-success">
              <i class="fas fa-stop"></i> Done
            </button>
            <button @click="cancelRecording()"
                    class="btn btn-warning"><i class="fas fa-"></i>Cancel</button>
          </template>
          <template v-else-if="recorderState === 'recorded' && this.timer === false">
            <button @click="startRecording()"
                    :disabled="filestack.busy"
                    class="btn btn-primary">
              <i class="fas fa-circle"></i>
              <span class="d-none d-md-inline-block ml-1"> Re-Record</span>
            </button>
            <button @click="previewRecording()"
                    :disabled="filestack.busy"
                    class="btn btn-info">
              <i class="fas fa-play"></i>
              <span class="d-none d-md-inline-block ml-1"> Preview</span>
            </button>
            <button @click="upload()"
                    :disabled="filestack.busy"
                    class="btn btn-secondary">
              <i class="fas fa-check mr-1"></i>
              <span class="d-none d-md-inline-block">Upload</span>
            </button>
          </template>
        </div>
      </div>
    </div>

    <input type="file" ref="uploader" class="d-none">

  </div>
</template>

<script>
import mixins from "@/mixins";
import RecordRTCPromisesHandler from "recordrtc";
import API from "@/api";

export default {
  name: "VideoRecorder",
  mixins: [mixins],
  data(){
    return {

      showHelp: true,
      stream: null,
      recorder: null,
      recorderState: 'inactive',
      timer: false,

      filestack: {
        client: API.__initFileStackClient(),
        busy: false,
        uploadPercent: false,
      }
    };
  },

  computed: {
    player(){
      return this.$refs.player;
    },

  },

  methods: {

    mute(){
      this.player.muted = true;
      this.player.volume = 0;
    },

    unmute(){
      this.player.muted = false;
      this.player.volume = 1;
    },

    async startTimer(seconds){
      this.timer = seconds;
      return new Promise((resolve) => {
        const timer = setInterval(() => {
          if( this.timer-- <= 0 ) {
            resolve();
            this.timer = false;
            clearInterval(timer);
          }
        }, 1000);
      });
    },

    async startRecording() {

      this.mute();
      this.player.srcObject = this.stream;
      this.showHelp = false;

      await this.startTimer(3);

      const result = await this.recorder.startRecording();
      this.recorderState = 'recording';

      return result;
    },

    cancel(){
      this.stream.getTracks().forEach(track => track.stop());
      this.$emit('cancel');
    },

    async stopRecording() {

      this.showHelp = false;

      this.recorder.stopRecording(() => {
        this.unmute();
        this.player.src = this.player.srcObject = null;
        this.player.src = this.recorder.toURL();
        this.stream.getTracks().forEach(track => track.stop());
        this.recorderState = 'recorded';
      });
    },

    async cancelRecording() {

      this.showHelp = false;

      this.recorder.stopRecording(() => {
        this.mute();
        this.player.src = this.player.srcObject = null;
        this.player.srcObject = this.stream;
        this.recorderState = 'idle';
      });

    },

    async previewRecording() {
      this.unmute();
      this.player.currentTime = 0;
      this.player.play();
    },

    upload(){

      function getFileName(fileExtension) {
        const d = new Date();
        const year = d.getUTCFullYear();
        const month = d.getUTCMonth();
        const date = d.getUTCDate();
        return 'RecordRTC-' + year + month + date + '-grant-' + Math.floor((Math.random() * 10000) + 1) + fileExtension;
      }

      if( this.filestack.busy ) return ;
      this.filestack.busy = true;

      const fileName = getFileName('.webm');

      const blob = this.recorder.getBlob();
      const fileObject = new File([blob], fileName, {
          type: 'video/webm'
      });

      this.filestack.client.upload(fileObject, {
        onProgress: progress => this.filestack.uploadPercent = progress.totalPercent,
      }, {filename: fileName}).then(res => {
        this.filestack.uploadPercent = false;
        this.filestack.busy = false;
        this.uploadComplete(res);

      }).catch(err => {
        console.log('FILE ERROR: ', err);

        this.$swal(
            'Unable to upload the video. Please try again.',
            'Upload Failed!',
            'error'
        );

        this.filestack.busy = false;
        this.filestack.uploadPercent = false;

      });
    },

    async uploadComplete(file) {

      this.$emit('recorded', file);

      // const aParams = [];

      // aParams.push({
      //   name: this.step.student_field_name,
      //   value: file.url,
      // });
      
      // await this.updatestudentdata(this.student_id, aParams);
      // await this.next();
    }

  },

  async mounted() {

    if (!navigator.mediaDevices.getUserMedia) {
      // Video recording is not supported.
      return;
    }

    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
      echoCancellation:true,
    }).then((stream) => {

      this.mute();
      this.stream = stream;
      this.player.srcObject = stream;

      this.recorder = new RecordRTCPromisesHandler(this.stream, { type: 'video' });
      this.recorderState = 'idle';

    }).catch(() => null);

  },

}
</script>

<style lang="scss" scoped>
$primary: #ED1651;
$secondary: #0096DB;
$dark: #323232;
$light: #fafafa;

.page-content{
  position: relative;
}

.help{
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 10;

  .btn{
    box-shadow: rgba($secondary, 0.7) 0 0 10px;
    animation: btn-glow 1s alternate infinite;
  }
}

.instructions-box {
  position: absolute;
  margin-left: 16px;
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  color: $dark;
  font-size: 1.1rem;
  width: 70vw;
  max-width: 580px;
  border-radius: 0.6rem;
  box-shadow: rgba($dark, 0.3) 0 2px 15px;
  opacity: 0.75;
  z-index: 10;
  transition: opacity .3s;

  &:hover{
    opacity: 1;
  }

  &:before{
    content: '';
    position: absolute;
    right: 100%;
    top: 50%;
    width: 16px;
    height: 16px;
    background: $light;
    transform: translate(50%, -50%) rotate(45deg);
    z-index: 1;
  }

  .box-head{
    background: $secondary;
    color: $light;
    padding: 1rem 2rem;
    border-radius: 0.6rem 0.6rem 0 0;
  }

  .box-body{
    background: $light;
    color: $dark;
    padding: 0.4rem 2rem;
    border-radius: 0 0 0.6rem 0.6rem;
  }

  //&:after{
  //  content: '';
  //  position: absolute;
  //  width: 30px;
  //  height: 30px;
  //  background: $light;
  //  transform: rotate(45deg);
  //}

  h1{
    font-size: 2rem;
  }

  h5{
    font-size: 1.2rem;
  }

  ul{
    list-style: none;
    padding: 0;
    margin: 0.6rem 0 0;
    text-align: left;

    li{
      margin-bottom: 0.6rem;
      padding: 0 0 0 1.6rem;
      position: relative;

      .fas{
        position: absolute;
        left: 0;
        top: 0.3rem;
      }
    }
  }

  //
  //
  //h5 {
  //  font-size: 1.1em;
  //}
  //
  //li {
  //  font-size: 0.7em;
  //}
}

.recording-container{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: darken( $dark, 10% );
}

.player-wrap{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 80px;
  left: 0;

  .player{
    width: 100%;
    height: 100%;
    background: darken( $dark, 5% );
  }

}
.controls-wrap{
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: auto;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.controls{
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  > * {
    margin: 0 0.5rem;
  }

  .btn{
    color: white;
    font-size: 1.1rem;
    padding: 0.4rem 0.8rem;
  }
}

.upload-progress{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .value{
    border-top: $primary solid 2px;
  }
}

.timer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  font-size: 2rem;
  display: none;

  &.active{
    display: block;
    animation: zoom-fade 1s infinite;
  }
}

@keyframes btn-glow{
  from{
    box-shadow: rgba($secondary, 0.7) 0 0 3px 0px;
  }
  to{
    box-shadow: rgba($secondary, 0.9) 0 0 15px 3px;
  }
}

@keyframes zoom-fade {
  0%{
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }

  30%{
    opacity: 1;
  }

  70%{
    opacity: 1;
  }

  100%{
    opacity: 0;
    transform: translate(-50%, -50%) scale(5);
  }
}
.video-recorder{
  position: relative;
}
.btn-close{
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
