<template>
  <div class="grant-show">
    <div class="container">

      <div class="row align-items-stretch" v-if="submission">

        <div class="col-md-4 mb-3 text-light px-4 py-5 rounded-lg" :class="submission.status === 'submitted' ? 'bg-secondary' : 'bg-dark'">
          <div class="user">
            <div class="text-center">
              <i class="fas user-icon" :class="submission.status === 'submitted' ? 'fa-check-circle' : 'fa-clock'"></i>
              <div class="mb-3">{{ inflect.titleize( submission.status ) }}</div>


              <h1>{{ submission.first_name }} {{ submission.last_name }}</h1>
              <hr>
            </div>
            <ul class="list-unstyled details">
              <li>
                <i class="fas fa-envelope mr-1"></i>
                <a :href="`mailto:${submission.email}`"
                   target="_blank"
                   class="text-light">{{ submission.email }}</a>
              </li>
              <li><i class="fas fa-school mr-1"></i> {{ submission.school_name }}</li>
              <li><i class="fas fa-map-marker-alt mr-1"></i> {{ [submission.city, submission.state].join(', ') }}</li>
              <li>
                <i class="fas fa-calendar mr-1"></i>
                {{ (new Date(submission.updated_timestamp)).toDateString() }}
                -
                {{ (new Date(submission.updated_timestamp)).toLocaleTimeString() }}
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-8 mb-3">

          <div>
            <div class="alert alert-info mb-0">
              <h4 class="mb-0">Project Info</h4>
            </div>

            <dl>
              <dt>Theme Chosen</dt>
              <dd>{{ constants.themes[ details.theme ] }}</dd>

              <dt>What resources or processes did you use for this project?</dt>
              <dd>
                <ul class="list-unstyled">
                  <li v-for="process in details.processes" :key="process">
                    <i class="fas fa-check mr-1 text-success"></i> {{ process }}
                  </li>
                </ul>
              </dd>

              <dt>Grade Level</dt>
              <dd>
                {{ constants.grades[ details.grade_level ] }}
              </dd>

              <dt>Number of students</dt>
              <dd>
                {{ details.number_of_students }}
              </dd>

              <dt><img class="icon" src="/img/feel_arrow.png" alt=""> Feel Stage</dt>
              <dd>
                <p class="small mb-1 font-weight-bold">
                  Write your class' <em>How Might We</em> statement here. For example,
                  "how might we [do A] in a world where [B]?"
                </p>
                <p v-html="nl2br(details.feel)"></p>
              </dd>

              <dt><img class="icon" src="/img/imagine_arrow.png" alt=""> Imagine Stage</dt>
              <dd>
                <p class="small mb-1 font-weight-bold">
                  What was the idea your class decided to work on together?
                </p>
                <p v-html="nl2br(details.imagine)"></p>
              </dd>

              <dt><img class="icon" src="/img/do_arrow.png" alt=""> Do Stage</dt>
              <dd>
                <p class="small mb-1 font-weight-bold">
                  What did your students do to take action and create social change?
                  Describe their actions below:
                </p>
                <p v-html="nl2br(details.do.actions)"></p>


                <template v-if="details.do.files.length">
                  <strong class="d-block mb-2">Attachments</strong>
                  <div class="attachments row">
                    <div class="attachment col-6 col-md-3" v-for="file in details.do.files" :key="file.file.handle">
                      <a :href="`https://cdn.filestackcontent.com/${file.file.handle}`" target="_blank">
                        <img v-if="file.icon.preview" class="preview" :src="file.icon.preview" alt="">
                        <img v-else class="icon" :src="file.icon.icon" alt="">
                        <div class="caption">
                          {{ file.caption }}
                        </div>
                      </a>
                    </div>
                  </div>
                </template>
              </dd>


              <dt><img class="icon" src="/img/share_arrow.png" alt=""> Do Stage</dt>
              <dd>
                <p class="small mb-1 font-weight-bold">
                  What did your class learn from this experience?
                </p>
                <p v-html="nl2br(details.share.experience)"></p>

                <p class="small mb-1 font-weight-bold">
                  How did you and your class share this knowledge with others?
                </p>
                <p v-html="nl2br(details.share.knowledge)"></p>

                <template v-if="details.share.files.length">
                  <strong class="d-block mb-2">Attachments</strong>
                  <div class="attachments row">
                    <div class="attachment col-6 col-md-3" v-for="file in details.share.files" :key="file.file.handle">
                      <a :href="`https://cdn.filestackcontent.com/${file.file.handle}`" target="_blank">
                        <img v-if="file.icon.preview" class="preview" :src="file.icon.preview" alt="">
                        <img v-else class="icon" :src="file.icon.icon" alt="">
                        <div class="caption">
                          {{ file.caption }}
                        </div>
                      </a>
                    </div>
                  </div>
                </template>
              </dd>

            </dl>
          </div>


          <div>
            <div class="alert alert-info mb-0">
              <h4 class="mb-0">Share your Impact, Story and Experience</h4>
            </div>

            <dl>
              <dt>What was the impact your class created with the Design for Change project?</dt>
              <dd v-html="nl2br(details.impact)"></dd>

              <dt>How many people were impacted by your project?</dt>
              <dd>{{ details.impact_count }}</dd>

              <dt>
                What are any additional stories about your students' or your experience with
                DFC that you would like to share? (please include any quotes and
                testimonials you may have)
              </dt>
              <dd v-html="nl2br(details.additional_stories)"></dd>

              <dt>
                How likely are you to recommend us to others?
                <div class="small text-muted">0 being "Not at all likely" and 10 being "Extremely likely"</div>
              </dt>
              <dd>
                <template v-if="details.rating >= 0">
                  <i class="fas fa-star" v-for="r in details.rating" :key="r"></i>
                  <i class="far fa-star" v-for="r in (10 - details.rating)" :key="r"></i>
                  <strong class="font-weight-bold ml-2">[ {{ details.rating }}/10 ]</strong>
                </template>
                <template v-else>
                  RATING NOT GIVEN
                </template>
              </dd>

              <dt>
                Please share any extra feedback you'd like us to know so we can improve the program in the future (optional)
              </dt>
              <dd v-html="nl2br(details.feedback)"></dd>

            </dl>

          </div>

        </div>

      </div>

<!--      <pre>{{ details }}</pre>-->

    </div>
  </div>
</template>

<script>
import API from "@/api";
import mixins from "@/mixins";

export default {
  name: "GrantShow",
  mixins: [mixins],

  props: {
    id: {
      type: Number,
      default: 0,
    }
  },

  data(){
    return {
      is_busy: false,
      submission: null,

      constants: {
        themes: {
          climate: 'Climate Action',
          education: 'Educational Equity',
          justice: 'Racial Justice',
          other: 'Other',
        },
        grades: {
          elementary_school: 'Elementary Grade',
          middle_school: 'Middle School',
          high_school: 'High School',
        }
      }
    }
  },

  computed:{
    details(){
      if( typeof this.submission.data === 'undefined' || !this.submission.data ) return {};
      return this.submission.data;
    },

  },

  methods: {
    nl2br(text){
      return text ? text.split("\n").join("<br>") : '';
    }
  },

  async mounted() {
    if (!this.id) return this.$router.push({name: 'error.404'});
    this.is_busy = true;
    const submission = await API.getGrantSubmission(this.id);
    submission.data = JSON.parse( submission.data );
    submission.data.do.files.map(file => { file.icon = this.fileIcon( file.file ); return file; });
    submission.data.share.files.map(file => { file.icon = this.fileIcon( file.file ); return file; });

    this.submission = submission;
    this.is_busy = false;
  }

}
</script>

<style lang="scss" scoped>
.user-icon{
  font-size: 4rem;
}
.details li{
  margin-bottom: 0.6rem;
  .fas{
    text-align: center;
    width: 1.6rem;
  }
}

dl{

  padding-top: 1rem;

  dt{
    margin-bottom: 0.4rem;

    .icon{
      width: auto;
      height: 1.6rem;
      margin-right: 0.2rem;
      margin-top: -0.3rem;
    }
  }

  dd{
    border-bottom: rgba(black, 0.1) solid 1px;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    &:last-child{
      border-bottom: none;
    }
  }
}

.attachments{
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  .attachment{
    display: flex;

    a{
      display: block;
      text-decoration: none;
      border-radius: 0.3rem;
      overflow: hidden;
      padding: 0.4rem;

      &:hover{
        background: rgba(black, 0.1);
      }
    }

    img{
      display: block;
      max-width: 100%;
      max-height: 120px;
      margin: 0 auto 0.6rem;
      border-radius: 0.3rem;
    }
    .caption{
      text-align: center;
    }
  }
}

</style>