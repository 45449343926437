<template>
    <div v-if="show_header">
        <div class="try">
            <a href="https://pro.designforchange.us">Try our new platform!</a>
        </div>
        <iframe src="https://www.designforchange.us/header" width=100% frameborder=0 height=280 scrolling="no" id="headerframe"></iframe>
    </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "AppHeader",
  mixins: [mixins],

  methods: {
    logout(){
      // window.gapi.auth2.getAuthInstance().signOut();

      this.$cookies.remove('user');
      window.location = '/auth';
    },

    hideNewPlatformNotice(){
      this.$cookies.set('hide_new_platform_notice', true);
      this.$forceUpdate();
    }

  },

  computed: {

    loggedin() {
      return this.$cookies.get('user');
    },

    show_new_platform_notice(){
      if( this.loggedin ){
        return !this.$cookies.get('hide_new_platform_notice');
      }
      return false;
    },
    show_header() {
      if (this.$route.query.header == 'false') {
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-expand-lg .navbar-nav .nav-item.short-margin {
  margin-right: 0;
}
.link{
    color: white;
    text-decoration: underline;
}
.try{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 100;

    a{
        display: block;
        color: black;
        text-decoration: none;
        background: #F9C80E;
        padding: 0.3rem 1rem;
        text-align: center;
        font-weight: bold;
    }
}
</style>