<template>
  <div class="grant-step-project">

    <div class="head">
      <div class="container">
        <h1 class="h3">Project Info</h1>
      </div>
    </div>

    <div class="container">
      <div class="form-group" v-if="my_projects.length">
        <label for="project_id">
          Select a project to autofill the form.
        </label>
        <select id="project_id"
                v-model="values.project"
                @change="projectChanged($event)"
                class="form-control">
          <option :value="null">-- None --</option>
          <option v-for="project in my_projects"
                  :key="project.id"
                  :value="project">{{ project.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="theme">
          Theme Chosen <sup>*</sup>
        </label>
        <select id="theme"
                v-model="values.theme"
                class="form-control">
          <option value="">-- Select Theme --</option>
          <option value="climate">Climate Action</option>
          <option value="education">Educational Equity</option>
          <option value="justice">Racial Justice</option>
          <option value="other">Other</option>
        </select>
        <field-error-message :errors="errors" field="theme"/>
      </div>

      <div class="form-group">
        <label for="theme">
          What resources or processes did you use for this project? <sup>*</sup>
        </label>
        <div v-for="item in processes"
             :key="item">
          <b-checkbox :value="item" v-model="values.processes">{{ item }}</b-checkbox>
        </div>
        <field-error-message :errors="errors" field="processes"/>
      </div>

      <div class="form-group">
        <label for="grade_level">Grade Level <sup>*</sup></label>
        <select id="grade_level"
                class="form-control"
                v-model="values.grade_level">
          <option value="">-- Select Grade --</option>
          <option value="elementary_school">Elementary Grade</option>
          <option value="middle_school">Middle School</option>
          <option value="high_school">High School</option>
        </select>
        <field-error-message :errors="errors" field="grade_level"/>
      </div>

      <div class="form-group">
        <label for="number_of_students">Number of students <sup>*</sup></label>
        <input type="number"
               id="number_of_students"
               class="form-control"
               min="1"
               v-model="values.number_of_students">
        <field-error-message :errors="errors" field="number_of_students"/>
      </div>

      <div class="row align-items-stretch">
        <div class="col-md-6 mb-3">
          <div class="card d-flex h-100">
            <div class="card-body">
              <label for="feel" class="h5">
                <img class="icon" src="/img/feel_arrow.png" alt="">
                Feel Stage
              </label>
              <p class="small">
                Write your class' <em>How Might We</em> statement here.
                For example, "how might we [do A] in a world where [B]?"
              </p>
              <textarea id="feel"
                        class="form-control"
                        rows="1"
                        placeholder="How might we ___________ in a world where _________?"
                        v-model="values.feel"></textarea>
              <field-error-message :errors="errors" field="feel"/>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="card d-flex h-100">
            <div class="card-body">
              <label for="imagine" class="h5">
                <img class="icon" src="/img/imagine_arrow.png" alt="">
                Imagine Stage
              </label>
              <p class="small">
                What was the idea your class decided to work on together?
              </p>
              <textarea id="imagine"
                        class="form-control"
                        rows="1"
                        placeholder="Type your class' idea here."
                        v-model="values.imagine"></textarea>
              <field-error-message :errors="errors" field="imagine"/>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3 ">
          <div class="card d-flex h-100">
            <div class="card-body">
              <label class="h5" for="do_actions">
                <img class="icon" src="/img/do_arrow.png" alt="">
                Do Stage
              </label>
              <p class="small mt-4">
                What did your students do to take action and create social change?
                Describe their actions below:
              </p>
              <textarea id="do_actions"
                        class="form-control"
                        rows="1"
                        placeholder="Describe the actions taken by your students."
                        v-model="values.do.actions"></textarea>
              <field-error-message :errors="errors" field="do.actions"/>
              <p class="small mt-4">
                OPTIONAL:  We also encourage you to upload photos, videos, or files that
                document your journey, whether they're photos your students shared or a
                copy of the lesson plans you created!
              </p>
              <media-manager :files="values.do.files"></media-manager>
              <field-error-message :errors="errors" field="do.files"/>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="card d-flex h-100">
            <div class="card-body">
              <label class="h5" for="share_experience">
                <img class="icon" src="/img/share_arrow.png" alt="">
                Share Stage
              </label>
              <p class="small mt-4">
                What did your class learn from this experience?
              </p>
              <textarea id="share_experience"
                        class="form-control"
                        rows="1"
                        placeholder="What did your class learn from this experience?"
                        v-model="values.share.experience"></textarea>
              <field-error-message :errors="errors" field="share.experience"/>
              <p class="small mt-4">
                How did you and your class share this knowledge with others?
              </p>
              <textarea id="share_knowledge"
                        class="form-control"
                        rows="1"
                        placeholder="How did you and your class share this knowledge with others?"
                        v-model="values.share.knowledge"></textarea>
              <field-error-message :errors="errors" field="share.knowledge"/>
              <p class="small mt-4">
                OPTIONAL: Upload any files of how your class shared their learning
                experience or record yourself sharing your story
              </p>
              <media-manager class="mt-4"
                             :files="values.share.files"
                             :recorder_enabled="true"></media-manager>
              <field-error-message :errors="errors" field="share.files"/>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import API from "@/api";
import MediaManager from "@/components/MediaManager";
import FieldErrorMessage from "@/components/FieldErrorMessage";
import mixins from "@/mixins";
import tracker from "@/mixins/tracker";

export default {
  name: "GrantStepProject",
  components: {FieldErrorMessage, MediaManager},
  mixins: [mixins, tracker],
  props: {
    values: {
      type: Object,
      default() {
        return {};
      }
    },
    errors: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      is_busy: false,
      my_projects: [],
      students: [],
      responses: {},
      processes: ['Do Good From Home', 'Design Sprints', 'Podcast Episode', 'DFC Online Platform', 'Other'],
    }
  },

  computed: {
    project(){
      return this.values.project;
    }
  },

  methods: {

    async projectChanged() {

      console.log({projectChanged: this.project});

      this.values.theme = '';
      this.values.grade_level = '';
      this.values.number_of_students = '';
      this.values.feel = '';
      this.values.imagine = '';
      this.values.do.files = [];
      this.values.share.files = [];
      this.students = [];

      if (this.project) {

        const cd = this.project.class_data;

        this.students = await API.getStudentsData(this.project.id);
        this.values.theme = this.project.course;
        this.values.grade_level = this.project.grade_level;
        this.values.number_of_students = this.students.length;

        if (cd.feel_howmightwe_start && cd.feel_howmightwe_end) {
          this.values.feel = `How might we ${cd.feel_howmightwe_start} in a world where ${cd.feel_howmightwe_end}`;
        }

        if (cd.imagine_classidea) {
          this.values.imagine = cd.imagine_classidea;
        }

        this.values.do.files = this.students.reduce((acc, student) => {
          try{
            return acc.concat( student.student_data.do_gallery.map(file => ({
              file,
              caption: file.filename,
              selected: true
            })) );
          }catch (e){/**/}
          return acc;
        }, this.values.do.files);

        console.log(
          this.students
            .filter(student => student.student_data && student.student_data.share_video && student.student_data.share_video.length).length
        );

        this.values.share.files = (
          await Promise.all(
            this.students
              .filter(student => student.student_data && student.student_data.share_video && student.student_data.share_video.length)
              .map(student => API.uploadedFileInfo(student.student_data.share_video))
          )
        ).map(file => ({
          file,
          caption: file.filename,
          selected: true
        }));

      }

    },

  },

  async mounted() {

    console.log({selectedProject: this.values.project});

    this.is_busy = true;

    try {
      this.my_projects = await API.getClasses();
    } catch (e) {/**/
    }

    this.is_busy = false;


    this.setPageTitle( 'Grant Submission - Project Info' );
    this.tracker.visit(this.$route);

  }

}
</script>

<style scoped>
.form-group {
  margin-bottom: 2rem;
}
.icon{
  display: inline-block;
  width: 1.8rem;
  height: auto;
  margin-right: 0.3rem;
  vertical-align: middle;
}
</style>