<template>
  <div class="new-class-wizard">
    <section class="hero with-background-image with-title"
             style="background-image: url('/img/img_hedr_teachers.png');">
      <div class="hero-container">
        <h4 class="heading text-uppercase font-weight-bold" id="page-title">{{ meta.title }}</h4>
      </div>
    </section>

    <div class="container">
      <progress-bar class="steps-progress" :progress="store.progress"></progress-bar>
    </div>

    <div class="step">
      <transition>
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import ProgressBar from "../../components/ProgressBar";
import mixins from "../../mixins";

export default {
  name: "NewClassWizard",
  components: {ProgressBar},
  mixins: [ mixins ],
}
</script>

<style lang="scss" scoped>
.hero{
  padding: 1rem 15px;
}
.steps-progress{
  position: relative;
  z-index: 1;
  transform: translateY(-50%);
}

</style>

<style lang="scss">
#app{
  .container{
    max-width: 1200px;
  }
}

button{
  &:disabled{
    cursor: not-allowed;
    opacity: 0.3;
    background-color: #9a9a9a !important;
    color: rgba(white, 0.5) !important;
    border-color: #9a9a9a !important;
  }
}

.form-control{
  border-radius: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;

  &::placeholder{
    color: #9299a0;
  }
}

.form-control-xl{
  height: 4.3rem;
  font-size: 1.3rem;
  border-radius: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.text-block{
  font-size: 0.9rem;
  color: #586571;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 680px;
}

.bottom-actions{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;

  > * {
    margin: 0.4rem 0.6rem;
  }
}
</style>