<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22.982" height="22.982" viewBox="0 0 22.982 22.982"><path class="a" d="M5.3,7.6H3V23.684a2.3,2.3,0,0,0,2.3,2.3H21.386v-2.3H5.3ZM23.684,3H9.895A2.3,2.3,0,0,0,7.6,5.3V19.088a2.3,2.3,0,0,0,2.3,2.3H23.684a2.3,2.3,0,0,0,2.3-2.3V5.3A2.3,2.3,0,0,0,23.684,3ZM14.491,17.364V7.022l6.895,5.171Z" transform="translate(-3 -3)"/></svg>
</template>

<script>
export default {
  name: "IconVideo",
}
</script>
<style>
  .a{fill:currentColor}
</style>