<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="115.714" height="120" viewBox="0 0 115.714 120"><defs><filter id="a" x="0" y="0" width="115.714" height="120" filterUnits="userSpaceOnUse"><feOffset input="SourceAlpha"/><feGaussianBlur stdDeviation="15" result="b"/><feFlood flood-color="#007bff"/><feComposite operator="in" in2="b"/><feComposite in="SourceGraphic"/></filter></defs><g class="b" transform="matrix(1, 0, 0, 1, 0, 0)"><path class="a" d="M9,37.5h8.571V7.5H9Zm17.143-30v30h8.571V7.5Z" transform="translate(36 37.5)"/></g></svg>
</template>

<script>
export default {
  name: "IconPause",
}
</script>
<style>
  .a{fill:currentColor;}
  .b{filter:url(#a);}
</style>