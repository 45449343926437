<template>
  <div class="container">
    <p class="text-center mt-4 text-block">
    </p>
    <div class="row justify-content-center mt-5">
      <div class="col-7">
        <input class="form-control form-control-xl text-center"
               v-model="store.wiz.name"
               placeholder="Name of your class">
      </div>
    </div>
    <div class="row justify-content-center mt-2 mb-4">
      <div class="col-10">
        <div class="grades">
          <div v-for="grade in grades" :key="grade.level" class="grade my-3">
            <box-check :box="grade" @change="onChange"></box-check>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-actions">
      <button class="btn btn-lg btn-dark"
              @click="back">
        <i class="fas fa-caret-left mr-2"></i> BACK
      </button>
      <button class="btn btn-lg btn-secondary"
              @click="next"
              :disabled="!validate()">
        NEXT <i class="fas fa-caret-right ml-2"></i>
      </button>
    </div>
  </div>
</template>

<script>
import jQuery from "jquery";
import BoxCheck from "../../components/BoxCheck";
import mixins from "../../mixins";
import tracker from "@/mixins/tracker";

export default {
  name: "SelectTheme",
  components: {BoxCheck},
  mixins: [ mixins, tracker ],

  data(){
    return {
      grades: [
        {level: 'elementary_school', title: 'Elementary Grade', checked: false, image: 'https://www.readingrockets.org/sites/default/files/field/image/teaching-writing.jpg' },
        {level: 'middle_school', title: 'Middle Grade', checked: false, image: 'https://nafme.org/wp-content/uploads/2020/03/smiling-middle-school-students-credit-monkeybusinessimages.jpg' },
        {level: 'high_school', title: 'High School', checked: false, image: 'https://blog.connectionsacademy.com/wp-content/uploads/Getting-Ready-for-College-Checklist.png' },
      ],
    };
  },

  computed: {
    class_name(){
      try {
        return this.store.wiz.name;
      }catch (e) {
        return '';
      }
    }
  },

  methods: {

    onChange(grade){
      this.grades.forEach((t) => {
        if( t.level !== grade.level ) t.checked = false;
      });
      this.store.wiz.grade_level = grade.checked ? grade.level : '';
    },

    validate(){
      try{
        return jQuery.trim(this.class_name).length > 0 && this.store.wiz.grade_level;
      }catch (e) {
        return false;
      }
    },

    next(){
      this.$router.push({ name: 'new-class.students' });
    }

  },

  watch: {
    class_name(val){
      this.meta.title = jQuery.trim(val).length > 0 ? val : 'Your Class';
    }
  },

  beforeMount() {
    this.setPageTitle( 'Create New Class - Your Class' );
    this.tracker.visit(this.$route);
  },

  mounted() {

    if( !this.store.wiz.course ) return this.$router.push({name: 'new-class.theme'});

    this.meta.title = 'Your Class';
    this.store.progress.value = 1;

    const grade = this.grades.reduce( (acc, val) => this.store.wiz.grade_level === val.level ? val : acc, null );
    if( grade ) grade.checked = true;

  },
}
</script>

<style scoped>
.grades{
  display: flex;
  margin-top: 3rem;
  justify-content: center;
  align-items: center;
}
.grade{
  flex: 1 1 0px;
  max-width: 260px;
  padding: 0 1rem;
}
</style>