<template>
  <div class="class-stage">
    <div class="class-settings">
      <title-bar :title="$route.params.stage ? inflect.titleize(stage) : ''"
                 layout="project"
                 :project="active_project"/>

<!-- Featured Podcast -->
    <dash-section v-if="$route.params.stage == 'warmup'" title="Featured Podcast"
                  description="Below are the featured podcasts for the theme. Select one podcast
                               for your students to listen to during the Empathy Warmup that
                               will focus their experience in the platform."
                  class="mb-5"
                  :busy="podcasts_busy"
                  :action="{
                    title: podcasts_selector_enabled
                            ? '<i class=\'fas fa-check mr-1\'></i> Done'
                            : '<i class=\'fas fa-sync-alt mr-1\'></i> Change',
                    click: actionChangeFeaturedPodcast
                  }">

      <div v-if="podcasts_selector_enabled" class="podcast-selector">
        <div class="podcasts row align-items-stretch" v-if="!podcasts_busy">
          <div v-for="podcast in podcasts" :key="podcast.id"
               class="podcast mb-4" :class="podcast.priority > 0 ? 'col-md-12' : 'col-md-6'">

<!--            <card-selector :card="{-->
<!--              id: podcast.id,-->
<!--              title: podcast.name,-->
<!--              description: podcast.description,-->
<!--              image: podcast.picture,-->
<!--              duration: podcast.duration,-->
<!--              difficulty: podcast.difficulty,-->
<!--              selected: active_project.podcast === podcast.short_name,-->
<!--              short_name: podcast.short_name,-->
<!--              preview: '/podcast/' + podcast.short_name,-->
<!--            }" @change="podcastSelected( podcast )" type="checkbox" :selectable="true" :deselectable="false" />-->

            <podcast-list-item :podcast="podcast"
                               :featured="podcast.priority > 0"
                               :selectable="true"
                               :newtab="true"
                               @selected="podcastSelected( podcast )" />

          </div>
        </div>
      </div>
      <div v-else class="selected-podcast">

        <podcast-list-item :podcast="active_project.podcast" :newtab="true"/>

<!--        <card-selector :card="{-->
<!--              id: active_project.podcast.id,-->
<!--              title: active_project.podcast.name,-->
<!--              description: active_project.podcast.description,-->
<!--              image: active_project.podcast.picture,-->
<!--              duration: active_project.podcast.duration,-->
<!--              difficulty: active_project.podcast.difficulty,-->
<!--              short_name: active_project.podcast.short_name,-->
<!--              preview: '/podcast/' + active_project.podcast.short_name,-->
<!--            }" :selectable="false" :deselectable="false" layout="horizontal"></card-selector>-->
      </div>

    </dash-section>
    <!-- Featured Podcast Ends -->

    <!-- Content Library -->
    <dash-section v-if="$route.params.stage == 'warmup'" title="Content Library"
                  description="Below are curated articles and videos on the theme. Based on your
                               students' readiness level, select one or more for your students to
                               access to provide additional information on the selected theme."
                  class="mb-5"
                  :busy="library_busy"
                  :action="{
                    title: library_selector_enabled
                            ? '<i class=\'fas fa-check mr-1\'></i> Done'
                            : '<i class=\'fas fa-sync-alt mr-1\'></i> Change',
                    click: actionChangeContentLibrary
                  }">

      <div v-if="library_selector_enabled" class="library-selector">
        <div class="row align-items-stretch">
          <div v-for="item in library" :key="item.id"
               class="col-sm-6 col-lg-4 mb-4">
            <card-selector :card="{
              id: item.id,
              title: item.title,
              description: item.description,
              image: libraryImageUrl(item),
              type: item.type,
              difficulty: item.level,
              selected: active_project.library_items && active_project.library_items.indexOf(item.id) >= 0,
              preview: item.url,
            }" @change="libraryChanged" type="checkbox" :selectable="true" :deselectable="true" />
          </div>
          <div class="create-library-item col-sm-6 col-lg-4 mb-4">
            <div class="card h-100">
              <div class="card-body text-center rounded-lg h-100 d-flex align-items-center">
                <div>
                  <img class="img-fluid rounded-lg mb-4"
                       src="https://via.placeholder.com/350x100/f0f0f0/ccc?text=..."
                       alt="">
                  <p class="mb-3">Want to add your own library item?</p>
                  <router-link :to="{name: 'my-library.create'}" class="btn btn-danger">
                    <i class="fas fa-plus mr-1"></i> Create New Item
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="library-selected">
        <div class="row">
          <div v-for="item in selected_library"
               :key="item.id"
               class="col-sm-6 col-lg-4 mb-4">
            <card-selector :card="{
              id: item.id,
              title: item.title,
              image: libraryImageUrl(item),
              type: item.type,
              difficulty: item.level,
              selected: false,
              preview: item.url,
            }" type="checkbox" :selectable="false" :deselectable="false" />
          </div>
        </div>
      </div>

    </dash-section>
    <!-- Content Library Ends -->


      <dash-section
          title="Student Responses"
          :busy="response_busy"
          :description="stage_options.hint">

        <template v-slot:action>
          <div class="filters-wrap">
            <div class="btn-group">
              <button class="btn btn-danger text-uppercase px-2"
                      @click="toggleShowAll()"
                      type="button">
                <i class="fas fa-eye"></i> Show {{ show_all ? 'Default' : 'All' }}
              </button>
              <button class="btn btn-danger"
                      @click="show_filters = true"
                      type="button">
                <i class="fas fa-angle-down"></i>
              </button>
            </div>
            <div class="filters" v-if="show_filters">
              <a  v-for="field in stage_options.fields" :key="field.name"
                  href="#" @click="filter(field.name)">{{ field.title }}</a>
            </div>
          </div>
        </template>



        <div class="row">
          <div class="col-sm-7">
            <div v-for="response in responses" :key="response.id"
                 class="response">
              <div class="card mb-3">
                <div class="card-header d-flex align-items-center justify-content-between">
                  <h3 class="title flex-grow-1">
                    {{ response.first_name }} {{ response.last_initial }}.
                  </h3>
                  <div class="action">
                    <a :href="screenUrl(response.id)"
                       target="_blank"
                       class="btn btn-light"><i class="fas fa-laptop mr-1"></i> View Screen</a>
                  </div>
                </div>
                <div class="card-body">
                  <div v-for="field in stage_options.fields" :key="field.name">
                    <div v-if="!stage_options.current_field || stage_options.current_field === field.name || response.__all_opened"
                         class="response-line">
                      <h6 class="res-title">{{ field.title }}</h6>
                      <p class="res-content">
                        {{
                          Array.isArray(response[ field.name ])
                              ? response[ field.name ].join(', ')
                              : response[ field.name ]
                        }}
                      </p>
                    </div>
                  </div>
                  <a href="#"
                     class="btn btn-ellipsis"
                     @click.prevent="response.__all_opened = !response.__all_opened">
                    <i class="fas fa-ellipsis-h"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="deliverable" v-if="stage_options.deliverable">
              <div class="card mb-3">
                <div class="card-header">
                  <h5 class="mb-0 text-center">Class Deliverable</h5>
                </div>
                <div class="card-body text-center">
                  <p class="description">{{ stage_options.deliverable.hint }}</p>
                  <div v-if="has_deliverable" class="mt-3">
                    <div class="field" v-for="field in stage_options.deliverable.fields" :key="field.name">
                      <div class="font-weight-bold">{{ field.title }}</div>
                      <div v-if="typeof class_data[ field.name ] === 'string'">{{ class_data[ field.name ] }}</div>
                      <div v-if="Array.isArray(class_data[ field.name ])">
                        {{ class_data[ field.name ].reduce((acc, val) => val.selected ? val.content : acc, '') }}
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <a :href="deliverableUrl(stage_options.deliverable.step)"
                       target="_blank"
                       class="btn btn-success text-white">
                      <i class="fas fa-history mr-1"></i> Review {{ !has_deliverable ? '& Decide' : '' }}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="stage === 'share'" class="card mb-3 text-center">
              <div class="card-header">
                Share your experience by <strong>May 31st</strong> for a chance
                to win a <strong class="text-secondary">$500 award</strong>!
              </div>
              <div class="card-body">
                <router-link :to="{name: 'grant.home'}" class="btn btn-secondary">
                  Submit your project <i class="fas fa-angle-right ml-1"></i>
                </router-link>
              </div>
            </div>

          </div>
        </div>

      </dash-section>
    </div>
  </div>
</template>

<script>
import mixins from "../mixins";
import all_stage_options from "../helpers/all_stage_options";
import TitleBar from "../components/TitleBar";
import CardSelector from "../components/CardSelector";
import DashSection from "../components/DashSection";
import PodcastListItem from "@/components/PodcastListItem";
import API from "../api";
import tracker from "@/mixins/tracker";

export default {
  name: "ClassStage",
  components: {DashSection, TitleBar, CardSelector, PodcastListItem},
  mixins: [mixins, tracker],
  props: ['stage'],

  data(){
    return {
      all_stage_options,

      class_data: {},

      response_busy: false,
      responses: [],
      show_all: false,
      show_filters: false,
      podcasts: [],
      podcasts_selector_enabled: false,
      podcasts_busy: false,
      podcast_selected_temp: null,
      library: [],
      library_selector_enabled: false,
      library_busy: false,
      library_selected_temp: [],
    };
  },

  computed: {
    stage_options(){
      return all_stage_options[ this.stage ];
    },

    has_deliverable(){

      try{
        for ( let i = 0; i < this.stage_options.deliverable.fields.length; i++ ){
          if( this.stage_options.deliverable.fields[i].name.length === 0 ) return false;
        }
      }catch (e){
        return false;
      }

      return true;
    },

    selected_library(){
      if(!this.active_project.library_items) return [];
      var libs = [];
      var i;
      for (i = 0; i < this.active_project.library_items.length; i++) {
        var j;
        for (j = 0; j < this.library.length; j++) {
          if (this.library[j].id == this.active_project.library_items[i]) {
            libs.push(this.library[j]);
          }
        }
      }
      return libs;
    },
  },

  watch: {
    stage(){
      this.refresh();
    }
  },

  methods: {
    async actionChangeFeaturedPodcast() {

      if( this.podcasts_selector_enabled ){
        this.podcasts_selector_enabled = false;
        return;
      }

      this.podcasts_busy = true;
      try {
        this.podcasts = await API.getPodcasts();
        this.podcasts_selector_enabled = true;
      } catch (e) {/** */}
      this.podcasts_busy = false;
    },

    podcastSelected(podcast){
      this.active_project.podcast = podcast;
      this.podcasts_selector_enabled = false;
    },

    screenUrl(student_id){
      return `${process.env.VUE_APP_APP_BASE}/step/${this.active_project.id}/${this.stage_options.screen_id}?student=${student_id}`
    },

    deliverableUrl(step){
      return `${process.env.VUE_APP_APP_BASE}/step/${this.active_project.id}/${step}?teacher=yes`;
    },

    async refresh(){
      this.response_busy = true;

      this.class_data = (await API.getClass(this.active_project.id)).class_data;
      this.responses = (await API.getResponses(this.active_project.id, this.stage)).map(response => {
        response.__all_opened = false;
        return response;
      });

      this.response_busy = false;

      this.setPageTitle( this.inflect.titleize(this.stage)  + ' - ' + this.active_project.name );
      this.tracker.visit(this.$route);

    },

    filter(field_name){
      this.showDefault();
      this.stage_options.current_field = field_name;
      this.show_filters = false;
    },

    showDefault(){
      // Show Default
      this.show_all = false;
      this.responses.map(res => res.__all_opened = false);
      this.stage_options.current_field = this.stage_options.default_field;
    },

    showAll(){
      // Show All
      this.show_all = true;
      this.responses.map(res => res.__all_opened = true);
    },

    toggleShowAll(){
      this.show_all ? this.showDefault() : this.showAll();
    },

    async actionChangeContentLibrary() {
      this.library_selector_enabled = !this.library_selector_enabled;
    },


    libraryChanged(library){
      const idx = this.active_project.library_items.indexOf(library.id);
      if( library.selected ){
        if( idx < 0 ){
          this.active_project.library_items.push( library.id );
        }
      }else{
        if( idx >= 0 ){
          this.active_project.library_items.splice( idx, 1 );
        }
      }
    },
  },

  async mounted() {
    await this.refresh();
    this.library_busy = true;
    this.library = await API.getLibrary(this.active_project.course);
    this.library_busy = false;
  }

}
</script>

<style lang="scss" scoped>
.card{
  border-radius: 1rem;
}
.card-header{
  background: #fafafa;
  border-radius: 1rem 1rem 0 0;
}
.card-body{
  background: #fafafa;
  border-radius: 0 0 1rem 1rem;
}
.res-title{
  color: #999999;
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
}
.res-content{
  color: black;
  font-size: 1.3rem;
  font-style: normal;
  margin-bottom: 1.2rem;
}
.btn-ellipsis{
  background: white;
  border: black solid 1px;
  border-radius: 1rem;
  display: inline-block;
  line-height: 0.8;
  padding: 0rem 0.6rem;
}

.filters-wrap{
  position: relative;
  z-index: 1;
}

.filters{
  position: absolute;
  top: 100%;
  transform: translateY(10px);
  right: 0;
  background: white;
  padding: 1rem 0;
  border-radius: 1rem;
  box-shadow: rgba(black, 0.1) 0 3px 6px;

  &:before{
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border: transparent solid 6px;
    border-bottom-color: white;
    top: -12px;
    right: 20px;
  }

  a{
    display: block;
    white-space: nowrap;
    color: black;
    text-decoration: none;
    padding: 0.6rem 1.2rem;
    border-bottom: #eaeaea solid 1px;

    &:hover{
      background: red;
      color: white;
    }

    &:first-child{
      border-top: #eaeaea solid 1px;
    }
  }
}

.deliverable{
  font-size: 1.2rem;

  .field{
    padding: 0.6rem 0;
    border-bottom: #f0f0f0 solid 1px;

    &:last-child{
      border-bottom: none;
    }
  }
}
</style>