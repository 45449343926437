<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="121.5" height="110.531" viewBox="0 0 121.5 110.531"><defs><filter id="a" x="0" y="0" width="121.5" height="110.531" filterUnits="userSpaceOnUse"><feOffset input="SourceAlpha"/><feGaussianBlur stdDeviation="15" result="b"/><feFlood flood-color="#007bff"/><feComposite operator="in" in2="b"/><feComposite in="SourceGraphic"/></filter></defs><g class="b" transform="matrix(1, 0, 0, 1, 0, 0)"><path class="a" d="M33.75,18,18.45,7.734V28.266ZM2.25,7.734V28.266L17.55,18Z" transform="translate(78.75 73.27) rotate(180)"/></g></svg>
</template>

<script>
export default {
  name: "IconBackward",
}
</script>
<style>
  .a{fill:currentColor;}
  .b{filter:url(#a);}
</style>