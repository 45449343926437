<template>
  <button ref="button" :data-clipboard-text="content">
    <slot v-if="status === 'success'" name="success"></slot>
    <slot v-else></slot>
  </button>
</template>

<script>

import ClipboardJS from "clipboard";

export default {
  name: "CopyButton",
  props: ['content'],

  data(){
    return {
      status: '',
      clipboard: undefined,
    };
  },

  mounted() {
    this.clipboard = new ClipboardJS( this.$refs.button );
    this.clipboard.on('success', () => {
      this.status = 'success';
      setTimeout(() => this.status = '', 1500);
    })
  },

  beforeDestroy() {
    this.clipboard.destroy();
  }

}
</script>

<style scoped>

</style>