<template>
  <div id="app">

    <template v-if="maintenance">

      <div class="container text-center my-5">
        <img src="/img/img_logo_dfc.svg"
             class="logo mb-3"
             style="max-width: 180px"
             alt="Design for change">

        <h1>Under Maintenance</h1>
        <p>We are updating out system, we'll be back soon</p>
      </div>

    </template>
    <template v-else>

      <app-header v-if="!embed.is_embed"></app-header>

      <div v-if="routeNameIs(['error.*', 'podcast.*', 'profile.*', 'grant.*', 'rise.*'])">
        <!-- General View -->
        <router-view/>
      </div>

      <div v-else-if="routeNameIs('auth.*')" class="headless">
        <!-- Headless View -->
        <router-view/>
      </div>

      <div v-else-if="routeNameIs('new-class.*')">
        <!-- New Class Wizard View -->
        <new-class-wizard/>
      </div>

      <div v-else class="container">
        <!-- Other Admin View -->
        <div class="manager" v-if="!busy">
          <div class="sidebar-wrap"
               :class="{'show-menu': show_menu}"
               @click="show_menu = false">
            <sidebar/>
          </div>
          <div class="manager-page">
            <router-view></router-view>
          </div>
        </div>
      </div>

      <notifications group="projects" position="bottom right" />

      <app-footer v-if="!embed.is_embed"></app-footer>

    </template>

  </div>
</template>
<script>
import NewClassWizard from "./views/new-class/NewClassWizard";
// import ProgressBar from "./components/ProgressBar";
import mixins from "./mixins";
import Sidebar from "./components/Sidebar";
import API from "./api";
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";

export default {
  name: "App",
  components: {AppFooter, AppHeader, NewClassWizard/*, ProgressBar*/, Sidebar},
  mixins: [ mixins ],

  data(){
    return {
      busy: true,
      show_menu: false,
      maintenance: false,
    }
  },

  watch: {

    active_project_id(project_id){
      this.store.active_project = this.projects.reduce( (acc, val) => val.id === project_id ? val : acc, undefined);
    },

    active_project: {
      deep: true,
      handler( now, old ){
        // old is undefined, when the project is loaded for the first time.
        if( now && old ){

          API.saveClassDebounced({
            id: now.id,
            bookmark_step: now.bookmark_step,
            bookmark_step_label: now.bookmark_step_label,
            class_data: now.class_data,
            code: now.code,
            completed_steps: now.completed_steps,
            course: now.course,
            grade_level: now.grade_level,
            created: now.created,
            last_updated: now.last_updated,
            library_items: now.library_items,
            name: now.name,
            num_students: now.num_students,
            organization_id: now.organization_id,
            picture: now.picture,
            podcast: now.podcast ? now.podcast.short_name : '',
            skip_step: now.skip_step,
            user_id: now.user_id,
          }, 1000)
              .then(() => {
                this.$notify({
                  group: 'projects',
                  title: 'Saved',
                  type: 'success',
                  text: 'The class is automatically saved.'
                });
              }).catch(err => {
            if( err.statusText !== 'abort' ){

              console.log( err );

              this.$notify({
                group: 'projects',
                title: 'Error',
                type: 'error',
                text: 'Failed to save the class'
              });
            }
          });
        }
      },
    }
  },

  beforeMount() {
    this.checkAutoDownload();
  },

  async mounted() {
    this.projects = (await API.getClasses()).map(project => {
      project.library_items = project.library_items ? project.library_items : [];
      return project;
    });
    this.busy = false;

    this.$root.$on('menu', () => {
      this.show_menu = true;
    });
  }

}
</script>
<style lang="scss">
footer.footer{
  margin: 0 !important;
}
#app{
  .content-area{
    padding: 0 0 0 30px;

    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  .form-control{
    border-radius: 30px;
    border: #666666 solid 1px;
    height: 3.6rem;
    padding: 1.3rem 1.5rem;
    box-shadow: rgba(black, 0.1) 0 3px 6px;
  }

  textarea.form-control{
    height: auto;
  }

  select.form-control{
    padding: 1rem 1.5rem;
  }

  .col-form-label{
    padding-top: calc(.975rem + 1px);
    padding-bottom: calc(.975rem + 1px);
  }

  .form-group{
    label{
      //text-transform: uppercase;
      font-weight: 600;
    }
  }

  .input-validation{
    position: relative;
    .form-control{
      padding-right: 48px;
    }

    &-icon{
      position: absolute;
      width: 48px;
      font-size: 24px;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}
</style>
<style lang="scss" scoped>
.manager{
  display: flex;
  align-items: stretch;
}
.container{
  max-width: 1200px;
}
.sidebar-wrap{
  flex: 0 0 240px;
  width: 240px;
  background: #fafafa;
  border: #f0f0f0 solid 1px;
  border-bottom: none;
  padding-bottom: 80px;

  @media screen and (max-width: 767px) {
    display: none;
    &.show-menu{
      display: block;
    }
  }

}
.manager-page{
  flex: 1 1 auto;
  padding-bottom: 80px;
}


@media screen and (max-width: 767px) {
  .manager{
    position: relative;
  }

  .sidebar-wrap{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    width: auto;
    z-index: 1000;
  }
}

.headless{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 10;
  overflow: auto;
}

</style>