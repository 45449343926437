<template>
  <div class="card-selector h-100" :class="layout">
    <input ref="input" :type="type" v-model="card.selected" @change="changed">
    <div class="card h-100 d-flex">
      <div class="card-header">
        <div class="icon" v-if="card.icon">
          <img :src="`${card.icon}`" alt="">
        </div>
        <img :src="card.image" alt="">
        <div class="select" v-if="card.selected && (!deselectable || (selectable && deselectable))">
          <div class="icon">
            <i class="fas fa-check"></i>
          </div>
        </div>
        <div class="actions">
          <template v-if="!card.selected || (card.selected && deselectable)">
            <a :href="card.preview"
               class="btn btn-lg btn-light font-weight-normal"
               target="_blank"><i class="fas fa-caret-right mr-1"></i> PREVIEW</a>
            <a href="#"
               v-if="selectable || deselectable"
               class="btn btn-lg font-weight-normal"
               :class="{'btn-light': !card.selected, 'btn-dark': card.selected}"
               @click.prevent.stop="select">
              <i class="fas fa-check-circle mr-1"></i>
              {{ card.selected ? (labelDeselect || 'DESELECT') : (labelSelect || 'SELECT') }}
            </a>
            <router-link :to="card.edit"
                         v-if="editable"
                         class="btn btn-lg btn-light font-weight-normal">
              <i class="fas fa-edit mr-1"></i> Edit
            </router-link>
          </template>
        </div>
      </div>
      <div class="card-body-wrap h-100">
        <div class="card-body flex-grow-1">
          <h6 v-if="card.title">{{ card.title }}</h6>
          <p v-if="card.description">{{ card.description }}</p>
        </div>
        <div class="card-footer">
          <div class="option" v-if="card.duration">
            <i class="fas fa-clock text-secondary"></i> {{ card.duration }}
          </div>
          <div class="option" v-if="card.type">
            <i class="fas text-secondary mr-1" :class="{
            'fa-file': card.type === 'article',
            'fa-video': card.type === 'video',
          }"></i> {{ card.type }}
          </div>
          <div class="option" v-if="card.difficulty">
            <i class="fas fa-cog text-secondary mr-1"></i> {{ card.difficulty }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardSelector",

  props: {
    layout: {
      type: String,
      default: 'vertical',
    },
    card: {
      type: Object,
      default(){
        return {
          title: '',
          description: '',
          image: '',
          duration: '',
          difficulty: '',
          selected: false,
          preview: '#',
          icon: '',
          edit: null,
        }
      }
    },
    type: {
      type: String,
      default: 'checkbox',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    deselectable: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'small',
    },
    labelSelect: {
      type: String,
      default: 'SELECT',
    },
    labelDeselect: {
      type: String,
      default: 'DESELECT',
    },
  },

  methods: {

    changed(){
      this.$emit('change', this.card);
    },

    select(){
      const input = this.$refs.input;
      input.checked = !input.checked
      input.dispatchEvent(new Event('change'));
    }
  },
}
</script>

<style lang="scss" scoped>
$active-color: #76dd68;

input{
  display: none;
}

.card-selector{
  display: block;

  .card{
    flex-direction: column;
  }

  &.horizontal{
    @media screen and (min-width: 768px) {
      .card{
        flex-direction: row;

        .card-header{
          flex: 0 0 50%;
        }

        img{
          height: 100%;
        }
      }
    }
  }
}
.card{
  border-radius: 1rem;
  border: rgba(black, 0.1) solid 1px;
  box-shadow: rgba(black, 0.05) 0 3px 6px;

  &:hover{
    .actions{
      opacity: 1;
    }
  }
}

.select{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(#2fde32, 0.8);
  border-radius: 1rem;

  .icon{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2.2rem;
    height: 2.2rem;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $active-color;
    color: white;
    border: white solid 2px;
    box-shadow: rgba(black, 0.25) 0 0 10px;
    border-radius: 50%;
  }
}

.card-body-wrap{
  display: flex;
  flex-direction: column;

  .card-body{
    flex-grow: 1;
  }
}

.card-header{
  padding: 0;
  border-radius: 1rem;
  position: relative;

  .icon{
    position: absolute;
    top: 5px;
    left: 5px;
    height: 70px;
    width: 70px;

    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .actions{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(#146bff, 0.8);
    opacity: 0;
    transition: opacity .3s;
    border-radius: 1rem;

    > * {
      margin: 0.4rem 0.6rem;
    }
  }
}

.card-footer{
  display: flex;
  align-items: stretch;
  padding: 0;
  text-transform: uppercase;
  background: none;
  font-size: 0.9rem;

  > * {
    border-right: rgba(black, 0.1) solid 1px;
    padding: 0.8rem 0.4rem;
    text-align: center;
    flex-grow: 1;

    &:last-child{
      border-right: none;
    }
  }
}

img{
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 1rem;
}

</style>