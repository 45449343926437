import jQuery from "jquery";
import mixpanel from "mixpanel-browser";

/**
 * Check if mixpanel tracker is enabled in environment.
 * @returns {boolean}
 */
function mixpanel_enabled(){
    return ( process.env.VUE_APP_MIXPANEL_ENABLED === 'yes' || process.env.VUE_APP_MIXPANEL_ENABLED === 'true' ) && process.env.VUE_APP_MIXPANEL_API_KEY.length > 0;
}


if( mixpanel_enabled() ){
    /**
     * Initialize mixpanel & ignore DNT settings of browser.
     */
    mixpanel.init(process.env.VUE_APP_MIXPANEL_API_KEY, {
        ignore_dnt: true,
    });

    /**
     * Identify current user if user is already logged in.
     */
    if( window.user && window.user.id ){
        mixpanel.identify(window.user.id);
    }

}


/**
 * The Tracker Instance / Object.
 * @type {{attach(*=, *=, *=, *=): Promise<minimist.Opts.unknown>, track(*=, *=, *=): Promise<minimist.Opts.unknown>}}
 */
export const tracker = {


    /**
     * Send a tracking event.
     *
     * @param event
     * @param props
     * @param delay
     * @returns {Promise<unknown>}
     */
    async track(event, props = () => null, delay = 0){
        if( mixpanel_enabled() ){

            return new Promise((resolve) => {

                /**
                 * Action delayed to make sure all things like page title and
                 * page rendering are done correctly.
                 */

                setTimeout( () => {
                    const props_obj = jQuery.extend({}, typeof props === 'function' ? props() : props);
                    mixpanel.track(event, props_obj, null, resolve);
                }, delay);
            });
        }
    },


    /**
     * Attach tracking for an event over some elements selected
     * by `dom_selector`
     *
     * @param dom_event
     * @param dom_selector
     * @param event_name
     * @param props object or callable function.
     * @returns {Promise<unknown>}
     */
    async attach(dom_event, dom_selector, event_name = null, props = () => null){
        if( mixpanel_enabled() ){

            /**
             * The default event name is the same as dom-event if event_name is not set.
             */
            if( !event_name ) event_name = dom_event;

            return new Promise((resolve) => {
                jQuery(document).on(dom_event, dom_selector, function(ev){

                    console.log({clicked: ev});

                    const props_obj = jQuery.extend({}, typeof props === 'function' ? props(ev.currentTarget) : props);
                    mixpanel.track(event_name, props_obj, null, resolve);
                });

            });
        }
    },

};


tracker.visit = (route) => {
    return tracker.track('Visit', () => ({
        title: document.title,
        page_name: route.name,
        page_params: route.params,
    }), 1200);
};


/**
 * The vue vue object for usable as mixin to
 * other vue components.
 */
export default {

    computed: {
        tracker: () => tracker,
    },

};