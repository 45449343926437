<template>
  <div class="student-list">
    <table class="table" v-if="students.length">
      <thead>
      <tr>
        <th>Student</th>
        <th colspan="2">
          <div class="d-flex align-items-center justify-content-between">
            <div>Access Code</div>
            <div>
              <button v-if="open_students.length < students.length"
                      class="btn btn-secondary btn-sm ml-1"
                      type="button"
                      @click="removeAllAccessCodes">Enable Open Access</button>
              <button v-else
                      class="btn btn-success btn-sm ml-1 text-light"
                      type="button"
                      @click="generateAllAccessCodes">Disable Open Access</button>
            </div>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <template v-for="student in students">
        <tr :key="`details-${student.id}`" class="student-details">
          <td>{{ student.first_name }} {{ student.last_initial }}.</td>
          <td v-if="student.access_code">
            <span class="code text-monospace font-weight-bold">
              {{ student.access_code }}
              <a href="#"
                 class="text-danger"
                 title="Remove Access Code"
                 @click.prevent="removeAccessCode(student)"><i class="fas fa-times"></i></a>
            </span>
            <copy-button class="btn btn-light btn-sm"
                         :content="student.access_code">
              <i class="fas fa-clipboard mr-1"></i> Copy
              <template v-slot:success>
                <i class="fas fa-clipboard-check mr-1"></i> Copied
              </template>
            </copy-button>
          </td>
          <td v-else>
            <a href="#"
               class="btn btn-light"
               @click.prevent="generateAccessCode(student)"><i class="fas fa-lock mr-1"></i> Generate</a>
          </td>
          <td width="10" class="text-right">
            <a class="remove" href="#" @click.prevent="remove(student)"><i class="fas fa-times"></i></a>
          </td>
        </tr>
        <tr :key="`progress-${student.id}`" class="student-progress">
          <td colspan="3">
            <div class="progress">
              <div class="progress-bar bg-success"
                   :style="'width:' + progress(student) + '%'"></div>
            </div>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
    <div v-else>
      <p class="p-3 mb-0">No students found for this class.</p>
    </div>


    <b-modal id="modal-add-students">
      <template #modal-title>
        Add Students
      </template>
      <div class="d-block text-center">
        <add-student @change="new_students = $event"></add-student>
      </div>
      <template #modal-footer>
        <b-button class="mt-3" @click="saveNewStudents()">Add Students</b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import CopyButton from "./CopyButton";
import AddStudent from "../components/AddStudent";
import API from "../api";
import mixins from "../mixins";

export default {
  name: "StudentList",
  components: {AddStudent, CopyButton},
  mixins: [mixins],
  props: ['project', 'totalSteps'],

  data(){
    return {
      is_busy: false,
      students: [],
      new_students: [],
    };
  },

  computed: {
    open_students(){
      return this.students.filter(s => !s.access_code)
    }
  },

  methods: {

    actionAddStudent() {
      console.log( this.students );
      this.$bvModal.show('modal-add-students');
    },

    async saveNewStudents() {

      this.is_busy = true;

      this.$bvModal.hide('modal-add-students');

      await API.createStudents({
        id: this.active_project.id,
        students: this.new_students,
      });

      await this.refresh();

      this.is_busy = false;

    },

    async refresh(){
      const students = await API.getStudents(this.project.id);
      this.students = ( typeof students === 'string' ) ? JSON.parse( students ) : students;
    },

    async remove(student){
      this.$swal({
        title: 'Remove Student?',
        html: `
            Are you sure you want remove
            <strong class="text-danger">${student.first_name} ${student.last_initial}.</strong>
            from this class. This action can't be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, Remove.'
      }).then(({ isConfirmed }) => {
        if( isConfirmed ){
          API.removeStudent(this.project.id, student.id).then(() => {
            const idx = this.students.indexOf(student);
            if( idx >= 0 ){
              this.students.splice(idx, 1);
            }
          });
        }
      });
    },

    progress(student){
      return student.completed_steps ? Math.floor( student.completed_steps.length / this.totalSteps * 100 ) : 0;
    },


    removeAccessCode(student){
      this.$swal({
        title: 'Remove Access Code?',
        html: `Removing access code will give access to his/her account directly.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, Remove.'
      }).then(({ isConfirmed }) => {
        if( isConfirmed ){
          API.removeAccessCode(student.id).then(() => {
            student.access_code = null;
          });
        }
      });
    },

    removeAllAccessCodes(){
      this.$swal({
        title: 'Give open access to everyone?',
        html: `Removing access code will give access to that account directly.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(({ isConfirmed }) => {
        if( isConfirmed ){
          this.students.map((student) => {
            API.removeAccessCode(student.id).then(() => {
              student.access_code = null;
            });
          });
        }
      });
    },

    generateAccessCode(student){
      API.generateAccessCode(student.id).then((res) => {
        res = typeof res === 'string' ? JSON.parse(res) : res;
        student.access_code = res.access_code;
      });
    },

    generateAllAccessCodes(){
      this.$swal({
        title: 'Disable open access to everyone?',
        html: `Disabling open access will restrict everyone from accessing their account without a valid access code.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(({ isConfirmed }) => {
        if( isConfirmed ){
          this.open_students.map((student) => {
            API.generateAccessCode(student.id).then((res) => {
              res = typeof res === 'string' ? JSON.parse(res) : res;
              student.access_code = res.access_code;
            });
          });
        }
      });
    },

  },

  mounted() {
    this.refresh();
  }

}
</script>

<style lang="scss" scoped>
.student-list{
  border: #f0f0f0 solid 1px;
  border-radius: 1rem;
  background: #fafafa;
  box-shadow: rgba(black, 0.15) 0 3px 6px;
}

td, th{
  padding: 0.8rem 1.3rem 0.4rem;
}

.student-progress{
  td{
    border: none;
    padding-top: 0;
    padding-bottom: 0.8rem;
  }
}


thead{
  th{
    border-top: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.progress{
  height: 4px;
  border-radius: 4px;

  .progress-bar{
    border-radius: 4px;
    min-width: 1%;
  }
}

.remove{
  color: black;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover{
    background: red;
    color: white;
  }
}

.code{
  a{
    display: inline-flex;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    &:hover{
      background: rgba(black, 0.1);
    }
  }
}

</style>