<template>
  <div class="container">
    <p class="text-center mt-4 text-block">
    </p>

    <add-student :default-students="students" @change="studentsChanged($event)"></add-student>

    <div class="form-group text-center">
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="generate-access-code" v-model="store.wiz.generate_access_code">
        <label class="custom-control-label" for="generate-access-code">
          {{ store.wiz.generate_access_code ? 'Students must use access code to login' : 'No access code is required to login' }}
        </label>
      </div>
    </div>

    <div class="bottom-actions">
      <button class="btn btn-lg btn-dark"
              @click="back">
        <i class="fas fa-caret-left mr-2"></i>
        BACK
      </button>
      <button class="btn btn-lg"
              :class="students_are_added ? 'btn-secondary' : 'btn-warning'"
              @click="next">
        {{ students_are_added ? 'NEXT' : 'SKIP' }}
        <i class="fas fa-caret-right ml-2"></i>
      </button>
    </div>
  </div>
</template>

<script>
import mixins from "../../mixins";
import AddStudent from "@/components/AddStudent";
import tracker from "@/mixins/tracker";

export default {
  name: "Students",
  components: {AddStudent},
  mixins: [ mixins, tracker ],

  data(){
    return {

    };
  },

  computed: {

    students:{
      get(){
        return this.store.wiz.students;
      },
      set(students){
        this.store.wiz.students = students;
      }
    },

    students_are_added(){
      return this.students.length > 0;
    }
  },

  methods: {

    studentsChanged(students){
      this.students = students;
    },

    next(){
      this.$router.push({ name: 'new-class.podcast' });
    },

  },

  mounted() {

    if( !this.store.wiz.grade_level || !this.store.wiz.name ) return this.$router.push({name: 'new-class.class'});

    this.meta.title = 'Class Roster';
    this.store.progress.value = 2;

    this.setPageTitle( 'Create New Class - Class Roster' );
    this.tracker.visit(this.$route);

  },
}
</script>

<style lang="scss" scoped>
.students{
  max-width: 600px;
  margin: 2rem auto;
}
</style>