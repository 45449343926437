<template>
  <div class="star-rating">
    <div class="text-muted small mb-2">
      0 being "Not at all likely" and 10 being "Extremely likely"
    </div>
    <div class="stars-wrap flex-wrap align-items-center">
      <div class="stars d-flex">
        <div v-for="i in (max - min + 1)" :key="i" class="star">
          <a href="#"
             class="text-primary text-decoration-none"
             @click.prevent="change(i + min - 1)"
             :class="{active: (i + min - 1)  <= number}">
            <i class="fa-star" :class="(i + min - 1) <= number ? 'fas' : 'far'"></i>
            <span class="num">
            {{ i + min - 1 }}
          </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StarRating",
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 5,
    },
    value: {
      type: [Number],
      default: -1,
    },
  },

  data(){
    return {
      number: this.value,
    };
  },

  computed: {
    rating_text(){
      if( this.number <= 0 ) return '';
      if( this.number <= 2 ) return 'Not at all likely';
      if( this.number <= 4 ) return 'Not that good.';
      if( this.number <= 5 ) return 'Not good not bad.';
      if( this.number <= 8 ) return 'Awesome';
      return 'Extremely likely';
    }
  },

  methods: {
    change(val){
      this.number = val;
      this.$emit('input', val);
    },
  },

  // mounted() {
  //   this.number = this.value;
  // }

}
</script>

<style lang="scss" scoped>
.stars-wrap{
  display: flex;

  .star{
    display: block;
    max-width: 1.5rem;
    font-size: 1.2rem;
    text-align: center;
    margin: 0 0.1rem;

    a{
      display: block;
    }

    .num{
      font-size: 0.75em;
      margin-top: -.3rem;
    }
  }
}
</style>