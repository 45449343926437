<template>
  <div class="podcast-archive">
    <div class="container">
      <!-- {{ podcasts }} -->

      <section class="page-content mb-2">

        <div class="row align-items-center">

          <div class="mb-3" :class="featured_podcasts.length > 0 ? 'col-md-4 order-md-last' : 'col-md-5 mx-auto text-center'">
            <h2 class="heading-secondary">Get Going With Empathy Warmup Podcasts</h2>
            <p class="mb-5">
              If you are interested in building a unique experience for students, you can start by choosing from the library of social cause podcasts. Students build empathy around the selected topic by listening to the podcast, each of which is under 10 minutes.
              <br><br>Then, download and use <a target="_new" href="https://f.hubspotusercontent20.net/hubfs/6395720/Design%20for%20Change%20USA%20Toolkit/Design%20for%20Change%20USA%20-%20Empathy%20Warmup%20Toolkit%20-%202021-2022.pdf">our free Empathy Warmup Toolkit</a> that outlines activities to engage students in the Design for Change framework.
            </p>
          </div>

          <div class="col-md-8 mb-3 order-md-first" v-if="featured_podcasts.length > 0">
            <podcast-list-item :podcast="featured_podcasts[0]" :featured="true" />
          </div>

        </div>

        <!-- code>
          <pre class="bg-dark text-light p-3">{{ featured_podcast }}</pre>
        </code -->

      </section>

      

      <!-- Podcasts -->
      <div class="podcasts mb-4 row align-items-stretch" v-if="!podcasts_busy">
        <template v-for="podcast in non_featured_podcasts">
          <div :key="podcast.id"
               class="podcast col-lg-4 col-md-6 mb-5">
            <podcast-list-item :podcast="podcast" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";

// import CardSelector from "../../components/CardSelector";
import tracker from "@/mixins/tracker";
import mixins from "@/mixins";
import PodcastListItem from "@/components/PodcastListItem";
export default {
  name: "PodcastArchive",
  components: {PodcastListItem/*, CardSelector*/ },
  mixins: [tracker, mixins],

  data() {
    return {
      podcasts: [],
      podcasts_busy: false,
    };
  },


  computed: {
    featured_podcasts(){
      return this.podcasts.filter(p => p.priority > 0);
    },
    non_featured_podcasts(){
      if(this.podcasts.length > 0 ){
        if( this.podcasts[0].priority > 0 ){
          return this.podcasts.slice(1);
        }
        return this.podcasts;
      }
      return [];
    }
  },

  methods: {
    async refresh() {
      try {
        let podcasts = await API.getPodcasts();
        podcasts =
          typeof podcasts === "string" ? JSON.parse(podcasts) : podcasts;
        podcasts.map(item => this.podcasts.push(item));
        this.$forceUpdate();

        this.setPageTitle('Podcasts');
        this.tracker.visit(this.$route);

      } catch (e) {
        /**/
      }
    },
  },

  mounted() {
    this.refresh();

  },
};
</script>


<style lang="scss" scoped>
.container{
  max-width: 1420px !important;
}

.heading-secondary{
  font-size: 32px;
}

.podcast-archive .hero-section {
  position: relative;
  height: 150px;
  width: 100%;
  margin-bottom: 50px;
  background-size: cover;
  background-position: center;

  .heading {
    position: absolute;
    color: white;
    font-weight: 400;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin-bottom: 0;
    z-index: 1;
  }
}
</style>