<template>
  <div class="students">
    <div v-for="(student, index) in students" :key="index">
      <div class="inputs">
        <div class="input input-first">
          <input type="text" tabindex="0" class="form-control form-control-lg" v-model="student.first" placeholder="First Name">
        </div>
        <div class="input input-last">
          <input type="text"
                 tabindex="0"
                 class="form-control form-control-lg"
                 maxlength="1"
                 v-model="student.last"
                 placeholder="Last Initial">
        </div>
        <div class="action">
          <button href="#"
                  @click.prevent="remove(index)"
                  :disabled="index === 0 && students.length <= 1"
                  class="btn"
                  tabindex="1"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddSutdent",

  props: {
    defaultStudents: {
      type: Array,
      default(){
        return [
          { first: '', last: '' }
        ];
      }
    }
  },

  data(){
    return {
      students: [],
      students_added: [],
    }
  },

  computed: {
    students_filled(){
      return this.students.filter( s => s.first !== '' && s.last !== '' );
    },
  },

  watch: {
    students: {
      deep: true,
      handler(){
        if( this.students.filter( s => s.first === '' && s.last === '' ).length === 0 ){
          this.students.push({ first: '', last: '' });
        }
        this.students_added = this.students_filled.map( s => `${s.first} ${s.last}` );
        this.$emit('change', this.students_added);
      }
    }
  },

  methods: {
    remove(index){
      this.students.splice(index, 1);
      this.$forceUpdate();
    },
  },

  mounted() {
    this.students = this.defaultStudents;
  }

}
</script>

<style lang="scss" scoped>
.inputs{
  display: flex;
  position: relative;
  margin-bottom: 1rem;

  input{
    height: 3.4rem;
    padding: 1rem;
  }

  .input-first{
    flex: 1 1 auto;

    input{
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .input-last{
    flex: 0 0 40%;

    input{
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      padding-right: 66px;
    }
  }

  .action{
    position: absolute;
    top: 0;
    right: 0;
    width: 56px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn{
      display: flex;
      width: 40px;
      height: 40px;
      background: red;
      color: white;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
  }
}
</style>