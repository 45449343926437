<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <path class="a" d="M30 16.5h-18.26l8.38-8.38-2.12-2.12-12 12 12 12 2.12-2.12-8.38-8.38h18.26v-3z"/>
  </svg>
</template>
<script>
export default {
  name: "IconBack"
}
</script>
<style scoped>
  .a{fill: currentColor}
</style>