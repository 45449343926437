<template>
  <div class="media-manager">
    <div class="file-manager" v-if="show_manager">
      <div class="inner">
        <h3>Media Gallery</h3>
        <div class="files align-items-stretch">
          <div v-for="(file, idx) in files" :key="file.file.handle" class="file" :class="{selected: file.selected}">
            <a href="#" class="image" @click.prevent="file.selected = !file.selected">
              <div class="check">
                <span class="check-icon"><i class="fas fa-check"></i></span>
              </div>
              <img v-if="icons[idx].preview" :src="icons[idx].preview" class="preview" alt="">
              <img v-else :src="icons[idx].icon" class="icon" alt="">
            </a>
            <input class="form-control"
                   type="text"
                   v-model="file.caption"
                   placeholder="Caption">
          </div>
          <div class="file file-add d-flex">
            <a href="#"
               class="image w-100 h-100 d-flex flex-column text-primary text-decoration-none p-4"
               @click.prevent="upload()">
              <i class="fas fa-plus fa-2x"></i>
              <div class="my-2">
                Add files
              </div>
            </a>
          </div>
        </div>

        <div class="media-footer d-flex border-top pt-2">
          <button class="btn btn-lg btn-secondary mx-auto" @click="show_manager = false">Close</button>
        </div>

      </div>
    </div>

    <div class="video-recorder" v-if="show_recorder">
      Record
      <video-recorder @recorded="recorded($event)" @cancel="show_recorder = false"></video-recorder>
    </div>


    <div class="d-flex flex-column">
      <div class="btn-group">
        <button class="btn btn-success text-light" @click="show_manager = true">
          <i class="fas mr-1"
             :class="selected_files.length < 1 ? 'fa-upload' : (selected_files.length > 1 ? 'fa-copy' : 'fa-file')"></i>
          <template v-if="selected_files.length > 0">
            {{ selected_files.length }} file{{ selected_files.length > 1 ? 's' : '' }} selected
          </template>
          <template v-else>
            Upload or select &amp; files
          </template>
        </button>
        <button v-if="recorder_enabled" class="btn btn-secondary"
                @click="show_recorder = true">
          <i class="fas fa-circle mr-1"></i> Record Now
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import mixins from "@/mixins";
import API from "@/api";
import VideoRecorder from "@/components/VideoRecorder";
export default {
  name: "MediaManager",
  components: {VideoRecorder},
  mixins: [mixins],

  props: {
    files: {
      type: Array,
      default(){
        return [];
      }
    },
    recorder_enabled: {
      type: Boolean,
      default: false,
    },
  },

  data(){
    return {
      show_manager: false,
      show_recorder: false,
    }
  },

  computed: {
    icons(){
      return this.files.map(file => this.fileIcon(file.file));
    },
    selected_files(){
      return this.files.filter(file => file.selected);
    }
  },

  methods: {
    async upload() {
      const res = await API.uploadFilePicker({
        maxFiles: 50
      });
      res.filesUploaded.map(file => this.addFile(file));
      this.show_manager = true;
    },

    recorded(file){
      this.addFile(file);
      this.show_recorder = false;
      this.show_manager = true;
    },

    addFile(file){
      this.files.push({
        file,
        caption: file.filename,
        selected: true,
      });
    }
  }

}
</script>

<style lang="scss" scoped>
.file-manager{
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background: rgba(black, 0.5);
  padding: 10px;
  backdrop-filter: blur(3px);

  .inner{
    background: white;
    padding: 0.5rem 1rem;
    border-radius:0.4rem;

    h3{
      margin: 0.5rem 0 1rem;
    }
  }
}

.files{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;

  .file{
    width: 100%;
    flex: 0 0 100%;
    padding: 0 1rem;
    margin-bottom: 2rem;

    @media all and (min-width: 480px) {
      width: 50%;
      flex: 0 0 50%;
    }

    @media all and (min-width: 768px) {
      width: 33.33333%;
      flex: 0 0 33.33333%;
    }

    @media all and (min-width: 1200px) {
      width: 20%;
      flex: 0 0 20%;
    }

    &.selected{
      .image{
        img{
          opacity: 0.5;
        }
      }

      .check{
        background: #E94950;
        color: white;

        .check-icon{
          display: block;
        }
      }
    }

    .form-control{
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      border-radius: 0 0 0.4rem 0.4rem !important;
      border-color: #999 !important;
    }

  }
}

.file-add{
  .image{
    border-radius: 0.4rem;
  }
}

.image{
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: rgba(black, 0.2) solid 1px;
  position: relative;
  border-radius: 0.4rem 0.4rem 0 0;
  overflow: hidden;

  .check{
    position: absolute;
    z-index: 2;
    top: 0.5rem;
    left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 0.3rem;
    box-shadow: rgba(black, 0.1) 2px 3px;

    .check-icon{
      display: none;
    }
  }

  .preview{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .icon{
    width: 50%;
    height: 50%;
    object-fit: contain;
  }
}

.video-recorder{
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

</style>