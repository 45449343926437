<template>
  <div class="sidebar">
    <div class="menu">
      <div class="item" :class="{active: $route.name === 'class.dashboard'}" title="Dashboard">
        <router-link class="head" :to="{name: 'class.dashboard'}">
          <img src="/img/dashboard.svg" alt="" class="icon">
          <h3>Dashboard</h3>
        </router-link>
      </div>
      <div class="item"
           :class="{ active: routeNameIs('my-library.*') }"
           title="My Library">
        <router-link class="head" :to="{ name: 'my-library.index' }">
          <i class="fas fa-book icon"></i>
          <h3>My Library</h3>
        </router-link>
      </div>
<!--       <div class="item" 
        :class="{ active: routeNameIs('users.*') }"
        title="Users">
        <router-link class="head" :to="{name: 'users.index'}">
          <i class="fas icon fa-user-friends"></i>
          <h3>Teachers</h3>
        </router-link>
      </div> -->
      <h2 class="section-heading" v-if="items.length">Opened Projects</h2>
      <div v-else>
        <h2 class="section-heading">No Projects Opened</h2>
        <p class="px-3">
          Go to <a href="/">dashboard</a> to open existing class or
          <router-link :to="{name: 'new-class.theme'}">create a new class</router-link>.
        </p>
      </div>
      <div class="projects">
        <div v-for="item in items" :key="item.id"
             class="item"
             :class="{active: ['class.settings', 'class.stage'].indexOf( $route.name ) >= 0 && active_project.id === item.id}"
             :title="item.project.name">
          <router-link class="head" :to="{name: 'class.settings', params: {id: item.id}}">
            <img :src="projectIconUrl(item.project.course)" alt="" class="icon">
            <h3>{{ item.project.name }}</h3>
            <p>{{ item.project.grade_level ? inflect.titleize( item.project.grade_level ) : '' }}</p>
            <div class="close" @click.prevent.stop="closeProject(item.id)"><i class="fas fa-times"></i></div>
          </router-link>
          <div class="sub-items">
            <router-link v-for="sub in item.sub_items" :key="item.id + sub.id"
                         :to="sub.action"
                         :class="{active: sub.is_active()}"
                         class="sub-item">
              <img :src="sub.icon" alt="" class="icon">
              {{ sub.title }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "../mixins";
import ls from 'local-storage';
import SidebarMenuItem from "../helpers/SidebarMenuItem";

export default {
  name: "Sidebar",
  mixins: [mixins],
  props: {
    // sidebar: {
    //   type: Object,
    //   default(){
    //     return {
    //       list: [],
    //     }
    //   }
    // }
  },

  data(){
    return {
      menu_states: [],
    };
  },

  computed: {
    items(){
      return this.sidebar.list.map(id => new SidebarMenuItem(id, this.store.projects, this.menu_states));
    }
  },

  watch: {
    active_project(project){
      if( project && this.store.sidebar.list.indexOf(project.id) < 0 ){
          this.store.sidebar.list.push( project.id );
      }
    },

    sidebar:{
      deep: true,
      handler(sidebar, old){
        if( sidebar && old ){
          ls.set('sidebar', sidebar);
        }
      }
    }

  },

  methods: {
    toggle(item){
      this.items.map(it => it.menu.is_active = it.id === item.id ? !it.menu.is_active : false);
      this.$forceUpdate();
    },
  },


  mounted() {
    const sidebar = ls.get('sidebar');
    if( sidebar ) this.sidebar = sidebar;
  }

}
</script>
<style lang="scss" scoped>
.menu{
  display: block;
  color: black;
  text-decoration: none;
  user-select: none;

  h2{
    font-size: 0.8rem;
    color: #999;
    text-transform: uppercase;
    padding: 0.8rem 30px 0.8rem 20px;
    font-weight: normal;
    margin: 0;
  }

  .item{
    &:first-child{
      border-top: #f0f0f0 solid 1px;
    }

    border-bottom: #f0f0f0 solid 1px;

    &.active{
      background: #f0f0f0;

      .head{
        background: #e0e0e0;
      }

      .sub-items{
        display: block;
      }
    }
  }

  .head{
    color: black;
    display: block;
    cursor: pointer;
    padding: 0.8rem 30px 0.8rem 60px;
    position: relative;
    text-decoration: none;

    h3{
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 600;
    }

    p{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .close{
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background: white;
      border: #999 solid 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      border-radius: 50%;
    }
  }

  h3{
    font-size: 0.9rem;
    margin: 0;
    overflow: hidden;
  }
  p{
    font-size: 0.8rem;
    margin: 0;
  }

  .sub-items{
    padding: 1rem 0;
    display: none;
  }

  .sub-item{
    display: block;
    text-decoration: none;
    padding: 0.6rem 30px 0.6rem 60px;
    color: black;
    font-size: 0.9rem;
    position: relative;

    &.active{
      background: rgba(red, 0.1);
    }
  }

  .icon{
    position: absolute;
    left: 30px;
    top: 50%;
    width: 20px;
    height: auto;
    transform: translate(-50%, -50%);
  }

}

</style>