<template>
  <iframe v-if="show_header" src="https://www.designforchange.us/footer" width=100% frameborder=0 height=550 scrolling="no" id="footerframe"></iframe>
</template>

<script>
export default {
  name: "AppFooter",

  computed: {
    show_header() {
      if (this.$route.query.header == 'false') {
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>

<style scoped>

</style>