<template>
  <div class="grant-success">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 mb-3">
          <h1 class="h2">
            Thank you! We've received your DFC Completion Form!
          </h1>

          <p class="my-4">
            We will be evaluating your submission and will announce
            the award winners by <strong class="text-secondary">June 30th</strong>.
            We will also notify you about your Be the Change Conference status soon!
          </p>

          <p class="h5">
            Congratulations, your
            <span class="text-primary">Certificate of Completion</span>
            is now available.
          </p>

          <div class="form-group mt-3 mb-4 pb-3">
            <label for="name">Certified For (optional)</label>
            <input type="text"
                   id="name"
                   class="form-control"
                   placeholder="Name"
                   v-model="name">
          </div>

          <div class="form-group">
            <div class="row">
              <div v-for="cert_theme in cert_themes" :key="cert_theme.id" class="col-12 col-sm-6 mb-4">
                <box-check :box="cert_theme" @change="themeSelected($event)"></box-check>
              </div>
            </div>
          </div>
          <div class="text-center">
            <a :href="download_url"
               target="_blank"
               class="btn btn-secondary btn-lg mt-3 mb-4">
              <i class="fas fa-download mr-1"></i> Download Certificate
            </a>
          </div>

        </div>
        <div class="col-md-6 mb-3">
          <div class="d-none d-md-block">
            <img class="img-fluid rounded border shadow-lg"
                 src="/files/Certificate_DFC_2021_01.jpg"
                 alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import BoxCheck from "@/components/BoxCheck";
import tracker from "@/mixins/tracker";
import mixins from "@/mixins";

export default {
  name: "GrantSuccess",
  components: {BoxCheck},
  mixins: [mixins, tracker],

  props: {
    id: {
      type: Number,
      default: 0
    },
  },

  data(){
    return {
      submission: null,
      name: '',
      cert_themes: [
        { title: 'Theme A', id: 1, checked: true, image: '/files/Certificate_DFC_2021_01_thumb.jpg' },
        { title: 'Theme B', id: 2, checked: false, image: '/files/Certificate_DFC_2021_02_thumb.jpg' },
      ],
    }
  },

  computed: {
    download_url(){
      return API.getGrantCertificateUrl(this.active_cert_theme.id, this.name);
    },
    active_cert_theme(){
      return this.cert_themes.reduce((acc, theme) => theme.checked ? theme : acc, {});
    }
  },

  methods: {
    themeSelected(theme){
      this.cert_themes.forEach((t) => {
        if( t !== theme ) t.checked = false;
      });
      setTimeout( () => theme.checked = true, 3 );
    },
  },

  async mounted() {
    this.submission = await API.getGrantSubmission(this.id);

    this.setPageTitle( 'Grant Submission Successful' );
    this.tracker.visit(this.$route);

  }

}
</script>

<style lang="scss" scoped>
.container {
  // max-width: 00px !important;
  margin: 1rem auto;
}
</style>