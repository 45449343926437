<template>

  <div class="recover-password my-5">
    <div class="container text-center">

      <img src="/img/img_logo_dfc.svg"
           class="logo d-block my-3 mx-auto"
           alt="Design for change">

      <h2 class="h4">Reset Password</h2>
      <p>Enter a new password:</p>

        <form action="" @submit.prevent="register()">
          <div class="form-group">
            <input type="password"
                  v-model="password"
                   class="form-control"
                   placeholder="Password">
          </div>
          <div class="form-group">
            <div class="input-validation">
              <input type="password"
                     v-model="re_password"
                     class="form-control"
                     placeholder="Retype Password">
              <div v-if="re_password.length" class="input-validation-icon">
                <i v-if="stage_security_password_is_valid" class="fas fa-check-circle text-success"></i>
                <i v-else class="fas fa-exclamation-circle text-danger"></i>
              </div>
            </div>

          </div>

          <div v-if="re_password.length && !stage_security_password_is_valid" class="form-group">
            <div class="text-danger">
              <i class="fas fa-exclamation-circle mr-1"></i>
              Passwords do not match.
            </div>
          </div>

          <div v-if="error" class="form-group">
            <div class="text-danger">
              <i class="fas fa-exclamation-circle mr-1"></i>
              {{ error }}
            </div>
          </div>

          <div class="form-group">
            <button type="submit"
                    :disabled="is_busy || !stage_security_password_is_valid"
                    class="btn btn-primary btn-lg" @click="resetPassword()">Set Password</button>
          </div>
        </form>

      <div class="my-5 small">
        © 2021 Design for Change USA
      </div>

    </div>
  </div>

</template>

<script>
import API from '../../api';
import tracker from "@/mixins/tracker";

export default {
  name: "ResetPassword",
  mixins: [tracker],

  data(){
    return {
      is_busy: false,
      password: '',
      re_password: '',
    }
  },

  computed: {
    stage_security_password_is_valid(){
      return this.re_password.length && this.password === this.re_password;
    },
  },

  methods: {
    resetPassword(){
      this.is_busy = true;
      
      var user = {};
      user['password'] = this.password;

      API
          .resetPassword(user)
          .then(() => {
            this.$swal({
              title: 'Password Reset',
              text: 'You will now be redirected to log into your account.',
              icon: 'success',
            }).then( () => window.location.href = `/auth` );
        }).reject(() => {
          this.$swal({
             title: 'Password Reset',
             text: 'Error resetting your password.',
             icon: 'error',
          })
        });
    },
  },

  mounted() {
    this.setPageTitle( 'Reset Password' );
    this.tracker.visit(this.$route);
  }

}
</script>

<style lang="scss" scoped>
.container{
  max-width: 600px !important;
}
.logo{
  max-width: 200px;
}
</style>