<template>
  <div class="grant-step-account">

    <div class="head">
      <div class="container" style="max-width: 800px">
        <h1 class="h3">Create Account</h1>
      </div>
    </div>

    <div class="container" style="max-width: 800px">

      <div class="form-group">
        <b-form-checkbox
          switch
          id="create_account"
          v-model="values.create_account"
          name="create_account"
          :value="true"
          :unchecked-value="false"
        >
          {{ values.create_account ? 'Create my Design for Change USA account' : 'Do not create any account' }}
        </b-form-checkbox>
        <field-error-message :errors="errors" field="create_account"/>
      </div>

      <div class="form-group">
        <label for="create_account_password">Create Account Password</label>
        <input class="form-control"
               type="password"
               id="create_account_password"
               placeholder="Secure Password"
               :disabled="!values.create_account"
               v-model="values.create_account_password">
        <field-error-message :errors="errors" field="create_account_password"/>
      </div>

      <div class="form-group">
        <label for="create_account_retype_password">Retype Password</label>
        <input class="form-control"
               type="password"
               id="create_account_retype_password"
               placeholder="Retype Password"
               :disabled="!values.create_account"
               v-model="values.create_account_retype_password">
        <field-error-message :errors="errors" field="create_account_retype_password"/>
      </div>

    </div>

  </div>
</template>

<script>
import FieldErrorMessage from "@/components/FieldErrorMessage";
import tracker from "@/mixins/tracker";
export default {
  name: "GrantStepAccount",
  components: {FieldErrorMessage},
  mixins: [tracker],

  props: {
    values: {
      type: Object,
      default() {
        return {};
      }
    },
    errors: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  mounted() {
    this.setPageTitle( 'Grant Submission - Create Account' );
    this.tracker.visit(this.$route);
  }

}
</script>

<style scoped>
.form-group {
  margin-bottom: 2rem;
}
</style>