<template>
  <div class="student-profile">

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12 my-4">
          <div v-if="student" class="inner">
            <div class="row align-items-stretch">

              <div class="col-md-4 mb-3 bg-secondary text-light px-4 py-5 rounded-lg">
                <div class="user">
                  <div class="text-center">
                    <i class="fas fa-user-circle user-icon mb-3"></i>
                    <h1>{{ info.details_name }}</h1>
                    <hr>
                  </div>
                  <ul class="list-unstyled details">
                    <li>
                      <i class="fas fa-envelope"></i>
                      <a :href="`mailto:${info.details_email}`"
                         target="_blank"
                         class="text-light">{{ info.details_email }}</a>
                    </li>
                    <li><i class="fas fa-user"></i> {{ info.details_age }} years old</li>
                    <li><i class="fas fa-school"></i> {{ info.details_grade }}</li>
                    <li><i class="fas fa-globe"></i> {{ info.details_country }}</li>
                  </ul>
                </div>
              </div>

              <div class="col-md-8 mb-3">
                <div v-for="(group) in profile_sections" :key="group.prefix">
                  <div class="alert alert-info mb-0">
                    <h4 class="mb-0">{{ group.title }}</h4>
                  </div>
                  <table class="table mb-5">
                    <tr v-for="field in matchFields(group.prefix)" :key="field[0]" class="mb-1">
                      <th width="200" class="text-right">{{ title( field[0], group.prefix ) }}</th>
                      <td width="2" style="width: 2px;">:</td>
                      <td>{{ value(field[1], field[0], group.prefix) }}</td>
                    </tr>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

<!--    Student Profile-->
<!--    <p>Student ID: {{ id }}</p>-->
<!--    <h4>Profile</h4>-->
<!--    {{ student }}-->
  </div>
</template>

<script>
import API from "@/api";
import inflect from "i";
import tracker from "@/mixins/tracker";

export default {
  name: "StudentProfile",
  mixins: [tracker],

  props: {
    id: {
      type: Number,
      default: null,
    },
  },

  data(){
    return {
      student: null,
      profile_sections: [
        {prefix: 'intro_', title: 'Intro'},
        {prefix: 'warmup_', title: 'Warmup'},
        {prefix: 'feel_', title: 'Feel'},
        {prefix: 'imagine_', title: 'Imagine'},
        {prefix: 'do_', title: 'Do'},
        {prefix: 'share_', title: 'Share'},
      ],
      titles: {
        intro_previousproject: 'Previous Project',
        intro_whychoosedfc: 'Why choose DFC?',

        feel_empathysketch_do: 'Do',
        feel_empathysketch_say: 'Say',
        feel_empathysketch_think: 'Think',
        feel_howmightwe: 'How might we',
        feel_survey_motivated: 'Survey motivation',

        do_getresources: 'Get Resources',
      }
    }
  },

  computed: {

    inflect(){
      return new inflect();
    },

    info(){
      return this.student ? this.student.student_data : {};
    },

    fields(){
      return this.student ? Object.keys(this.info).sort().reduce(
          (arr, key) => {
            arr.push([key,  this.info[key]]);
            return arr;
          },
          []
      ) : [];
    },
  },

  methods:{
    async refresh() {
      try {
        // const student = JSON.parse( this.$cookies.get('profile') ); console.log( API );
        const student = await API.getStudentById(this.id);
        this.student = typeof student === 'string' ? JSON.parse( student ) : student;
      } catch (e) {
        console.log( e );
      }
    },

    matchFields(prefix){
      const regex = new RegExp(prefix, 'i');
      return this.fields.filter((f) => {
        return regex.test( f[0] );
      });
    },

    title(key, prefix = ''){
      if( typeof this.titles[key] !== 'undefined' ) return this.titles[key];
      return this.inflect.humanize( key.replace(new RegExp(`^${prefix}`), '') );
    },

    value(val, name/*, prefix*/){

      switch (name){
        case 'warmup_library_read':
          return val.join(', ');
      }

      return val;
    },

  },

  async mounted() {

    await this.refresh();

    this.setPageTitle('Profile - ' + this.info.details_name);
    this.tracker.visit(this.$route);

  }

}
</script>

<style lang="scss" scoped>
h1{
  font-size: 2.1rem;
}
.user-icon{
  font-size: 7rem;
}
.details{
  font-size: 1.1rem;

  li{
    margin-bottom: 1rem;
  }

  .fas{
    display: inline-block;
    width: 1.5rem;
    text-align: center;
    margin-right: 0.7rem;
  }
}
</style>