export default function Auth ({ to, from, next }){
	// return window.user ? next() : next({ name: 'auth.login' });

  console.log('auto-redirect', {to, from, next});


  switch ( to.name ){

    case 'podcast.index':
      return window.location.replace('https://pro.designforchange.us/podcasts/');

    case 'podcast.show':
      return window.location.replace(`https://pro.designforchange.us/podcast/${to.params.slug}`);

  }

  return next();
}
