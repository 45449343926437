var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"library-archive"},[_c('title-bar',{attrs:{"title":"My Library","background":"/dfcusa-web/img/img_hedr_teachers.png"}}),_c('dash-section',{staticClass:"mb-4",attrs:{"title":"","description":"This is your full library of articles, videos, and content you can assign to your students. Add, manage, and preview all of your existing content here. You can customize your lesson plans by selecting the content you want students to learn under each of your open projects."}},[_c('div',{staticClass:"content-archive"},[_c('div',{staticClass:"actions my-3"},[_c('div',{staticClass:"filters"},_vm._l((_vm.filter_courses),function(f){return _c('a',{key:f.filter,staticClass:"filter",class:{active: f.filter === _vm.filter.course},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.filter.course = f.filter}}},[_vm._v(_vm._s(f.title))])}),0),_c('div',{staticClass:"primary-action"},[_c('router-link',{staticClass:"btn btn-danger text-uppercase",attrs:{"to":{ name: 'my-library.create' }}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Add New Item ")])],1)]),_c('div',{staticClass:"content-area"},[(!_vm.library_busy)?_c('div',{staticClass:"library mb-4 row align-items-stretch"},_vm._l((_vm.library),function(item){return _c('div',{key:item.id,staticClass:"podcast col-lg-4 col-md-6 mb-4"},[_c('card-selector',{attrs:{"type":"checkbox","selectable":false,"deselectable":false,"editable":!!item.organization_id,"card":{
                                        id: item.id,
                                        title: item.title,
                                        description: item.description,
                                        image: _vm.libraryImageUrl(item),
                                        type: item.type,
                                        difficulty: item.level,
                                        preview: item.url,
                                        edit: {name: 'my-library.edit', params: {id: item.id}},
                                      }}})],1)}),0):_vm._e(),(!_vm.library.length)?_c('div',[_c('div',{staticClass:"card text-center"},[_c('div',{staticClass:"card-body py-5"},[_c('p',[_vm._v("Sorry, no library items found.")]),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{name: 'my-library.create'}}},[_c('i',{staticClass:"fas fa-plus mr-1"}),_vm._v(" Add New Item ")])],1)])]):_vm._e()])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }