<template>
  <div class="wiz-progress-bar">
    <div v-for="num in progress.max" :key="num"
         class="step"
         :class="{
            complete: (num - 1) < progress.value,
            active: (num - 1) === progress.value,
        }"></div>
  </div>
</template>

<script>

export default {
  name: "ProgressBar",
  props: {
    progress: {
      type: Object,
      default() {
        return {
          max: 10,
          value: 0,
        };
      }
    }
  },

}

</script>

<style lang="scss" scoped>

$thickness: 8px;
$spacing: 3px;
$inactive-color: #b5b8b1;
$active-color: #76dd68;

.wiz-progress-bar{
  display: flex;
  padding: $spacing ($spacing * 1.35) $spacing ($spacing * 1.35 * 2);
  border-radius: $thickness + $spacing;
  background: white;
  box-shadow: rgba(black, 0.2) 0 $spacing $thickness;

  .step{
    flex: 1 1 auto;
    height: $thickness;
    position: relative;
    transform-origin: left center;

    &:before{
      content: '';
      width: $thickness;
      height: $thickness;
      background: $inactive-color;
      border-radius: 50%;
      position: absolute;
      left: - $spacing * 1.35;
      top: 0;
    }

    &:first-child{
      border-radius: $thickness 0 0 $thickness;
    }

    &:last-child{
      border-radius: 0 $thickness $thickness 0;

      &:after{
        content: '';
        width: $thickness;
        height: $thickness;
        background: $inactive-color;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    &.complete{
      background: $active-color;
      animation: scale-right .3s;
    }

    &.active, &.complete{
      &:before{
        background: $active-color;
      }
      &:last-child{
        &:after{
          background: $active-color;
        }
      }
    }

  }
}

@keyframes scale-right {
  from{
    transform: scale(0.1, 1);
  }
  to{
    transform: scale(1, 1);
  }
}

</style>