<template>
    <div class="user-invite-wrapper">
        
        <title-bar title="Invite Teachers"
               class=""
               background="/dfcusa-web/img/img_hedr_teachers.png" layout="plain"/>
               <!-- ask about image-->
              
        <dash-section title="Invite Teacher"
                description="Invite a teacher to join your class and collaborate"
                class="mb-5">
            <div class="text-center text-md-left">

                <div class="row align-items-center">
                    <div class="col-sm-7">
                        <div class="form-group">
                            <label for="organization-url">Invite Link</label>
                            <div class="input-group">
                                <input type="text"
                                       id="organization-url"
                                       readonly="readonly"
                                       class="form-control curved-half"
                                       placeholder="First Name "
                                       :value="organization_url"/>
                                <div class="input-group-append">

                                    <copy-button class="btn btn-secondary" :content="organization_url">
                                        <i class="fas fa-clipboard mr-1"></i> Copy
                                        <template v-slot:success>
                                            <i class="fas fa-check mr-1"></i> Copied
                                        </template>
                                    </copy-button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <div class="form-group">
                            <label>Social Share</label>
                            <ul class="social-share">
                                <li><a target="_blank" title="Skype"
                                       :href="'https://web.skype.com/share?url=' + organization_url"><i
                                        class="fab fa-skype"></i></a></li>
                                <li><a target="_blank" title="Email"
                                       :href="'mailto:john@doe.com?body=' + organization_url + '&subject=Invite Subject text'"><i
                                        class="far fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="my-4">
            <div class="row mt-3">
                <div class="col-sm-7">
                    <div class="form-group">
                    <label for="item-name">E-mail address</label>

                        <div class="input-group">
                            <input type="text" 
                                   class="form-control "
                                   v-model="fl_name"
                                   id="firstlast-name"
                                   placeholder="First and Last Name">
                        </div>
                     
                        <div class="input-group mt-3">
                            <input type="email"
                                   class="form-control curved-half"
                                   v-model="mail"
                                   id="item-name"
                                   :disabled="is_busy"
                                   placeholder="john@doe.com"/>
                            <div class="input-group-append">
                                <button type="button"
                                        class="btn btn-primary btn-lg"
                                        :disabled="is_busy || mail.length <= 0"
                                        @click="invite()">Send Invitation</button>
                            </div>
                        </div>

                        <div class="input-group invitation-type-wrapper">
                            <b-form-checkbox
                                id="invitation-type"
                                v-model="invitation_type"
                                value="1"
                                >
                                Invite to be an admin
                            </b-form-checkbox>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </dash-section>
    </div>

    
</template>

<script>
import TitleBar from "@/components/TitleBar";
import DashSection from "@/components/DashSection";
import API from "@/api";
import mixins from "@/mixins";
import CopyButton from "@/components/CopyButton";
import tracker from "@/mixins/tracker";



export default {
    name: "UsersInvite",
    components: {CopyButton, TitleBar, DashSection},
    mixins: [mixins, tracker],

    props: {
        id: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            is_busy: false,
            mail: '',
            isActive: false,
            fl_name : '',
            invitation_type: '',
        };
    },

    computed: {
        organization_url(){
            return `${this.base_url}/auth/register/?org=` + window.user.organization_id
        }
    },

    methods: {
        async invite() {

            this.is_busy = true;
            //alert(this.invitation_type);
            try {

                await API.inviteUser(this.mail, this.invitation_type, this.fl_name );

                this.$notify({
                    group: 'projects',
                    title: 'Invited',
                    type: 'success',
                    text: 'The Invitation was sent successfully.'
                });

                this.mail = "";

            } catch (e) {
                this.$notify({
                    group: 'projects',
                    title: 'Invited',
                    type: 'error',
                    text: 'Failed to invite.'
                });
            }

            this.is_busy = false;

        },


        // copy_url( ) {
        //   var _t = this;
        //
        //   navigator.clipboard.writeText(this.organization_url).then(function() {
        //     _t.isActive = true;
        //     setTimeout(() => {
        //       _t.isActive = false;
        //     }, 2000);
        //   }, function(err) {
        //     console.log(err);
        //   });
        // },
    },

    async mounted() {
        this.is_busy = false;
        this.$forceUpdate();

      this.setPageTitle('Invite Teacher');
      this.tracker.visit(this.$route);

    }

};
</script>

<style lang="scss" scoped>
.social-share {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    display: inline-block;
    margin-right: 0.1rem;
    padding: 0.3rem 0;
  }

  a{
    display: block;
    padding: 0.3rem;
  }

  i {
    font-size: 1.3rem;
  }
}
.invitation-type-wrapper {
    margin-top: 10px;
}
.actions {
  display: flex;
  margin: 0 -10px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  > * {
    padding: 0 10px;
    margin-bottom: 1rem;
  }
}

button.active {
  position: relative;
  overflow: hidden;
  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 3;
    background: rgba( 0,0,0,0.3 );
    left: 0;
    top: 0;
  }
}

.input-group{
  .form-control.curved-half {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .btn{
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }
}

//#app {
//  .form-control.copy-field {
//    width: 100%;
//    padding: 0 10px;
//    height: 30px;
//    font-size: 14px;
//
//  }
//}
</style>