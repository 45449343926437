<template>
  <div class="library-edit">

    <title-bar :title="id ? (item.title.length ? item.title : 'Unnamed*') : 'Add to My Library'" layout="plain"/>

    <dash-section title="Library Item"
                  description="Enter information identifying your library item."
                  class="mb-5"
                  :busy="is_busy"
                  :action="id ? {
                    title: '<i class=\'fas fa-trash mr-1\'></i> Delete Item',
                    click: actionDeleteItem,
                  }: null">
      <div class="row mt-3">
        <div class="col-md-7">
          <div class="form-group">
            <div class="row">
              <label class="col-sm-4 col-form-label" for="item-name">Title</label>
              <div class="col-sm-8">
                <input type="text"
                       class="form-control"
                       id="item-name"
                       v-model="item.title"
                       placeholder="Title"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-sm-4 col-form-label"
                     for="item-description">
                Description
              </label>
              <div class="col-sm-8">
                <textarea class="form-control"
                          rows="6"
                          v-model="item.description"
                          placeholder="Description of the item"
                          id="item-description"></textarea>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-sm-4 col-form-label"
                     for="item-course">Theme</label>
              <div class="col-sm-8">
                <select type="text"
                        v-model="item.course"
                        class="form-control"
                        id="item-course">
                  <option v-for="course in courses" :key="course.value"
                          :value="course.value">{{ course.title }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-sm-4 col-form-label"
                     for="item-type">Item Type</label>
              <div class="col-sm-8">
                <select type="text"
                        v-model="item.type"
                        class="form-control"
                        id="item-type">
                  <option value="">-- SELECT TYPE --</option>
                  <option value="article">ARTICLE</option>
                  <option value="video">VIDEO</option>
                </select>
              </div>
            </div>
          </div>

          <div class="form-group" v-if="item.type === 'article'">
            <div class="row">
              <label class="col-sm-4 col-form-label" for="article-url">Article</label>
              <div class="col-sm-8">
                <div v-if="item.url" class="mb-3">
                  <a :href="item.url" target="_blank" class="article-preview">{{ item.url }}</a>
                </div>
                <input type="text"
                       class="form-control"
                       id="article-url"
                       v-model="item.url"
                       placeholder="ARTICLE URL"/>
              </div>
            </div>
          </div>

          <div class="form-group" v-if="item.type === 'video'">
            <div class="row">
              <label class="col-sm-4 col-form-label" for="video-url">Video url</label>
              <div class="col-sm-8">
                <input type="text"
                       class="form-control"
                       id="video-url"
                       v-model="item.url"
                       placeholder="VIDEO URL"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-sm-4 col-form-label"
                     for="item-level">Level</label>
              <div class="col-sm-8">
                <select type="text"
                        v-model="item.level"
                        class="form-control"
                        id="item-level">
                  <option v-for="level in levels" :key="level.value"
                          :value="level.value">{{ level.title }}</option>
                </select>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-5 mb-3">
          <div class="row align-items-center">
            <div class="col-6">
              <h6 class="mb-2">FEATURED IMAGE</h6>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <file-uploader id="featured-image"
                         v-model="item.picture"
                         label="<i class='fas fa-camera'></i> UPLOAD"></file-uploader>
            </div>
          </div>
          
          <div class="preview mt-3">
            <img :src="libraryImageUrl(item)" class="shadow border" alt="">
          </div>

          

        </div>
      </div>

      <div v-if="!id" class="text-center text-md-right">
        <hr class="my-4">
        <button type="button"
                class="btn btn-primary btn-lg"
                @click="create()">
          CREATE LIBRARY ITEM
        </button>
      </div>
    </dash-section>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import DashSection from "@/components/DashSection";
import API from "@/api";
import FileUploader from "@/components/FileUploader";
import mixins from "@/mixins";
import tracker from "@/mixins/tracker";

export default {
  name: "LibraryEdit",
  components: {FileUploader, TitleBar, DashSection },
  mixins: [mixins, tracker],

  props: {
    id: {
      type: Number,
      default: 0
    },
  },

  data() {
    return {
      is_busy: false,
      item: {
        course: 'justice',
        type: 'article',
        title: '',
        description: '',
        level: 'beginner',
        url: '',
        picture: '',
      },

      levels: [
        {title: 'Beginner', value: 'beginner'},
        {title: 'Intermediate', value: 'intermediate'},
        {title: 'Advanced', value: 'advanced'},
      ],

      courses: [
        {title: 'Racial Justice', value: 'justice'},
        {title: 'Climate Action', value: 'climate'},
        {title: 'Education Equity', value: 'education'},
        {title: 'Other', value: 'other'}
      ]

    };
  },


  watch: {
    item:{
      deep: true,
      handler(val){

        if( this.is_busy ) return;
        if( !this.id ) return;

        API.saveLibraryItemDebounced(this.id, val)
            .then(() => {
              this.$notify({
                group: 'projects',
                title: 'Saved',
                type: 'success',
                text: 'The library item is automatically saved.'
              });
            }).catch(err => {
              if( err.statusText !== 'abort' ){

                console.log( err );

                this.$notify({
                  group: 'projects',
                  title: 'Error',
                  type: 'error',
                  text: 'Failed to save the library item'
                });
              }
            });
      }
    }
  },

  methods: {
    async actionDeleteItem(){

      this.$swal({
        title: 'Remove Library Item?',
        html: `
            Are you sure to delete this item
            <strong class="text-danger">${this.item.title}</strong>?
            This action can't be undone`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, Remove.'
      }).then(({ isConfirmed }) => {
        if(isConfirmed){
          this.is_busy = true;
          API.removeLibrary(this.item.id)
              .then(() => this.$router.push({name: 'my-library.index'}))
              .finally(() => this.is_busy = false);
        }
      });

      // if( !confirm('Are you sure to delete?') ) return;
      //
      // await API.removeLibrary(this.item.id);
      // await this.$router.push({name: 'my-library.index'});
    },

    async create(){
      await API.createLibraryItem(this.item);
      await this.$router.push({name: 'my-library.index'});
    },

  },

  async mounted() {
    this.is_busy = true;

    if( this.id ){
      try{
        this.item = await API.getLibraryItemById(this.id);
      }catch (e) {
        // DO some stuffs.
      }
    }

    this.is_busy = false;
    this.$forceUpdate();

    this.setPageTitle( this.id ? 'Edit Library Item ' + this.item.title : 'Add to My Library' );
    this.tracker.visit(this.$route);

  }

};
</script>


<style lang="scss" scoped>
textarea {
  &.form-control {
    height: auto !important;
  }
}

.article-preview{
  display: block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.preview{
  margin-bottom: 1rem;

  img{
    display: block;
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }
}

</style>